import PropTypes from 'prop-types';
import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { PAGE_CONTENT, validateEmail } from './AddParticipants.content';
import CloseIcon from '@mui/icons-material/Close';
import {
  DialogStyle,
  IconButtonStyle,
  DialogTitleStyle,
  DialogContentStyle,
  DialogActionStyle,
  ButtonStyle,
  TitleWrapper,
  InputWrapper,
  OptionItemStyle,
  AddParticipantWrapper,
} from './AddParticipants.style';
import { useForm } from 'hooks/useForm';
import { useEffect, useState } from 'react';
import { checkTernaryCondition, isEmpty } from 'utils/helper';
import { useParticipantDetails } from 'hooks/useParticipantDetails';
import axios from 'axios';
import BasicAutoComplete from 'components/FormComponents/BasicAutoComplete/BasicAutoComplete.react';
import {
  CHECK_PARTICIPANT_PROJECT,
  GET_PROJECT_FOR_PARTICIPANTS,
  USER_CHECK_EMAIL,
} from 'urls';
import {
  FocusedInputSx,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { STYLE_CONSTANTS } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { InputSx } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import {
  OptionNoBoldStyle,
  closeIconSx,
  modalActionButtonDefaultStyle,
} from 'theme/GlobalStyles';
import { OptionListStyle } from 'components/SearchAndFilterComponent/SearchAndFilterComponent.style';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { outlinedDropdownInputSx } from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import { COOL_GRAY, GRAYISH_BLUE } from 'theme/GlobalColors';

const formModal = {
  participantId: '',
  participantFirstName: '',
  participantLastName: '',
  participantEmail: '',
  project: null,
  projectCycleId: '',
};

const errorInitialState = {
  firstNameError: false,
  lastNameError: false,
  emailError: false,
  nameError: false,
  emailInValidError: false,
  duplicateEmailError: false,
  projectCycleIdError: false,
};

const AddParticipants = ({
  refreshHandler,
  isModalOpen,
  setIsModalOpen,
  projectId,
  projectName,
  particpantId,
  inParticipantPage,
  setParticipantId,
  projectLifeCycleList = [],
}) => {
  const {
    formValue: participantForm,
    formFieldChangeHandler,
    customFieldChangeHandler,
    setFormValue,
  } = useForm(formModal);

  const [errorState, setErrorState] = useState({
    errorInitialState,
  });

  const [isLinked, setIsLinked] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [projectList, setProjectList] = useState([{ label: '', value: null }]);
  const [projectErrorMessage, setProjectErrorMessage] = useState('');
  const [participantProjectName, setParticipantProjectName] =
    useState(projectName);
  const [newParticipant, setNewParticipant] = useState(true);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [isSelectdFromDropdown, setIsSelectedFromDropdown] = useState(false);
  const [filterText, setFilterText] = useState('');

  const {
    submitParticipantModal,
    loading,
    fetchParticipantList,
    participantList,
    fetchParticipantDetails,
    participantDetails,
    participantIdError,
    setParticipantDetails,
    setParticipantIdError,
    setParticipantList,
  } = useParticipantDetails();

  useEffect(() => {
    /* istanbul ignore else */
    if (isModalOpen === true) {
      setFormValue(formModal);
      setErrorState(errorInitialState);
      setParticipantDetails('');
      setParticipantIdError(false);
      setParticipantList([]);
      setIsLinked(false);
      /* istanbul ignore else */
      if (particpantId > 0) {
        setEditMode(true);
        fetchParticipantDetails(particpantId);
      }
    }
  }, [isModalOpen]);

  const fetchProjectList = (value) => {
    value &&
      axios
        .get(GET_PROJECT_FOR_PARTICIPANTS, { params: { projectName: value } })
        .then((response) => {
          setProjectList(
            response.data.map((item) => ({
              label: item.projectName,
              value: item.internalProjectId,
            })),
          );
        });
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (participantDetails !== '') {
      setFormValue({
        participantId: {
          participantId: participantDetails.participantId,
          participantFirstName: participantDetails.participantFirstName,
          participantLastName: participantDetails.participantLastName,
        },
        participantFirstName: participantDetails.participantFirstName,
        participantLastName: participantDetails.participantLastName,
        participantEmail: participantDetails.participantEmail,
        project: null,
        projectCycleId: '',
      });
    }
  }, [participantDetails]);

  useEffect(() => {
    const { participantEmail } = participantForm;
    /* istanbul ignore else */
    if (
      !isSelectdFromDropdown &&
      !editMode &&
      participantEmail !== '' &&
      validateEmail(participantEmail)
    ) {
      checkDuplicateEmail(participantEmail);
    }
  }, [participantForm.participantEmail]);

  useEffect(() => {
    fetchProjectList();
  }, [particpantId]);

  useEffect(() => {
    setParticipantProjectName(projectName);
  }, [projectName]);

  const checkDuplicateEmail = async (email) => {
    axios.get(`${USER_CHECK_EMAIL}${email}`).then((response) => {
      setErrorState({
        ...errorState,
        duplicateEmailError: response.data,
      });
    });
  };

  const onProjectChange = (value) => {
    setParticipantProjectName(value?.label);

    if (!newParticipant) {
      axios
        .get(
          `${CHECK_PARTICIPANT_PROJECT}?participantId=${participantForm?.participantId?.participantId}&projectId=${value?.value}`,
        )
        .then((response) => {
          if (response.data === true) {
            setErrorState({ ...errorState, projectError: true });
            setProjectErrorMessage(PAGE_CONTENT.project_secondary_message);
          } else {
            setErrorState({ ...errorState, projectError: false });
          }
        })
        .catch(() => {
          setErrorState({ ...errorState, projectError: true });
          setProjectErrorMessage(PAGE_CONTENT.project_secondary_message);
        });
    } else {
      setErrorState({ ...errorState, projectError: false });
    }
  };

  const submitHandler = ({ enrollParticipant }) => {
    setErrorState({
      ...errorState,
      firstNameError: !participantForm.participantFirstName.trim(),
      lastNameError: !participantForm.participantLastName.trim(),
      emailError: !participantForm.participantEmail.trim(),
      emailInValidError: checkTernaryCondition(
        participantForm.participantEmail.trim(),
        !validateEmail(participantForm.participantEmail),
        false,
      ),
      projectCycleIdError: isEmpty(participantForm.projectCycleId),
    });

    const formError = () => {
      if (
        inParticipantPage &&
        participantForm.participantFirstName.trim() &&
        participantForm.participantLastName.trim() &&
        participantForm.participantEmail.trim() &&
        validateEmail(participantForm.participantEmail) &&
        participantForm.projectCycleId !== '' &&
        !errorState.duplicateEmailError &&
        (editMode || !errorState.projectError)
      ) {
        return true;
      } else if (
        !inParticipantPage &&
        participantForm.participantFirstName.trim() &&
        participantForm.participantLastName.trim() &&
        participantForm.participantEmail.trim() &&
        participantForm.projectCycleId !== '' &&
        validateEmail(participantForm.participantEmail) &&
        !errorState.duplicateEmailError
      ) {
        return true;
      } else {
        return false;
      }
    };
    /* istanbul ignore else */
    if (formError()) {
      let particpantSubmitId = checkTernaryCondition(
        editMode,
        particpantId,
        checkTernaryCondition(
          participantForm.participantId === '',
          0,
          participantForm.participantId.participantId,
        ),
      );
      submitParticipantModal(
        {
          participantId: particpantSubmitId,
          participantFirstName: participantForm.participantFirstName.trim(),
          participantLastName: participantForm.participantLastName.trim(),
          participantEmail: participantForm.participantEmail.trim(),
          projectId: checkTernaryCondition(
            inParticipantPage && !editMode,
            participantForm?.project?.value || 0,
            projectId,
          ),
          projectCycleId: participantForm.projectCycleId,
        },
        participantProjectName,
        setIsModalOpen,
        isLinked,
        refreshHandler,
        enrollParticipant,
        editMode,
      );
      modalCloseHandler();
    }
  };

  const modalCloseHandler = () => {
    /* istanbul ignore else */
    if (setParticipantId) {
      setParticipantId(0);
    }
    setIsModalOpen({ flag: false, id: 0 });
    setFormValue(formModal);
    setErrorState(errorInitialState);
    setParticipantDetails('');
    setParticipantIdError(false);
    setParticipantList([]);
    setIsLinked(false);
    setEditMode(false);
    setIsFormEdited(false);
    setIsSelectedFromDropdown(false);
  };

  const formHasDataWithoutErrors =
    participantForm.participantFirstName?.length > 0 &&
    participantForm.participantLastName?.length > 0 &&
    participantForm.participantEmail?.length > 0 &&
    !Object.values(errorState).includes(true);

  const handleEmailChange = (e) => {
    formFieldChangeHandler(e);
    let errorStateUpdates = {
      emailInValidError: false,
      duplicateEmailError: false,
      emailError: false,
    };
    setErrorState((prev) => ({ ...prev, ...errorStateUpdates }));
    setIsFormEdited(true);
  };

  const addParticipantTitle = (
    <AddParticipantWrapper
      title={checkTernaryCondition(projectName?.length > 32, projectName, '')}>
      {checkTernaryCondition(
        editMode,
        PAGE_CONTENT.editPageTitle,
        checkTernaryCondition(
          projectName,
          `${PAGE_CONTENT.addPageTitle}`,
          PAGE_CONTENT.addPageTitle,
        ),
      )}
    </AddParticipantWrapper>
  );

  return (
    <div>
      <Dialog
        disableRestoreFocus
        open={isModalOpen}
        onClose={modalCloseHandler}
        sx={DialogStyle}
        scroll={PAGE_CONTENT.paper}
        aria-labelledby={PAGE_CONTENT.aria_labelledby}
        aria-describedby={PAGE_CONTENT.aria_describedby}>
        <TitleWrapper>
          <DialogTitle sx={DialogTitleStyle}>{addParticipantTitle}</DialogTitle>
          <IconButton sx={IconButtonStyle} onClick={modalCloseHandler}>
            <CloseIcon data-testid="close-icon" sx={closeIconSx} />
          </IconButton>
        </TitleWrapper>
        <DialogContent sx={DialogContentStyle}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}>
            <CircularProgress color="success" />
          </Backdrop>
          {!editMode && !inParticipantPage && (
            <BasicAutoComplete
              marginBottom={STYLE_CONSTANTS.zero_rem}
              hideArrowIcon
              disableClearable
              options={participantList}
              id="disable-close-on-select"
              label={PAGE_CONTENT.find_name_label}
              value={participantForm.participantId || null}
              loading={true}
              isError={participantIdError}
              ErrorMessage={
                participantIdError
                  ? PAGE_CONTENT.name_not_present_error
                  : PAGE_CONTENT.participant_linked_error_message
              }
              placeholder={PAGE_CONTENT.findByNamePlaceHolder}
              loadingText={PAGE_CONTENT.findByNamePlaceHolder}
              onChangeHandler={(value, reason) => {
                if (value?.participantId !== undefined)
                  customFieldChangeHandler('participantId', value);
                else {
                  const participantId = {
                    participantId: '',
                    participantEmail: '',
                    participantLastName: '',
                    participantFirstName: '',
                  };
                  customFieldChangeHandler('participantId', participantId);
                  setFormValue({ ...formModal, participantId });
                  setIsSelectedFromDropdown(false);
                }
                setErrorState(errorInitialState);
                /* istanbul ignore else */
                if (value?.participantId) {
                  fetchParticipantDetails(value.participantId);
                  setIsLinked(true);
                  setNewParticipant(false);
                  setIsSelectedFromDropdown(true);
                }
                /* istanbul ignore else */
                if (reason === 'clear') {
                  setIsLinked(false);
                  setFormValue(formModal);
                  setIsSelectedFromDropdown(false);
                }
                setFilterText('');
              }}
              inputHandler={(e, newValue) => {
                setFilterText(e?.target.value);
                /* istanbul ignore else */
                if (newValue?.split(' ')?.length <= 2) {
                  fetchParticipantList(newValue, projectId);
                }
              }}
              name="participantId"
              customGetOptionsLabel={(option) => {
                /* istanbul ignore else */
                if (
                  option.participantFirstName !== undefined &&
                  option.participantLastName !== undefined
                ) {
                  return `${option.participantFirstName} ${option.participantLastName}`;
                }
              }}
              renderCustomOptions={(props, option) => {
                const text = `${option?.participantFirstName} ${option?.participantLastName} <${option?.participantEmail}>`;
                const index = text
                  .toLowerCase()
                  .indexOf(filterText?.toLowerCase());
                const beforeStr = text.slice(0, index);
                const boldStr = text.slice(index, index + filterText?.length);
                const afterStr = text.slice(index + filterText?.length);
                return (
                  <li {...props} key={option.participantId}>
                    <span style={OptionItemStyle}>{beforeStr}</span>
                    <span style={OptionNoBoldStyle}>{boldStr}</span>
                    <span style={OptionItemStyle}>{afterStr}</span>
                  </li>
                );
              }}
              labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
              labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
              inputFontSize={STYLE_CONSTANTS.input_font_size}
            />
          )}

          <InputWrapper>
            <InputField
              width="90%"
              isRequired
              value={participantForm.participantFirstName}
              label={PAGE_CONTENT.firstName}
              name="participantFirstName"
              category="name-field"
              onUpdate={(e) => {
                formFieldChangeHandler(e);
                e.target.value !== '' &&
                  setErrorState({ ...errorState, firstNameError: false });
                setIsFormEdited(true);
              }}
              placeholder={PAGE_CONTENT.firstNamePlaceHolder}
              primaryError={errorState.firstNameError}
              maxLength={100}
              labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
              rightSideLabelFormLabelMargin={'0rem'}
              labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
              customInputSx={InputSx}
              isDisabled={isSelectdFromDropdown}
            />

            <InputField
              width="88%"
              isRequired
              label={PAGE_CONTENT.lastName}
              name="participantLastName"
              category="name-field"
              value={participantForm.participantLastName}
              onUpdate={(e) => {
                formFieldChangeHandler(e);
                e.target.value !== '' &&
                  setErrorState({ ...errorState, lastNameError: false });
                setIsFormEdited(true);
              }}
              placeholder={PAGE_CONTENT.lastNamePlaceHolder}
              primaryError={errorState.lastNameError}
              maxLength={100}
              labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
              rightSideLabelFormLabelMargin={'0rem'}
              labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
              customInputSx={InputSx}
              isDisabled={isSelectdFromDropdown}
            />
          </InputWrapper>
          <InputField
            width="100%"
            isRequired
            label={PAGE_CONTENT.email}
            isDisabled={editMode || isSelectdFromDropdown}
            name="participantEmail"
            category="email-field"
            value={participantForm.participantEmail}
            onUpdate={handleEmailChange}
            onBlurHandler={(e) => {
              setErrorState({
                ...errorState,
                emailInValidError: checkTernaryCondition(
                  e.target.value.trim(),
                  !validateEmail(e.target.value),
                  false,
                ),
              });
            }}
            placeholder={PAGE_CONTENT.emailPlaceHolder}
            primaryError={errorState.emailError}
            secondaryError={
              errorState.emailInValidError || errorState.duplicateEmailError
            }
            secondaryErrorMessage={checkTernaryCondition(
              errorState.duplicateEmailError,
              PAGE_CONTENT.duplicate_email_error.replace(
                '{{email}}',
                participantForm.participantEmail,
              ),
              PAGE_CONTENT.email_inValid_error,
            )}
            maxLength={100}
            labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
            rightSideLabelFormLabelMargin={'0rem'}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            customInputSx={{ ...InputSx, ...FocusedInputSx }}
          />
          <DropDown
            dropDownMinWidth={'13.75rem'}
            label={PAGE_CONTENT.project_life_cycle_label}
            ariaLabel={PAGE_CONTENT.project_life_cycle_label}
            dropDownPlaceholder={PAGE_CONTENT.project_life_cycle_placeholder}
            value={checkTernaryCondition(
              participantForm.projectCycleId === '',
              PAGE_CONTENT.project_life_cycle_placeholder,
              participantForm.projectCycleId,
            )}
            dropdownlist={projectLifeCycleList}
            setStateValue={() => {
              /* do nothing */
            }}
            setDropDownValInParent={(newVal) => {
              customFieldChangeHandler('projectCycleId', newVal);
              newVal !== '' &&
                setErrorState({ ...errorState, projectCycleIdError: false });
            }}
            setFormFieldValue={() => {
              /* do nothing */
            }}
            height={PAGE_CONTENT.two_rem}
            error={errorState.projectCycleIdError}
            customSx={outlinedDropdownInputSx}
            showLabelAsValue={true}
            labelMarginTop={'1.5rem'}
            hasNoBottomMargin={true}
            labelMarginBottom={'0.25rem'}
            customPlaceholderColor={COOL_GRAY}
            defaultDropdownColor={GRAYISH_BLUE}
          />
          {inParticipantPage && !editMode && (
            <BasicAutoComplete
              width="34.5rem"
              isOptional
              label={PAGE_CONTENT.project_label}
              options={projectList}
              placeholder={PAGE_CONTENT.project_placeholder}
              loadingText={PAGE_CONTENT.project_placeholder}
              value={participantForm?.project}
              id="disable-close-on-select"
              onChangeHandler={(value) => {
                customFieldChangeHandler('project', value);
                onProjectChange(value);
              }}
              inputHandler={(e) => {
                fetchProjectList(e.target.value);
                setFilterText(e?.target.value);
              }}
              isError={errorState.projectError}
              name="project"
              ErrorMessage={projectErrorMessage}
              renderCustomOptions={(props, option) => {
                const lowerTextCase = filterText?.toLowerCase();
                const beginIdx = option?.label
                  .toLowerCase()
                  .indexOf(lowerTextCase);
                /* istanbul ignore else */
                if (beginIdx === -1) return null;

                return (
                  <li {...props} key={option.value}>
                    <span style={OptionListStyle}>
                      {option?.label.substr(0, beginIdx)}
                    </span>
                    <span style={OptionNoBoldStyle}>
                      {option?.label.substr(beginIdx, filterText.length)}
                    </span>
                    <span style={OptionListStyle}>
                      {option?.label.substr(beginIdx + filterText.length)}
                    </span>
                  </li>
                );
              }}
            />
          )}
        </DialogContent>
        <DialogActions style={DialogActionStyle}>
          <Button
            onClick={modalCloseHandler}
            sx={[tertiaryButtonStyle, modalActionButtonDefaultStyle]}>
            {PAGE_CONTENT.cancelBtn}
          </Button>
          <Button
            sx={[ButtonStyle, primaryButtonStyle('4.5px 15px')]}
            onClick={() => submitHandler({ enrollParticipant: false })}
            variant={editMode ? 'contained' : 'outlined'}
            disabled={
              !formHasDataWithoutErrors ||
              checkTernaryCondition(editMode, !isFormEdited, loading)
            }>
            {checkTernaryCondition(
              editMode,
              PAGE_CONTENT.saveEditBtn,
              PAGE_CONTENT.addPageTitle,
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddParticipants.propTypes = {
  refreshHandler: PropTypes.func,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  particpantId: PropTypes.string,
  inParticipantPage: PropTypes.bool,
  setParticipantId: PropTypes.func,
  projectLifeCycleList: PropTypes.array,
};
export default AddParticipants;
