import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import InputField from 'components/FormComponents/InputField/InputField.react';
import PropTypes from 'prop-types';
import {
  communicationMethodList,
  STYLE_CONSTANTS,
  validateCommunicationMethod,
} from '../../components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { InputSx } from '../../components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import { modalActionButtonDefaultStyle } from 'theme/GlobalStyles';
import {
  FocusedInputSx,
  primaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { useForm } from 'hooks/useForm';
import { useEffect, useRef, useState } from 'react';
import {
  checkTernaryCondition,
  formatPhoneNumber,
  replaceSpecialCharactersWithSingleCharacter,
  validatePhone,
} from 'utils/helper';

import { uniqueId } from 'utils/uniqueIdGenerator';
import CheckboxFormGroup from 'components/FormComponents/CheckboxFormGroup/CheckboxFormGroup.react';
import { MODAL_CONTENT, validateEmail } from './AddContributorModal.content';
import {
  ButtonStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogSubTitle,
  DialogTitleStyle,
  InputWrapper,
  tertiaryButtonStyle,
  TitleWrapper,
} from './AddContrbutorModal.style';
import { CHECK_DUPLICATE_EMAIL, USER_CHECK_EMAIL } from 'urls';
import axios from 'axios';
import { fetchAllParticipantContributors } from '../ParticipantContributorsForm/ParticipantContributorsForm.funtions';
import { formLabelCustomSx } from 'pages/AddOriginationProject/AddOriginationProject.style';

const formModal = {
  participantFirstName: '',
  participantId: null,
  participantLastName: '',
  participantPhone: '',
  primaryCommunication: communicationMethodList,
  participantEmail: '',
  farmProjectAssignment: [],
};

const errorInitialState = {
  participantFirstNameError: false,
  participantIdError: false,
  participantLastNameError: false,
  participantPhoneError: false,
  participantEmailError: false,
  primaryCommunicationError: false,
  emailInValidError: false,
  phoneInValidError: false,
  duplicateEmailError: false,
};

const AddContributorModal = ({
  isModalOpen,
  setIsModalOpen,
  exisitingContributorsList,
  setParticipantContributors,
  setExistingContributorList,
}) => {
  const {
    formValue: participantForm,
    formFieldChangeHandler,
    customFieldChangeHandler,
    setFormValue,
  } = useForm(formModal);
  const [errorStateForAddContributor, setErrorStateForAddContributor] =
    useState(errorInitialState);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);
  const [selectedEmailId, setSelectedEmailId] = useState('');
  const buttonRef = useRef(null);

  const addModalCloseHandler = () => {
    setFormValue(formModal);
    setErrorStateForAddContributor(errorInitialState);
    setIsFormEdited(false);
    setIsModalOpen(false);
  };

  const handleEmailChangeForAddContributor = (e) => {
    formFieldChangeHandler(e);
    let errorStateUpdates = {
      emailInValidError: false,
      duplicateEmailError: false,
      participantEmailError: e.target.value === '',
    };
    setErrorStateForAddContributor((prev) => ({
      ...prev,
      ...errorStateUpdates,
    }));
    setIsFormEdited(true);
  };

  const handlePhoneChangeForAddModal = (e) => {
    formFieldChangeHandler(e);
    let errorStateUpdates = {
      participantPhoneError: e.target.value === '',
      phoneInValidError: e.target.value === '',
    };
    setErrorStateForAddContributor((prev) => ({
      ...prev,
      ...errorStateUpdates,
    }));
    setIsFormEdited(true);
  };

  useEffect(() => {
    if (isModalOpen) {
      setFormValue(formModal);
      setErrorStateForAddContributor(errorInitialState);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (emailExists) {
      setErrorStateForAddContributor({
        ...errorStateForAddContributor,
        participantFirstNameError:
          !participantForm?.participantFirstName?.trim(),
        participantIdError: false,
        participantPhoneError: false,
        participantLastNameError: !participantForm?.participantLastName?.trim(),
        participantEmailError: !participantForm?.participantEmail?.trim(),
        primaryCommunicationError: false,
        duplicateEmailError: false,
      });
    }
  }, [emailExists]);

  const addModalHasErrors = Object.values(errorStateForAddContributor).includes(
    true,
  );

  const checkDuplicateEmail = async (email, participantId) => {
    axios
      .get(
        `${CHECK_DUPLICATE_EMAIL}?id=${
          participantId ?? 0
        }&email=${email}&type=PARTICIPANT`,
      )
      .then((response) => {
        if (!response.data) {
          axios.get(`${USER_CHECK_EMAIL}${email}`).then((response) => {
            //if the email is found in the user table and he is not a already added contributor, then it is a duplicate email
            if (response.data) {
              setErrorStateForAddContributor({
                ...errorStateForAddContributor,
                duplicateEmailError: response.data,
              });
              return;
            }
          });
        } else {
          setEmailExists(response.data);
          return;
        }
      });
    fetchAllParticipantContributors().then((response) => {
      const existingContributor = response.data.find(
        (item) => item?.participantEmail === email,
      );
      if (existingContributor) {
        setFormValue((prev) => ({
          ...prev,
          participantFirstName: existingContributor?.participantFirstName,
          participantLastName: existingContributor?.participantLastName,
          participantId: existingContributor?.participantId,
        }));
      }
    });
  };

  const submitHandlerForAddModal = async ({ participantForm }) => {
    setErrorStateForAddContributor({
      ...errorStateForAddContributor,
      participantFirstNameError: !participantForm?.participantFirstName?.trim(),
      participantLastNameError: !participantForm?.participantLastName?.trim(),
      participantIdError: false,
      participantEmailError: !participantForm?.participantEmail?.trim(),
      participantPhoneError: !participantForm?.participantPhone?.trim(),
      primaryCommunicationError: !validateCommunicationMethod(
        participantForm.primaryCommunication,
      ),
    });
    const formError = () => {
      return (
        participantForm?.participantFirstName?.trim() &&
        participantForm?.participantLastName?.trim() &&
        participantForm?.participantEmail?.trim() &&
        participantForm?.participantPhone?.trim() &&
        participantForm?.primaryCommunication.some((check) => check.checked) &&
        !errorStateForAddContributor.duplicateEmailError &&
        validateEmail(participantForm.participantEmail)
      );
    };
    if (formError()) {
      addModalCloseHandler();
    }
    if (addModalHasErrors) {
      setIsModalOpen(false);
    }
    setExistingContributorList((prev) => [
      ...prev,
      {
        label: `${participantForm.participantFirstName} ${participantForm.participantLastName} <${participantForm.participantEmail}>`,
        participantFirstName: participantForm.participantFirstName,
        participantLastName: participantForm.participantLastName,
        participantEmail: participantForm.participantEmail,
        participantPhone: formatPhoneNumber(participantForm.participantPhone),
        primaryCommunication: participantForm.primaryCommunication
          .filter((filteredList) => filteredList.checked === true)
          ?.map((item) => item.itemLabel)
          .toString(),
        value: checkTernaryCondition(
          emailExists,
          Number(participantForm.participantId),
          uniqueId(),
        ),
      },
    ]);
    setParticipantContributors((prev) => [
      ...prev,
      `${participantForm.participantFirstName} ${participantForm.participantLastName} <${participantForm.participantEmail}>`,
    ]);
    setIsModalOpen(false);
  };

  useEffect(() => {
    setSelectedEmailId(participantForm.participantEmail);
  }, [participantForm.participantEmail]);

  useEffect(() => {
    if (isNextButtonClicked) {
      buttonRef.current?.blur();
    }
  }, [isNextButtonClicked]);

  useEffect(() => {
    const { participantId, participantEmail } = participantForm;
    if (participantEmail !== '' && validateEmail(participantEmail)) {
      checkDuplicateEmail(
        participantEmail,
        checkTernaryCondition(participantId === '', 0, participantId),
      );
    }
  }, [participantForm.participantEmail]);

  useEffect(() => {
    if (isFormEdited) {
      setErrorStateForAddContributor((prev) => ({
        ...prev,
        primaryCommunicationError: checkTernaryCondition(
          participantForm.primaryCommunication.filter((row) => !row.checked)
            .length === 3,
          true,
          false,
        ),
      }));
    }
  }, [participantForm?.primaryCommunication]);

  useEffect(() => {
    if (selectedEmailId) {
      const filteredContributors = exisitingContributorsList.filter(
        (contributor) => contributor?.participantEmail === selectedEmailId,
      );
      if (filteredContributors.length > 0) {
        setFormValue((prev) => ({
          ...prev,
          participantFirstName: filteredContributors[0]?.participantFirstName,
          participantLastName: filteredContributors[0]?.participantLastName,
        }));
      }
    }
  }, [selectedEmailId]);

  const getEmptyFieldNames = (form) => {
    let emptyFields = [];
    for (const [key, value] of Object.entries(form)) {
      if (
        Array.isArray(value) &&
        value.every((item) => item?.checked === false) &&
        value.length > 0
      ) {
        emptyFields.push(key); // Add the field name if the array is empty
      }
      if ((value === '' || value === undefined) && key !== 'participantId') {
        emptyFields.push(key); // Add the field name if the string is empty
      }
    }
    return emptyFields; // Return the array of empty field names
  };

  return (
    <Dialog
      disableRestoreFocus
      open={isModalOpen}
      sx={DialogStyle}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby={MODAL_CONTENT.aria_labelledby}
      aria-describedby={MODAL_CONTENT.aria_describedby}>
      <TitleWrapper>
        <DialogTitle sx={DialogTitleStyle} data-testid="modal-title">
          {MODAL_CONTENT.add_participant_contributor_label}
        </DialogTitle>
      </TitleWrapper>
      <DialogSubTitle data-testid="modal-sub-title">
        {checkTernaryCondition(
          emailExists,
          MODAL_CONTENT.existing_participant_label,
          MODAL_CONTENT.add_participant_contributor_content,
        )}
      </DialogSubTitle>
      <DialogContent sx={DialogContentStyle}>
        <InputField
          label={MODAL_CONTENT.email_label}
          width="34rem"
          isRequired
          name="participantEmail"
          category="email-field"
          value={participantForm.participantEmail}
          onUpdate={handleEmailChangeForAddContributor}
          onBlurHandler={(e) => {
            setErrorStateForAddContributor({
              ...errorStateForAddContributor,
              emailInValidError: checkTernaryCondition(
                e.target.value?.trim(),
                !validateEmail(e.target.value),
                false,
              ),
            });
          }}
          placeholder={MODAL_CONTENT.email_placeholder}
          primaryError={errorStateForAddContributor.participantEmailError}
          secondaryError={errorStateForAddContributor.emailInValidError}
          secondaryErrorMessage={MODAL_CONTENT.email_invalid_domain_error}
          thirdError={errorStateForAddContributor.duplicateEmailError}
          thirdErrorMessage={MODAL_CONTENT.duplicate_email_error.replace(
            '{{email}}',
            participantForm.participantEmail,
          )}
          maxLength={100}
          labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
          customInputSx={{ ...InputSx, ...FocusedInputSx }}
          nonEditable={emailExists}
        />
        {isNextButtonClicked && (
          <>
            <InputWrapper>
              <InputField
                isRequired
                width="16.25rem"
                minWidth="16.75rem"
                label={MODAL_CONTENT.first_name_label}
                value={participantForm.participantFirstName}
                category="name-field"
                onUpdate={(e) => {
                  formFieldChangeHandler(e);

                  setErrorStateForAddContributor({
                    ...errorStateForAddContributor,
                    participantFirstNameError: e.target.value === '',
                  });
                  setIsFormEdited(true);
                }}
                name="participantFirstName"
                placeholder={MODAL_CONTENT.first_name_placeholder}
                primaryError={
                  errorStateForAddContributor.participantFirstNameError
                }
                labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                maxLength={100}
                customInputSx={{ ...InputSx, ...FocusedInputSx }}
                nonEditable={emailExists}
              />
              <InputField
                width="16.25rem"
                minWidth="16.75rem"
                isRequired
                name="participantLastName"
                category="name-field"
                value={participantForm.participantLastName}
                onUpdate={(e) => {
                  formFieldChangeHandler(e);
                  setErrorStateForAddContributor({
                    ...errorStateForAddContributor,
                    participantLastNameError: e.target.value === '',
                  });
                  setIsFormEdited(true);
                }}
                placeholder={MODAL_CONTENT.last_name_placeholder}
                primaryError={
                  errorStateForAddContributor.participantLastNameError
                }
                maxLength={100}
                labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                customInputSx={{ ...InputSx, ...FocusedInputSx }}
                nonEditable={emailExists}
                label={MODAL_CONTENT.last_name_label}
              />
            </InputWrapper>
            {!emailExists && (
              <>
                <InputField
                  customInputSx={{ ...InputSx, ...FocusedInputSx }}
                  customStyles={{ width: '100%', marginTop: '-16px' }}
                  width="32%"
                  isRequired
                  label={MODAL_CONTENT.phone_label}
                  name="participantPhone"
                  category="phone-field"
                  value={formatPhoneNumber(participantForm.participantPhone)}
                  onUpdate={handlePhoneChangeForAddModal}
                  onBlurHandler={(event) => {
                    if (event.target.value !== '') {
                      const value = replaceSpecialCharactersWithSingleCharacter(
                        /[()\-\s]/g,
                        event.target.value,
                        '',
                      );
                      setErrorStateForAddContributor({
                        ...errorStateForAddContributor,
                        phoneInValidError: !validatePhone(value),
                      });
                    }
                  }}
                  primaryError={
                    errorStateForAddContributor.participantPhoneError
                  }
                  maxLength={100}
                  secondaryError={errorStateForAddContributor.phoneInValidError}
                  secondaryErrorMessage={MODAL_CONTENT.phone_invalid_error}
                  placeholder={MODAL_CONTENT.phone_placeholder}
                  rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                  labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  customPattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
                />
                <CheckboxFormGroup
                  list={participantForm.primaryCommunication}
                  label={MODAL_CONTENT.communication_method_label}
                  instructionText={MODAL_CONTENT.communication_method_helptext}
                  primaryError={
                    errorStateForAddContributor.primaryCommunicationError
                  }
                  gap="1.5rem"
                  formLabelCustomSx={formLabelCustomSx}
                  checkboxPadding={'0rem 0.5625rem'}
                  onClickHandler={(methodIndex) => {
                    setIsFormEdited(true);
                    customFieldChangeHandler(
                      'primaryCommunication',
                      participantForm.primaryCommunication?.map(
                        (method, index) => ({
                          ...method,
                          checked: checkTernaryCondition(
                            methodIndex === index,
                            !method.checked,
                            method.checked,
                          ),
                        }),
                      ),
                    );
                  }}
                  labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                  name="primaryCommunication"
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  titleAndInfoGap={STYLE_CONSTANTS.zero_point_two_five_rem}
                />
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          onClick={addModalCloseHandler}
          sx={[tertiaryButtonStyle, modalActionButtonDefaultStyle]}>
          {MODAL_CONTENT.cancelBtn}
        </Button>

        <Button
          ref={buttonRef}
          disabled={
            (isNextButtonClicked && addModalHasErrors) ||
            errorStateForAddContributor.emailInValidError ||
            errorStateForAddContributor.participantEmailError ||
            errorStateForAddContributor.duplicateEmailError
          }
          sx={[ButtonStyle, primaryButtonStyle('4.5px 15px')]}
          onClick={() => {
            if (!isNextButtonClicked) {
              if (participantForm?.participantEmail === '') {
                setErrorStateForAddContributor((prevState) => ({
                  ...prevState,
                  participantEmailError: true,
                }));
              } else {
                setIsNextButtonClicked(true);
                setErrorStateForAddContributor(errorInitialState);
              }
            } else if (
              getEmptyFieldNames(participantForm)?.length > 0 &&
              !emailExists
            ) {
              getEmptyFieldNames(participantForm).forEach((field) => {
                setErrorStateForAddContributor((prevState) => ({
                  ...prevState,
                  [field + 'Error']: true,
                }));
              });
            } else {
              submitHandlerForAddModal({ participantForm });
            }
          }}>
          {checkTernaryCondition(
            isNextButtonClicked,
            MODAL_CONTENT.submit_btn_label,
            MODAL_CONTENT.next_button,
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddContributorModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  exisitingContributorsList: PropTypes.array,
  setParticipantContributors: PropTypes.func,
  setExistingContributorList: PropTypes.func,
};
export default AddContributorModal;
