import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  FORM_CONTENT,
  validateEmail,
} from './ParticipantContributorsForm.content';
import InputField from 'components/FormComponents/InputField/InputField.react';
import CheckboxFormGroup from 'components/FormComponents/CheckboxFormGroup/CheckboxFormGroup.react';
import {
  ButtonStyle,
  CheckBoxStyle,
  checkboxStyleForInput,
  closeIconSx,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  FarmProjectError,
  FarmProjectTitleWrapper,
  FarmsTitleStyle,
  FarmTitleWrapper,
  IconButtonStyle,
  InputWrapper,
  ProjectTitleWrapper,
  tertiaryButtonStyle,
  TitleWrapper,
} from './ParticipantContributorsForm.style';
import CloseIcon from '@mui/icons-material/Close';
import {
  communicationMethodList,
  STYLE_CONSTANTS,
  validateCommunicationMethod,
} from '../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { InputSx } from '../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import { modalActionButtonDefaultStyle } from 'theme/GlobalStyles';
import {
  FocusedInputSx,
  primaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { useForm } from 'hooks/useForm';
import { useEffect, useRef, useState } from 'react';
import {
  checkTernaryCondition,
  replaceSpecialCharactersWithSingleCharacter,
  validatePhone,
  formatPhoneNumber,
  isEmpty,
  debounceFunc,
} from 'utils/helper';
import {
  addFarmContributor,
  fetchAddContributorFarmAndProject,
} from './ParticipantContributorsForm.funtions';
import axios from 'axios';
import {
  CHECK_DUPLICATE_EMAIL,
  FETCH_PROJECT_LIFECYCLES,
  USER_CHECK_EMAIL,
} from 'urls';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';

const formModal = {
  participantFirstName: '',
  participantLastName: '',
  participantEmail: '',
  participantPhone: '',
  primaryCommunication: communicationMethodList,
  farmProjectAssignment: [],
};
const errorInitialState = {
  participantFirstNameError: false,
  participantLastNameError: false,
  participantEmailError: false,
  emailInValidError: false,
  participantPhoneError: false,
  phoneInValidError: false,
  primaryCommunicationError: false,
  farmProjectAssignmentError: false,
  duplicateEmailError: false,
  duplicateContributorEmailError: false,
};

const ParticipantContributorModal = ({
  isModalOpen,
  setIsModalOpen,
  participantOwnerId,
  refreshHandler,
  internalProjectId,
}) => {
  const {
    formValue: participantForm,
    formFieldChangeHandler,
    customFieldChangeHandler,
    nestedFieldChangeHandler,
    setFormValue,
  } = useForm(formModal);
  const [errorStateForAddContributor, setErrorStateForAddContributor] =
    useState({
      errorInitialState,
    });
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubItems, setSelectedSubItems] = useState({});
  const [farmProjectAssignmentList, setFarmProjectAssignmentList] = useState(
    [],
  );
  const [projectCycleId, setProjectCycleId] = useState(null);
  const [duplicateEmailErrorMessage, setDuplicateEmailErrorMessage] =
    useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const timeRef = useRef(null);

  const addModalCloseHandler = () => {
    setFormValue(formModal);
    setErrorStateForAddContributor(errorInitialState);
    setIsFormEdited(false);
    setIsModalOpen(false);
  };

  const addModalHasErrors = Object.values(errorStateForAddContributor).includes(
    true,
  );

  const farmContributorList = farmProjectAssignmentList.filter(
    (name) => name.farmName !== null || '' || undefined,
  );

  const validateProjectSelection = (selectedFarmId, selectedProjectIds) => {
    if (
      (selectedFarmId !== undefined && selectedProjectIds.length === 0) ||
      selectedCategories.length === 0
    ) {
      setErrorStateForAddContributor((prevState) => ({
        ...prevState,
        farmProjectAssignmentError: true,
      }));
    } else
      setErrorStateForAddContributor((prevState) => ({
        ...prevState,
        farmProjectAssignmentError: false,
      }));
  };
  const handleEmailChangeForAddContributor = (e) => {
    formFieldChangeHandler(e);
    const emailValue = e.target.value.trim();
    if (!isEmpty(emailValue) && validateEmail(emailValue)) {
      debounceCheckDuplicateEmail(emailValue);
    } else {
      let errorStateUpdates = {
        participantEmailError: isEmpty(emailValue),
        emailInValidError: checkTernaryCondition(
          isEmpty(emailValue),
          !validateEmail(emailValue),
          false,
        ),
        duplicateContributorEmailError: false,
        duplicateEmailError: false,
      };
      setErrorStateForAddContributor((prev) => ({
        ...prev,
        ...errorStateUpdates,
      }));
    }
    setIsFormEdited(true);
  };

  const handlePhoneChangeForAddModal = (e) => {
    const phoneValue = formatPhoneNumber(e.target.value.trim());
    customFieldChangeHandler('participantPhone', phoneValue);
    let errorStateUpdates = {
      participantPhoneError: isEmpty(phoneValue),
      phoneInValidError: false,
    };
    setErrorStateForAddContributor((prev) => ({
      ...prev,
      ...errorStateUpdates,
    }));
    setIsFormEdited(true);
  };
  const handleCategoryChange = (farm) => {
    const isSelected = selectedCategories.includes(farm.farmId);
    const updatedSelectedCategories = isSelected
      ? selectedCategories.filter((cat) => cat !== farm.farmId)
      : [...selectedCategories, farm.farmId];
    setSelectedCategories(updatedSelectedCategories);
    // Update selectedSubItems
    setSelectedSubItems((prevSelectedSubItems) => {
      const updatedSelectedSubItems = { ...prevSelectedSubItems };
      if (isSelected) {
        // If farmId is being deselected, remove it from selectedSubItems
        delete updatedSelectedSubItems[farm.farmId];
      } else {
        // If farmId is being selected, add or update its projects in selectedSubItems
        const farmProjects = farm.projects.map((project) => project.projectId);
        updatedSelectedSubItems[farm.farmId] = farmProjects;
      }
      return updatedSelectedSubItems;
    });
    setIsFormEdited(true);
    if (isSelected) {
      setErrorStateForAddContributor((prevState) => ({
        ...prevState,
        farmProjectAssignmentError: true,
      }));
    }
  };

  const handleSubItemChange = (farm, project) => {
    const currentSubItems = selectedSubItems[farm] || [];
    const isSelected = currentSubItems.includes(project.projectId);
    const updatedSubItems = { ...selectedSubItems };
    if (isSelected) {
      updatedSubItems[farm] = currentSubItems.filter(
        (item) => item !== project.projectId,
      );
    } else {
      updatedSubItems[farm] = [...currentSubItems, project.projectId];
    }

    /** Code to handle select and unselect parent check box based on sub items */
    const selectedFarm = farmContributorList.find(
      (item) => item.farmId == farm,
    );
    if (selectedFarm.projects.length === updatedSubItems[farm].length) {
      handleCategoryChange(selectedFarm);
    } else {
      setSelectedCategories(selectedCategories.filter((cat) => cat !== farm));
    }
    /** End of Code : to handle select and unselect parent check box based on sub items */
    setSelectedSubItems({
      ...selectedSubItems,
      [farm]: updatedSubItems[farm],
    });
    setIsFormEdited(true);
    if (isSelected) {
      setErrorStateForAddContributor((prevState) => ({
        ...prevState,
        farmProjectAssignmentError: true,
      }));
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      setFormValue(formModal);
      setErrorStateForAddContributor(errorInitialState);
    }
  }, [isModalOpen]);

  useEffect(() => {
    fetchAddContributorFarmAndProject(participantOwnerId).then((data) =>
      setFarmProjectAssignmentList(data),
    );
  }, []);

  useEffect(() => {
    if (isFormEdited) {
      setErrorStateForAddContributor({
        ...errorStateForAddContributor,
        primaryCommunicationError: !validateCommunicationMethod(
          participantForm.primaryCommunication,
        ),
      });
    }
  }, [participantForm.primaryCommunication]);

  const checkDuplicateEmail = async (email) => {
    setIsSubmitDisabled(true);
    //Check first the email is already added as a contributor
    axios
      .get(
        `${CHECK_DUPLICATE_EMAIL}?id=${
          participantOwnerId ?? 0
        }&email=${email}&type=PARTICIPANT`,
      )
      .then((response) => {
        if (response.data) {
          setIsSubmitDisabled(false);
          setErrorStateForAddContributor({
            ...errorStateForAddContributor,
            duplicateContributorEmailError: response.data,
            duplicateEmailError: false,
          });
          setDuplicateEmailErrorMessage(
            FORM_CONTENT.DUPLICATE_CONTRIBUTOR_EMAIL_ERROR,
          );
        } else {
          //Check if the email is already added as a user
          axios.get(`${USER_CHECK_EMAIL}${email}`).then((response) => {
            setIsSubmitDisabled(false);
            setErrorStateForAddContributor({
              ...errorStateForAddContributor,
              duplicateEmailError: response.data,
              duplicateContributorEmailError: false,
            });
            setDuplicateEmailErrorMessage(
              FORM_CONTENT.duplicate_email_error.replace('{{email}}', email),
            );
          });
        }
      });
  };

  const debounceCheckDuplicateEmail = debounceFunc(
    checkDuplicateEmail,
    500,
    timeRef,
  );
  const fetchProjectCycles = () => {
    axios
      .get(FETCH_PROJECT_LIFECYCLES, {
        params: {
          internalProjectId: internalProjectId,
          participantId: participantOwnerId,
        },
      })
      .then((response) => {
        setProjectCycleId(response?.data[0]?.projectCycleId);
      });
  };
  useEffect(() => {
    fetchProjectCycles();
  }, [participantOwnerId]);

  const submitHandlerForAddModal = async ({ participantForm }) => {
    let hasFarmProjectError = false;
    if (selectedCategories.length === 0) {
      hasFarmProjectError = true;
    }
    setErrorStateForAddContributor({
      ...errorStateForAddContributor,
      participantFirstNameError: !participantForm.participantFirstName.trim(),
      participantLastNameError: !participantForm.participantLastName.trim(),
      participantEmailError: !participantForm.participantEmail.trim(),
      participantPhoneError: !participantForm.participantPhone.trim(),
      primaryCommunicationError: !validateCommunicationMethod(
        participantForm.primaryCommunication,
      ),
      farmProjectAssignmentError: hasFarmProjectError,
    });
    const formError = () => {
      return (
        participantForm.participantFirstName.trim() &&
        participantForm.participantLastName.trim() &&
        participantForm.participantEmail.trim() &&
        participantForm.participantPhone.trim() &&
        selectedCategories.length !== 0 &&
        participantForm?.primaryCommunication
          .map((check) => check.checked)
          .includes(true) &&
        !errorStateForAddContributor.duplicateEmailError &&
        validateEmail(participantForm.participantEmail)
      );
    };

    addFarmContributor({
      participantForm,
      participantOwnerId,
      selectedCategories,
      selectedSubItems,
      refreshHandler,
      projectCycleId,
    });
    if (formError()) {
      addModalCloseHandler();
    }
    if (addModalHasErrors) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    selectedCategories.forEach((selectedFarmId) => {
      const selectedProjectIds = selectedSubItems[selectedFarmId] || [];
      validateProjectSelection(selectedFarmId, selectedProjectIds);
      nestedFieldChangeHandler(
        'farmProjectAssignment',
        selectedFarmId,
        selectedProjectIds,
      );
    });
  }, [selectedCategories, selectedSubItems]);

  const hasError = errorStateForAddContributor.farmProjectAssignmentError;

  const initialButtonDisabled =
    participantForm.participantFirstName.trim() === '' ||
    participantForm.participantLastName.trim() === '';

  return (
    <Dialog
      disableRestoreFocus
      open={isModalOpen}
      sx={DialogStyle}
      onClose={() => setIsModalOpen(false)}
      scroll={FORM_CONTENT.paper}
      aria-labelledby={FORM_CONTENT.aria_labelledby}
      aria-describedby={FORM_CONTENT.aria_describedby}>
      <TitleWrapper>
        <DialogTitle sx={DialogTitleStyle} data-testid="modal-title">
          {FORM_CONTENT.add_participant_contributor_label}
        </DialogTitle>
        <IconButton sx={IconButtonStyle} onClick={addModalCloseHandler}>
          <CloseIcon sx={closeIconSx} />
        </IconButton>
      </TitleWrapper>
      <DialogContent sx={DialogContentStyle}>
        <InputWrapper>
          <InputField
            width="16.25rem"
            minWidth="16.75rem"
            isRequired
            label={FORM_CONTENT.first_name_label}
            value={participantForm.participantFirstName}
            category="name-field"
            onUpdate={(e) => {
              formFieldChangeHandler(e);
              setErrorStateForAddContributor({
                ...errorStateForAddContributor,
                participantFirstNameError: e.target.value === '',
              });
              setIsFormEdited(true);
            }}
            name="participantFirstName"
            placeholder={FORM_CONTENT.first_name_placeholder}
            primaryError={errorStateForAddContributor.participantFirstNameError}
            labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            maxLength={100}
            customInputSx={{ ...InputSx, ...FocusedInputSx }}
          />
          <InputField
            width="16.25rem"
            minWidth="16.75rem"
            isRequired
            name="participantLastName"
            value={participantForm.participantLastName}
            category="name-field"
            onUpdate={(e) => {
              formFieldChangeHandler(e);
              setErrorStateForAddContributor({
                ...errorStateForAddContributor,
                participantLastNameError: e.target.value === '',
              });
              setIsFormEdited(true);
            }}
            placeholder={FORM_CONTENT.last_name_placeholder}
            primaryError={errorStateForAddContributor.participantLastNameError}
            maxLength={100}
            labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            customInputSx={{ ...InputSx, ...FocusedInputSx }}
            label={FORM_CONTENT.last_name_label}
          />
        </InputWrapper>
        <InputField
          label={FORM_CONTENT.email_label}
          width="34rem"
          isRequired
          category="email-field"
          name="participantEmail"
          value={participantForm.participantEmail}
          onUpdate={handleEmailChangeForAddContributor}
          onBlurHandler={(e) => {
            setErrorStateForAddContributor({
              ...errorStateForAddContributor,
              emailInValidError: checkTernaryCondition(
                e.target.value.trim(),
                !validateEmail(e.target.value),
                false,
              ),
            });
          }}
          placeholder={FORM_CONTENT.email_placeholder}
          primaryError={errorStateForAddContributor.participantEmailError}
          secondaryError={
            errorStateForAddContributor.emailInValidError ||
            errorStateForAddContributor.duplicateEmailError ||
            errorStateForAddContributor.duplicateContributorEmailError
          }
          secondaryErrorMessage={checkTernaryCondition(
            errorStateForAddContributor.duplicateEmailError ||
              errorStateForAddContributor.duplicateContributorEmailError,
            duplicateEmailErrorMessage,
            FORM_CONTENT.email_invalid_domain_error,
          )}
          maxLength={100}
          labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
          customInputSx={{ ...InputSx, ...FocusedInputSx }}
        />
        <InputField
          customInputSx={{ ...InputSx, ...FocusedInputSx }}
          customStyles={{ width: '100%' }}
          width="32%"
          isRequired
          label={FORM_CONTENT.phone_label}
          name="participantPhone"
          category="phone-field"
          value={participantForm.participantPhone}
          onUpdate={handlePhoneChangeForAddModal}
          onBlurHandler={(event) => {
            if (event.target.value !== '') {
              const value = replaceSpecialCharactersWithSingleCharacter(
                /[()\-\s]/g,
                event.target.value,
                '',
              );
              setErrorStateForAddContributor({
                ...errorStateForAddContributor,
                phoneInValidError: !validatePhone(value),
              });
            } else {
              setErrorStateForAddContributor({
                ...errorStateForAddContributor,
                phoneInValidError: false,
              });
            }
          }}
          primaryError={errorStateForAddContributor.participantPhoneError}
          maxLength={100}
          secondaryError={errorStateForAddContributor.phoneInValidError}
          secondaryErrorMessage={FORM_CONTENT.phone_invalid_error}
          placeholder={FORM_CONTENT.phone_placeholder}
          rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
          labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          customPattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
        />
        <CheckboxFormGroup
          list={participantForm.primaryCommunication}
          label={FORM_CONTENT.communication_method_label}
          instructionText={FORM_CONTENT.communication_method_helptext}
          primaryError={errorStateForAddContributor.primaryCommunicationError}
          checkboxPadding={'0rem 0.5625rem'}
          onClickHandler={(methodIndex) => {
            setIsFormEdited(true);
            customFieldChangeHandler(
              'primaryCommunication',
              participantForm.primaryCommunication.map((method, index) => ({
                ...method,
                checked: checkTernaryCondition(
                  methodIndex === index,
                  !method.checked,
                  method.checked,
                ),
              })),
            );
          }}
          labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
          gap="1.625rem"
          name="primaryCommunication"
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          titleAndInfoGap={STYLE_CONSTANTS.zero_point_two_five_rem}
        />
        <FarmProjectTitleWrapper iserror={hasError}>
          Farm and project assignment
          {hasError && <AlertIcon data-testid="farm-error-icon" />}
        </FarmProjectTitleWrapper>
        <FarmsTitleStyle>
          {farmContributorList.map((farm, farmIndex) => {
            const keyIndex = farmIndex;
            return (
              <div key={keyIndex}>
                <label>
                  <FarmTitleWrapper>
                    <Checkbox
                      id={farm.farmId}
                      onChange={() => handleCategoryChange(farm)}
                      checked={selectedCategories.includes(farm.farmId)}
                      style={checkboxStyleForInput}
                      sx={CheckBoxStyle}
                    />
                    {farm.farmName}
                  </FarmTitleWrapper>
                  {farm.projects.map((project, projectIndex) => {
                    const isProjectChecked = selectedSubItems[
                      farm.farmId
                    ]?.includes(project.projectId);
                    const keyIndex = projectIndex;
                    return (
                      <div key={keyIndex}>
                        <label>
                          <ProjectTitleWrapper>
                            <Checkbox
                              key={isProjectChecked}
                              id={`${project.projectId}`}
                              checked={isProjectChecked}
                              style={checkboxStyleForInput}
                              onChange={() =>
                                handleSubItemChange(farm.farmId, project)
                              }
                              sx={CheckBoxStyle}
                            />
                            {checkTernaryCondition(
                              project.projectName,
                              project.projectName,
                              null,
                            )}
                          </ProjectTitleWrapper>
                        </label>
                      </div>
                    );
                  })}
                </label>
              </div>
            );
          })}
        </FarmsTitleStyle>
        {hasError && (
          <FarmProjectError iserror={hasError}>
            {FORM_CONTENT.farm_and_project_assignment_error}
          </FarmProjectError>
        )}
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          onClick={addModalCloseHandler}
          sx={[tertiaryButtonStyle, modalActionButtonDefaultStyle]}>
          {FORM_CONTENT.cancelBtn}
        </Button>

        <Button
          sx={[ButtonStyle, primaryButtonStyle('4.5px 15px')]}
          onClick={() => submitHandlerForAddModal({ participantForm })}
          disabled={
            addModalHasErrors || isSubmitDisabled || initialButtonDisabled
          }>
          {FORM_CONTENT.add_participant_contributor_label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ParticipantContributorModal;

ParticipantContributorModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  participantOwnerId: PropTypes.number.isRequired,
  refreshHandler: PropTypes.func.isRequired,
  internalProjectId: PropTypes.number.isRequired,
};
