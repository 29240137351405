import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AccordionDetailsSx,
  AccordionSummarySx,
  AccordionSx,
  ArrowLabelWrapper,
  DataWrapper,
  Field,
  FieldLabel,
  FieldValueWrapper,
  KeyBoardArrowSx,
  LabelAndButtonWrapper,
  MainWrapper,
  PracticesDataWrapper,
  RowData,
  RowDataWrapper,
  RowWrapper,
  SecondaryLabelWrapper,
  TextWrapper,
  TitleWrapper,
  addBtnSx,
} from './AccordionDisplayBox.style';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import { checkTernaryCondition, isEmpty } from 'utils/helper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIconWithHoverEffect from '@mui/icons-material/DeleteOutline';
import { ReactComponent as EditIconWithHoverEffect } from 'assets/icons/EditIconWithHoverEffect.svg';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { DARK_CHARCOAL, QUILL_GRAY } from 'theme/GlobalColors';
import EmptyStateComponent from '../EmptyStateComponent.react';
import { MODAL_CONTENT as TILLAGE_CONTENT } from '../../BaseLineFieldInfo/components/modals/TillageModal/TillageModal.content';
import { MODAL_CONTENT as RESIDUE_MANAGEMENT_CONTENT } from '../../BaseLineFieldInfo/components/modals/ResidueManagementModal/ResidueManagementModal.content';
import {
  checkEmptyDates,
  datesLabelList,
} from 'pages/ParticipantDataCollection/BaseLineReporting/BaseLineReportingMainPage.content';
import { deleteIconSx } from 'components/FormComponents/FormStyles';
import { disableIconStyle } from 'theme/GlobalStyles';

const BaselineAccordionDisplayBox = ({
  modalDataArray,
  mainLabel,
  itemId,
  handleClickDelete,
  handleClickEdit,
  deleteModalHeading,
  deleteModalContent,
  fieldValueStyle,
  secondaryLabelStyle,
  fieldLabelStyle,
  fieldUnitStyle,
  addBtnLabel,
  implementedEdgeOfFieldLength,
  eofLength,
  subText,
  typeOfReporting,
  deleteData,
  hasAddBtn = false,
  hasRowLevelEditDelete = false,
  addNewBtnHandler = () => {
    /* do nothing here */
  },
  setDeleteData = () => {
    /* do nothing here */
  },
  hasPracticesDropdown = false,
  allPracticesData = [],
  parentId = null,
  cropType = '',
  isInActivity = false,
  isButtonDisabled = false,
  disableUserActions = false,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPracticesDropdownOpen, setIsPracticesDropdownOpen] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

  const mandatoryPractices =
    allPracticesData?.data?.filter((item) => item.isMandatory) ?? [];
  const optionalPracticesData =
    allPracticesData?.data?.filter((item) => !item.isMandatory) ?? [];

  const handleAccordionToggle = (event) => {
    setExpanded(!expanded);
    event.stopPropagation();
  };

  const handleArrowIcon = (currentId) => {
    return (
      <div data-testid={currentId}>
        {checkTernaryCondition(
          expanded,
          <KeyboardArrowDownIcon />,
          <KeyboardArrowRightIcon />,
        )}
      </div>
    );
  };
  const deleteHandler = (id, isWholeModalDeleted, practiceTypeValue) => {
    setDeleteData({
      ...deleteData,
      id: id,
      isWholeModalDeleted: isWholeModalDeleted,
      practiceTypeValue: practiceTypeValue,
      modalType: modalDataArray?.modalType,
      parentId: parentId,
      cropType: cropType,
    });
    setIsDialogOpen(true);
  };
  const editHandler = (id) => {
    handleClickEdit(id, modalDataArray?.modalType, parentId);
  };

  const hasEmptyOptionalModal = useMemo(() => {
    return optionalPracticesData
      ?.map((practice) => {
        const modalTypeData = modalDataArray?.practicesModals.find(
          (item) => Object.keys(item)[0] === practice.modalList,
        )?.[practice.modalList];
        return isEmpty(modalTypeData?.finalData);
      })
      ?.includes(true);
  }, [modalDataArray, optionalPracticesData]);

  const shouldNotShowDeleteIcon = () => {
    return modalDataArray.finalData.some((arr) => {
      const obj = arr.find(
        (item) => item.label === 'isImplementedInActivities',
      );
      return checkTernaryCondition(
        isInActivity,
        obj && obj.value === true,
        true,
      );
    });
  };

  const deleteIconWithHover = (
    <DeleteIconWithHoverEffect
      data-testid={'delete-icon'}
      sx={{
        ...deleteIconSx,
        ...disableIconStyle(disableUserActions),
      }}
      onClick={() =>
        deleteHandler(
          itemId,
          modalDataArray.modalType !== 'Crop',
          checkTernaryCondition(
            modalDataArray.modalType === 'Crop',
            mainLabel?.split(' - ')[1],
            '',
          ),
        )
      }
    />
  );

  return (
    <>
      <MainWrapper>
        <Accordion expanded={expanded} sx={AccordionSx}>
          <AccordionSummary sx={AccordionSummarySx}>
            <RowWrapper>
              <ArrowLabelWrapper onClick={handleAccordionToggle}>
                {handleArrowIcon(itemId)}
                <TitleWrapper>
                  {!isEmpty(mainLabel) && (
                    <TextWrapper fontSize={'1rem'} fontWeight={600}>
                      {mainLabel.replace('- undefined', '')}
                    </TextWrapper>
                  )}
                  {subText && (
                    <TextWrapper
                      fontSize={'0.875rem'}
                      fontWeight={400}
                      color={QUILL_GRAY}>
                      {subText}
                    </TextWrapper>
                  )}
                </TitleWrapper>
              </ArrowLabelWrapper>
              <TextWrapper minWidth="300px" gap="0.5rem">
                <TextWrapper gap="0.5rem" className="HoverActionsContainer">
                  {hasRowLevelEditDelete &&
                    !isInActivity &&
                    deleteIconWithHover}
                  {hasRowLevelEditDelete &&
                    !shouldNotShowDeleteIcon() &&
                    isInActivity &&
                    deleteIconWithHover}
                </TextWrapper>
                {modalDataArray?.modalType !==
                  RESIDUE_MANAGEMENT_CONTENT.heading &&
                  hasAddBtn &&
                  (!hasPracticesDropdown || hasEmptyOptionalModal) && (
                    <Button
                      id="basic-button"
                      aria-controls={checkTernaryCondition(
                        isPracticesDropdownOpen,
                        'basic-menu',
                        undefined,
                      )}
                      aria-haspopup="true"
                      aria-expanded={checkTernaryCondition(
                        isPracticesDropdownOpen,
                        'true',
                        undefined,
                      )}
                      disabled={
                        (typeOfReporting === 'Activity' &&
                          addBtnLabel === '+ Edge of field' &&
                          eofLength === implementedEdgeOfFieldLength) ||
                        isButtonDisabled
                      }
                      variant={'contained'}
                      size={'medium'}
                      disableElevation
                      sx={addBtnSx}
                      onClick={(event) => {
                        if (!isEmpty(optionalPracticesData)) {
                          setIsPracticesDropdownOpen(true);
                          setDropdownAnchorEl(event.currentTarget);
                        } else {
                          addNewBtnHandler();
                        }
                      }}>
                      {addBtnLabel}
                      {hasPracticesDropdown && (
                        <KeyboardArrowDownIcon sx={KeyBoardArrowSx} />
                      )}
                    </Button>
                  )}

                <Menu
                  disableRestoreFocus
                  id="basic-menu"
                  anchorEl={dropdownAnchorEl}
                  open={isPracticesDropdownOpen}
                  onClose={() => {
                    setIsPracticesDropdownOpen(false);
                    setDropdownAnchorEl(null);
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}>
                  {optionalPracticesData?.map((practice) => {
                    const modalTypeData = modalDataArray?.practicesModals.find(
                      (item) => Object.keys(item)[0] === practice.modalList,
                    )?.[practice.modalList];
                    return (
                      isEmpty(modalTypeData?.finalData) && (
                        <MenuItem
                          key={practice?.id}
                          onClick={() => {
                            practice.clickHandler?.(itemId)();
                            setIsPracticesDropdownOpen(false);
                          }}
                          sx={{ fontSize: '0.875rem' }}>
                          {practice.label}
                        </MenuItem>
                      )
                    );
                  })}
                </Menu>
              </TextWrapper>
            </RowWrapper>
          </AccordionSummary>
          <AccordionDetails sx={AccordionDetailsSx}>
            <RowDataWrapper>
              {modalDataArray?.finalData?.map((rowData, index) => (
                <RowData key={uniqueId()}>
                  <SecondaryLabelWrapper>
                    <LabelAndButtonWrapper>
                      {!isEmpty(modalDataArray?.secondaryLabel) && (
                        <FieldLabel fieldStyle={secondaryLabelStyle}>
                          {modalDataArray?.secondaryLabel[index]}
                        </FieldLabel>
                      )}
                      {hasRowLevelEditDelete &&
                        modalDataArray.modalType !== 'Crop' && (
                          <TextWrapper width="10%" gap="0.5rem">
                            <EditIconWithHoverEffect
                              style={disableIconStyle(disableUserActions)}
                              data-testid={`edit-icon`}
                              onClick={() =>
                                editHandler(rowData[0]?.id || null)
                              }
                            />
                            {rowData.find(
                              (item) =>
                                item.label === 'isImplementedInActivities',
                            )?.value !== true && (
                              <DeleteIconWithHoverEffect
                                data-testid={`delete-icon`}
                                sx={deleteIconSx}
                                style={disableIconStyle(disableUserActions)}
                                onClick={() =>
                                  deleteHandler(
                                    rowData[0]?.id,
                                    false,
                                    modalDataArray?.secondaryLabel[index],
                                  )
                                }
                              />
                            )}
                          </TextWrapper>
                        )}
                    </LabelAndButtonWrapper>
                    {modalDataArray?.secondaryLabel[index] !== 'None' &&
                      modalDataArray?.secondaryLabel[index] !==
                        TILLAGE_CONTENT.noTillageOption && (
                        <DataWrapper>
                          {rowData.map((item) => {
                            const implementedActivitiesAdded =
                              item.label !== 'isImplementedInActivities';
                            /* istanbul ignore else */
                            if (implementedActivitiesAdded) {
                              return (
                                <Field key={item.label}>
                                  <FieldLabel
                                    fieldStyle={checkEmptyDates(
                                      mainLabel,
                                      item.value,
                                      item.label,
                                      datesLabelList,
                                      fieldLabelStyle,
                                      false, // isRenderValue
                                    )}>
                                    {item.label}
                                  </FieldLabel>
                                  <FieldValueWrapper>
                                    <FieldLabel
                                      fieldStyle={checkEmptyDates(
                                        mainLabel,
                                        item.value,
                                        item.label,
                                        datesLabelList,
                                        fieldValueStyle,
                                        false, // isRenderValue
                                      )}>
                                      {checkEmptyDates(
                                        mainLabel,
                                        item.value,
                                        item.label,
                                        datesLabelList,
                                        fieldValueStyle,
                                        true, // isRenderValue
                                      )}
                                    </FieldLabel>
                                    {item?.unit !== undefined && (
                                      <FieldLabel
                                        fieldStyle={fieldUnitStyle}
                                        style={{ marginTop: '0.0625rem' }}>
                                        {item?.unit}
                                      </FieldLabel>
                                    )}
                                  </FieldValueWrapper>
                                </Field>
                              );
                            }
                          })}
                        </DataWrapper>
                      )}
                  </SecondaryLabelWrapper>
                </RowData>
              ))}
            </RowDataWrapper>
            <PracticesDataWrapper>
              {!isEmpty(mandatoryPractices) && (
                <PracticesDataWrapper>
                  {mandatoryPractices?.map((practice) => {
                    const modalTypeData = modalDataArray?.practicesModals.find(
                      (item) => Object.keys(item)[0] === practice.modalList,
                    )?.[practice.modalList];

                    const isModalEmpty = modalTypeData?.finalData?.length === 0;

                    return (
                      isModalEmpty && (
                        <EmptyStateComponent
                          key={practice.id}
                          type={`${practice.label} (0)`}
                          onClick={practice.clickHandler(itemId)}
                        />
                      )
                    );
                  })}
                </PracticesDataWrapper>
              )}
            </PracticesDataWrapper>
          </AccordionDetails>
        </Accordion>
      </MainWrapper>
      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isDialogOpen}
        onConfirm={() => {
          handleClickDelete(parentId);
          setIsDialogOpen(false);
        }}
        subtitle={deleteModalContent}
        onCancel={() => {
          setIsDialogOpen(false);
          setDeleteData({
            id: null,
            isWholeModalDeleted: false,
            modalType: '',
            practiceTypeValue: '',
            deleteModalHeading: '',
            deleteModalContent: '',
            parentId: null,
            cropType: '',
          });
        }}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Delete"
        subtitleColor={DARK_CHARCOAL}
        isInsideBaseline
        title={deleteModalHeading}
      />
    </>
  );
};

BaselineAccordionDisplayBox.propTypes = {
  modalDataArray: PropTypes.array,
  mainLabel: PropTypes.string,
  itemId: PropTypes.string,
  handleClickDelete: PropTypes.func,
  handleClickEdit: PropTypes.func,
  deleteModalHeading: PropTypes.string,
  deleteModalContent: PropTypes.string,
  fieldValueStyle: PropTypes.object,
  secondaryLabelStyle: PropTypes.object,
  fieldLabelStyle: PropTypes.object,
  fieldUnitStyle: PropTypes.object,
  hasAddBtn: PropTypes.bool,
  addBtnLabel: PropTypes.string,
  hasRowLevelEditDelete: PropTypes.bool,
  addNewBtnHandler: PropTypes.func,
  deleteData: PropTypes.object,
  setDeleteData: PropTypes.func,
  subText: PropTypes.string,
  hasPracticesDropdown: PropTypes.bool,
  allPracticesData: PropTypes.array,
  parentId: PropTypes.string,
  cropType: PropTypes.string,
  isInActivity: PropTypes.bool,
  implementedEdgeOfFieldLength: PropTypes.number,
  eofLength: PropTypes.number,
  typeOfReporting: PropTypes.string,
  isButtonDisabled: PropTypes.bool,
  disableUserActions: PropTypes.bool,
};

export default BaselineAccordionDisplayBox;
