import styled from 'styled-components';
import landingPageImage from '../../assets/ParticipantEnrolmentLandingPage.png';
import { COOL_BLACK, TYPOGRAPHY_GRAY } from 'theme/GlobalColors';

export const ErrorContainerWrapper = styled.div`
  margin: auto;
  height: 100vh;
  background-image: linear-gradient(
      71.56deg,
      rgba(86, 137, 154, 0.7) 18.04%,
      rgba(241, 241, 241, 0) 85.83%
    ),
    url(${landingPageImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const errorCardSx = {
  minWidth: '35vw',
  margin: 'auto',
  borderRadius: '2rem',
  padding: '1.625rem 0',
  paddingLeft: '1.25rem',
  paddingRight: '1.5rem',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
};

const commonFontStyles = {
  fontFamily: 'Open Sans',
};

export const headerSx = {
  ...flexCenter,
  lineHeight: '3.15rem',
  letterSpacing: '-0.0125rem',
  color: TYPOGRAPHY_GRAY,
  fontSize: '2.25rem',
  fontWeight: 400,
  margin: 0,
};

export const subHeadingSx = {
  ...flexCenter,
  ...commonFontStyles,
  fontSize: '4rem',
  lineHeight: '5.68rem',
  letterSpacing: '-0.0125rem',
  margin: 0,
  fontWeight: 300,
  color: COOL_BLACK,
  textAlign: 'center',
};

export const paragraphSx = {
  ...flexCenter,
  ...commonFontStyles,
  fontSize: '1.25rem',
  lineHeight: '1.75rem',
  fontWeight: 400,
  color: COOL_BLACK,
  textAlign: 'center',
  margin: 0,
  marginTop: '1.25rem',
};

export const buttonSx = {
  ...flexCenter,
  ...commonFontStyles,
  margin: 0,
  marginTop: '1.25rem',
};

export const returnButtonSx = {
  ...flexCenter,
  ...commonFontStyles,
  position: 'absolute',
  top: '4.5rem',
  left: '1.96875rem',
  color: COOL_BLACK,
  margin: 0,
  padding: '0.375rem 0.5rem',
};

export const backIconSx = {
  height: '1rem',
  width: '1rem',
};
