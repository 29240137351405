import { Divider, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as HelpIcon } from '../../../assets/icons/help_outline.svg';
import EnrollmentHelpModal from './EnrollmentHelpModal/EnrollmentHelpModal.react';
import { useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  CopyRightMenuItemSx,
  MenuItemSx,
  MenuSx,
  OpenInNewIconSx,
  OpenInNewTab,
  PaperPropsStyle,
} from './EnrollmentHelpModal/EnrollmentHelpModal.style';
import { HELP_BUTTON_CONTENT } from './EnrollmentHelpModal/EnrollmentHelpModal.content';

import { GET_FAQ_PDF, GET_USER_GUIDE_PDF } from 'urls';
import axios from 'axios';
import { HeaderIconBtnSx } from '../style';
import { pathName } from 'Routes/routes.const';

const EnrollmentHelpButton = ({ section }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openContactForm = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };
  const openUserGuide = () => {
    let userGuideUrl = '';
    axios.get(GET_USER_GUIDE_PDF).then((response) => {
      userGuideUrl = response.data;
      window.open(userGuideUrl, '_blank');
    });
    setAnchorEl(null);
  };
  const openFaqPdf = () => {
    let faqPdfUrl = '';
    axios.get(GET_FAQ_PDF).then((response) => {
      faqPdfUrl = response.data;
      window.open(faqPdfUrl, '_blank');
    });
    setAnchorEl(null);
  };
  const openVideoTutorialPage = () => {
    window.open(`${window.location.origin}/video-tutorials`, '_blank');
    setAnchorEl(null);
  };
  const date = new Date();
  const openInNewTab = (pathName) => {
    window.open(pathName, '_blank');
  };
  return (
    <>
      <Tooltip title="Help">
        <IconButton
          sx={HeaderIconBtnSx}
          aria-label="help"
          onClick={handleClick}>
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="contact-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
          style: MenuSx,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: PaperPropsStyle,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <MenuItem sx={MenuItemSx} onClick={openUserGuide}>
          {HELP_BUTTON_CONTENT.USER_GUIDE}
          <OpenInNewTab>
            <OpenInNewIcon sx={OpenInNewIconSx} />
          </OpenInNewTab>
        </MenuItem>
        <MenuItem sx={MenuItemSx} onClick={openFaqPdf}>
          {HELP_BUTTON_CONTENT.FAQ}
          <OpenInNewTab>
            <OpenInNewIcon sx={OpenInNewIconSx} />
          </OpenInNewTab>
        </MenuItem>
        <MenuItem sx={MenuItemSx} onClick={openVideoTutorialPage}>
          {HELP_BUTTON_CONTENT.VIDEO_TUTORIALS}
          <OpenInNewTab>
            <OpenInNewIcon sx={OpenInNewIconSx} />
          </OpenInNewTab>
        </MenuItem>
        <MenuItem sx={MenuItemSx} onClick={openContactForm}>
          {HELP_BUTTON_CONTENT.CONTACT_SUPPORT}
        </MenuItem>
        <Divider />
        <MenuItem
          sx={MenuItemSx}
          onClick={() => openInNewTab(pathName.termsOfUse)}>
          {HELP_BUTTON_CONTENT.TERMS_OF_USE}
          <OpenInNewTab>
            <OpenInNewIcon sx={OpenInNewIconSx} />
          </OpenInNewTab>
        </MenuItem>
        <MenuItem
          sx={MenuItemSx}
          onClick={() => openInNewTab(HELP_BUTTON_CONTENT.PRIVACY_POLICY_LINK)}>
          {HELP_BUTTON_CONTENT.DELOITTE_PRIVACY_POLICY}
          <OpenInNewTab>
            <OpenInNewIcon sx={OpenInNewIconSx} />
          </OpenInNewTab>
        </MenuItem>
        <MenuItem
          sx={MenuItemSx}
          onClick={() => openInNewTab(HELP_BUTTON_CONTENT.COOKIES_LINK)}>
          {HELP_BUTTON_CONTENT.COOKIES}
          <OpenInNewTab>
            <OpenInNewIcon sx={OpenInNewIconSx} />
          </OpenInNewTab>
        </MenuItem>
        <Divider />
        <MenuItem sx={CopyRightMenuItemSx}>
          <span>
            &copy;{' '}
            {`${date.getFullYear()} ${HELP_BUTTON_CONTENT.COPYRIGHT_TEXT}`}
          </span>
        </MenuItem>
      </Menu>
      <EnrollmentHelpModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        section={section}
      />
    </>
  );
};

export default EnrollmentHelpButton;

EnrollmentHelpButton.propTypes = {
  section: PropTypes.string,
};
