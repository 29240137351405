import PropTypes from 'prop-types';
import { Button, Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DARK_GREEN, WHITE } from 'theme/GlobalColors';
import TabPanel from 'components/Tabber/TabPanel.react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useProjectCycleData } from 'hooks/useProjectCycleData';
import {
  AddSoilSamplingButtonSx,
  AddSoilSamplingButtonTextSx,
  BoxStyleSx,
  breadcrumbStyles,
  breadcrumbSx,
  projectNameStyle,
  SummaryHeaderWrapper,
  TabPanelStyle,
} from './Org_Projects.style';
import ListParticipants from './Participants/ListParticipants';
import OriginationSummaryTab from '../OriginationSummaryTab/OriginationSummaryTab.react';
import axios from 'axios';
import { FETCH_SOIL_SAMPLING_DATA, FETCH_VIEW_ORIGINATION_PROJECT } from 'urls';
import { SoilSamplingBtnText, TABS_TITLES } from './Org_Projects.content';
import SummaryViewHeader from 'components/PageHeaders/SummaryViewHeader/SummaryViewHeader.react';
import {
  checkAccess,
  checkTernaryCondition,
  hasAnyRequiredAccess,
  isEmpty,
} from 'utils/helper';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import {
  ORIGINATION_PARTICIPANT_CREATE,
  ORIGINATION_VIEWER,
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
} from 'utils/config';
import { addBtnSx, ButtonWrapper } from './Participants/Participants.style';
import {
  LABELS,
  PARTICIPANT_PARENT_PATH,
} from './Participants/Participants.content';
import AddButton from 'components/AddButton/AddButton.react';
import ProjectCalculationReport from '../ProjectCalculationReport/ProjectCalculationReport.react';
import { ProjectCalculationProvider } from 'contextAPI/projectCalculationContext';
import ActivitiesTab from './Participants/ParticipantTabs/ActivitesTab/ActivitiesTab.react';
import ProjectSettingsTab from './ProjectSettingsTab/ProjectSettingsTab.react';
import { sidenavContext } from 'contextAPI/SideNavContext';

const Org_Project = ({ testFlag }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [originationProjectDetails, setOriginationProjectDetails] = useState();
  const { state } = useLocation();
  const { id } = useParams();
  const { fetchProjectCycleList } = useProjectCycleData();
  const [soilSamplingDialog, setSoilSamplingDialog] = useState(false);
  const navigation = useNavigate();
  const toggleAddSoilSamplingDialog = () => {
    setSoilSamplingDialog((prevState) => {
      return !prevState;
    });
  };
  const [soilSamplingData, setSoilSamplingData] = useState([]);
  const { permissions, userRoles } = useContext(userAuthRolesContext);
  const [
    multipleParticipantsFileUploadOpen,
    setMultipleParticipantsFileUploadOpen,
  ] = useState(false);
  const [hasNoParticipants, setHasNoParticipants] = useState(false);
  const [participantDialogOpen, setParticipantDialogOpen] = useState({
    flag: false,
    id: 0,
  });
  const { tab } = useContext(sidenavContext);

  const addButtonOptions = [
    {
      id: 1,
      name: 'Add participant owner',
      modalHandler: () => addEditParticipantsHandler(0),
      hasDivider: false,
    },
    {
      id: 2,
      name: 'Add multiple participant owners',
      modalHandler: () => setMultipleParticipantsFileUploadOpen(true),
      hasDivider: false,
    },
  ];

  const addEditParticipantsHandler = (participantId) => {
    if (participantId === 0) {
      setParticipantDialogOpen({ flag: true, id: 0 });
    } else {
      setParticipantDialogOpen({ flag: true, id: participantId });
    }
  };
  useEffect(() => {
    fetchProjectDetails();
    fetchProjectCycleList(id);
  }, [id]);

  useEffect(() => {
    /* istanbul ignore else */
    if (state?.from === PARTICIPANT_PARENT_PATH) {
      setCurrentTab(1);
    }
  }, [state]);

  const fetchProjectDetails = () => {
    axios
      .get(`${FETCH_VIEW_ORIGINATION_PROJECT}/${id}`)
      .then((response) => {
        setOriginationProjectDetails(response.data);
      })
      .catch((_error) => {
        navigation('/error/404', { replace: true });
      });
  };
  useEffect(() => {
    axios.get(`${FETCH_SOIL_SAMPLING_DATA}/${id}`).then((response) => {
      setSoilSamplingData(() => {
        return response.data;
      });
    });
  }, [soilSamplingDialog]);
  const breadcrumbs = useMemo(
    () => [
      <div
        key="breadcrumb-2"
        style={projectNameStyle}
        title={originationProjectDetails?.projectName}>
        {' '}
        <Typography noWrap sx={breadcrumbStyles} color="text.primary">
          {originationProjectDetails?.projectName}
        </Typography>
      </div>,
    ],
    [originationProjectDetails],
  );

  const projectsTabs = [
    {
      title: 'Default',
      component: <></>,
      isDisabled: false,
    },
    {
      title: TABS_TITLES.summary,
      component: (
        <OriginationSummaryTab
          originationProjectDetails={originationProjectDetails}
          refreshHandler={fetchProjectDetails}
          projectId={id}
          testFlag={testFlag}
        />
      ),
      isDisabled: false,
    },
    {
      title: `${TABS_TITLES.participants}`,
      component: (
        <ListParticipants
          refreshHandler={fetchProjectDetails}
          disableVirtualization={false}
          projectName={originationProjectDetails?.projectName}
          participantDialogOpen={participantDialogOpen}
          setParticipantDialogOpen={setParticipantDialogOpen}
          setHasNoParticipants={setHasNoParticipants}
          multipleParticipantsFileUploadOpen={
            multipleParticipantsFileUploadOpen
          }
          setMultipleParticipantsFileUploadOpen={
            setMultipleParticipantsFileUploadOpen
          }
        />
      ),
      isDisabled: false,
    },
    {
      title: TABS_TITLES.calculations,
      component: (
        <ProjectCalculationProvider>
          <ProjectCalculationReport projectId={id} />
        </ProjectCalculationProvider>
      ),
      isDisabled: hasAnyRequiredAccess(userRoles, [
        PARTICIPANT,
        PARTICIPANT_CONTRIBUTOR,
      ]),
    },
    {
      title: TABS_TITLES.soilSampling,
      component: (
        <ActivitiesTab
          projectId={id}
          setSoilSamplingDialog={setSoilSamplingDialog}
          soilSamplingDialog={soilSamplingDialog}
          toggleAddSoilSamplingDialog={
            toggleAddSoilSamplingDialog
          }></ActivitiesTab>
      ),
      isDisabled: hasAnyRequiredAccess(userRoles, [
        PARTICIPANT,
        PARTICIPANT_CONTRIBUTOR,
      ]),
    },
    {
      title: TABS_TITLES.settings,
      component: <ProjectSettingsTab />,
    },
  ];
  useEffect(() => {
    /* istanbul ignore else */
    if (tab.module === 'Projects') {
      const tabIndex = projectsTabs.findIndex((tabs) => tabs.title === tab.tab);
      setCurrentTab(tabIndex);
    } else if (isEmpty(tab.module)) {
      setCurrentTab(1);
    }
  }, [tab]);

  return (
    <Container maxWidth={false}>
      <SummaryHeaderWrapper>
        <SummaryViewHeader
          gap={'0.5rem'}
          padding={0}
          noBackButton
          marginRight={0}
          title={
            <Breadcrumbs
              sx={breadcrumbSx}
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          }
          status={checkTernaryCondition(
            originationProjectDetails &&
              originationProjectDetails.isActive === 1,
            'Active',
            '',
          )}
        />
        {currentTab === 3 && soilSamplingData.length > 0 && (
          <Button
            onClick={() => toggleAddSoilSamplingDialog()}
            variant="contained"
            size="small"
            disabled={hasAnyRequiredAccess(userRoles, [
              PARTICIPANT,
              PARTICIPANT_CONTRIBUTOR,
              ORIGINATION_VIEWER,
            ])}
            sx={{
              ...AddSoilSamplingButtonSx,
            }}>
            <p
              style={{
                ...AddSoilSamplingButtonTextSx,
              }}>
              {SoilSamplingBtnText}
            </p>
          </Button>
        )}
        {!hasNoParticipants && currentTab === 1 && (
          <ButtonWrapper>
            <AddButton
              buttonName={LABELS.addNewParticipant}
              disableButton={
                permissions.length !== 0 &&
                !checkAccess(permissions, ORIGINATION_PARTICIPANT_CREATE)
              }
              backgroundColor={DARK_GREEN}
              textColor={WHITE}
              customBtnSx={addBtnSx}
              addBtnPadding={'0.406rem'}
              isModalPresent={true}
              hasMultipleModals={true}
              options={addButtonOptions}
            />
          </ButtonWrapper>
        )}
      </SummaryHeaderWrapper>

      <Box
        sx={{
          marginBottom: checkTernaryCondition(currentTab === 0, '0px', '1rem'),
        }}></Box>
      {projectsTabs.map((tab, index) => (
        <TabPanel
          key={tab.title}
          value={currentTab}
          index={index}
          style={checkTernaryCondition(index === 3, BoxStyleSx, TabPanelStyle)}>
          {tab.component}
        </TabPanel>
      ))}
    </Container>
  );
};

Org_Project.propTypes = {
  testFlag: PropTypes.bool,
};
export default Org_Project;
