import {
  InputInfoIcon,
  InputLableWrapper,
  LabelAndInfoStyle,
  LableWrapper,
  projectAutoCompleteSx,
  SwitchStyle,
  SwitchWrapper,
} from '../ProjectDesignSettingTab/ProjectDesignSettingTab.style';
import { ProjectScheduleWrapper } from '../ProjectSchedule/ProjectSchedule.style';
import { Switch } from '@mui/material';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { InputSx } from 'components/FormComponents/FormStyles';
import { STYLE_CONSTANTS } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import {
  outlinedDropdownInputSx,
  radioBtnPadding,
} from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import {
  checkboxPadding,
  dropdownProps,
  formLabelCustomSx,
} from 'pages/AddOriginationProject/AddOriginationProject.style';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/infoIcon.svg';
import { TextWrapper } from 'components/FormComponents/SwitchComponent/SwitchComponent.style';
import { PROJECT_GENERAL_SETTING_TAB_CONTENT } from './ProjectGeneralSettingTab.content';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import CheckboxFormGroup from 'components/FormComponents/CheckboxFormGroup/CheckboxFormGroup.react';
import { useParams } from 'react-router';
import { useProjectGeneralSettingTab } from './useProjectGeneralSettingTab';
import { checkTernaryCondition } from 'utils/helper';
import { LoaderProvider } from 'contextAPI/loaderContext';
import AutoCompleteForm from 'components/FormComponents/AutoComplete/AutoComplete.react';

const ProjectGeneralSetting = () => {
  const { id } = useParams();
  const {
    projectName,
    location,
    sectoralScope,
    afolusectoralSubtypes,
    commercialization,
    originationEngine,
    creditIssuance,
    projectActivities,
  } = useProjectGeneralSettingTab(id);

  return (
    <ProjectScheduleWrapper>
      <InputLableWrapper>
        <LableWrapper>{PROJECT_GENERAL_SETTING_TAB_CONTENT.name}</LableWrapper>
      </InputLableWrapper>
      <InputLableWrapper>
        <InputField
          value={projectName}
          width="100%"
          rightSideLabelFormLabelMargin={'0rem'}
          customInputSx={InputSx}
          isDisabled={true}
          labelMarginTop={STYLE_CONSTANTS.zero_rem}
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          customStyles={{ width: '100%' }}
        />
      </InputLableWrapper>

      <InputLableWrapper>
        <LabelAndInfoStyle>
          <LableWrapper>
            {PROJECT_GENERAL_SETTING_TAB_CONTENT.origination_engine}
          </LableWrapper>
          <InputInfoIcon>
            <InfoIcon />
          </InputInfoIcon>
        </LabelAndInfoStyle>
        <SwitchWrapper>
          <Switch
            data-testId={'Custom methodology'}
            sx={SwitchStyle}
            checked={originationEngine}
            onChange={() => {}}
            inputProps={{ 'aria-label': 'controlled' }}
            name={'Custom methodology'}
          />
          <TextWrapper fontSize={'12px'}>
            {checkTernaryCondition(originationEngine, 'On', 'Off')}
          </TextWrapper>
        </SwitchWrapper>
      </InputLableWrapper>

      <InputLableWrapper>
        <LabelAndInfoStyle>
          <LableWrapper>
            {PROJECT_GENERAL_SETTING_TAB_CONTENT.commercialization}
          </LableWrapper>
          <InputInfoIcon>
            <InfoIcon />
          </InputInfoIcon>
        </LabelAndInfoStyle>
        <div style={{ marginLeft: '2.8544rem' }}>
          <RadioButtonsGroup
            list={[
              {
                itemLabel: 'Insetting',
                selected: 'Insetting' === commercialization,
                isDisabled: true,
              },
              {
                itemLabel: 'Offsetting',
                selected: 'Offsetting' === commercialization,
                isDisabled: true,
              },
              {
                itemLabel: 'None',
                selected: 'None' === commercialization,
                isDisabled: true,
              },
            ]}
            selectedValue={commercialization}
            direction={'row'}
            marginTop={'0rem'}
            marginBottom={'0rem'}
            labelFontSize={STYLE_CONSTANTS.input_font_size}
            radioBtnPadding={radioBtnPadding}
            radioBtnGap={STYLE_CONSTANTS.one_rem}
            formLabelCustomSx={formLabelCustomSx}
            marginRight={STYLE_CONSTANTS.zero_rem}
          />
        </div>
      </InputLableWrapper>

      <InputLableWrapper>
        <LabelAndInfoStyle>
          <LableWrapper>
            {PROJECT_GENERAL_SETTING_TAB_CONTENT.credit_issuance}
          </LableWrapper>
          <InputInfoIcon>
            <InfoIcon />
          </InputInfoIcon>
        </LabelAndInfoStyle>
        <SwitchWrapper style={{ marginLeft: '2.8544rem' }}>
          <Switch
            data-testId={PROJECT_GENERAL_SETTING_TAB_CONTENT.credit_issuance}
            sx={SwitchStyle}
            checked={creditIssuance}
            onChange={() => {}}
            inputProps={{ 'aria-label': 'controlled' }}
            name={PROJECT_GENERAL_SETTING_TAB_CONTENT.credit_issuance}
          />
          <TextWrapper fontSize={'12px'}>
            {checkTernaryCondition(creditIssuance, 'On', 'Off')}
          </TextWrapper>
        </SwitchWrapper>
      </InputLableWrapper>

      <InputLableWrapper>
        <LabelAndInfoStyle>
          <LableWrapper>
            {PROJECT_GENERAL_SETTING_TAB_CONTENT.sector}
          </LableWrapper>
          <InputInfoIcon>
            <InfoIcon />
          </InputInfoIcon>
        </LabelAndInfoStyle>
      </InputLableWrapper>
      <InputLableWrapper>
        <RadioButtonsGroup
          list={[
            {
              itemLabel: 'Agriculture forestry and other land use (AFOLU)',
              selected: false,
              isDisabled: true,
            },
            {
              itemLabel: 'Carbon capture',
              selected: false,
              isDisabled: true,
            },
            {
              itemLabel: 'Energy',
              selected: false,
              isDisabled: true,
            },
          ]}
          selectedValue={sectoralScope}
          direction={'column'}
          marginTop={'0rem'}
          marginBottom={'0rem'}
          labelFontSize={STYLE_CONSTANTS.input_font_size}
          radioBtnPadding={radioBtnPadding}
          radioBtnGap={STYLE_CONSTANTS.one_rem}
          formLabelCustomSx={formLabelCustomSx}
          marginRight={STYLE_CONSTANTS.zero_rem}
        />
      </InputLableWrapper>

      <InputLableWrapper>
        <LabelAndInfoStyle>
          <LableWrapper>
            {PROJECT_GENERAL_SETTING_TAB_CONTENT.aflou_sector_sub_types}
          </LableWrapper>
          <InputInfoIcon>
            <InfoIcon />
          </InputInfoIcon>
        </LabelAndInfoStyle>
      </InputLableWrapper>
      <InputLableWrapper>
        <CheckboxFormGroup
          list={[
            {
              itemLabel: 'Improved forest management (IFM)',
              checked:
                'Improved forest management (IFM)' === afolusectoralSubtypes,
              isDisabled: true,
            },
            {
              itemLabel: 'Agriculture land management (ALM)',
              checked:
                'Agriculture land management (ALM)' === afolusectoralSubtypes,
              isDisabled: true,
            },
            {
              itemLabel:
                'Reducing emissions from deforestation and degradation (REDD+)',
              checked:
                'Reducing emissions from deforestation and degradation (REDD+)' ===
                afolusectoralSubtypes,
              isDisabled: true,
            },
            {
              itemLabel: 'Afforestation, reforestation, revegetation (ARR)',
              checked:
                'Afforestation, reforestation, revegetation (ARR)' ===
                afolusectoralSubtypes,
              isDisabled: true,
            },
          ]}
          titleAndInfoGap={'0rem'}
          checkboxPadding={checkboxPadding}
          gap={STYLE_CONSTANTS.one_rem}
          labelMarginTop={'0rem'}
          labelMarginBottom={'0rem'}
          stackedView={true}
          formLabelCustomSx={formLabelCustomSx}
          hasNoBottomMargin={true}
        />
      </InputLableWrapper>

      <InputLableWrapper>
        <LabelAndInfoStyle>
          <LableWrapper>
            {PROJECT_GENERAL_SETTING_TAB_CONTENT.project_activities}
          </LableWrapper>
          <InputInfoIcon>
            <InfoIcon />
          </InputInfoIcon>
        </LabelAndInfoStyle>
      </InputLableWrapper>
      <InputLableWrapper width="34.5rem">
        <AutoCompleteForm
          value={projectActivities}
          valueSetter={(value) => {}}
          minWidth={'34.5rem'}
          list={projectActivities}
          onClickHandler={() => {}}
          customSx={projectAutoCompleteSx(true)}
          marginTop={STYLE_CONSTANTS.zero_rem}
          labelGap={STYLE_CONSTANTS.zero_rem}
          marginBottom={STYLE_CONSTANTS.zero_rem}
          hasNewStyles={true}
          isDisabled
        />
      </InputLableWrapper>

      <InputLableWrapper>
        <LableWrapper>
          {PROJECT_GENERAL_SETTING_TAB_CONTENT.location}
        </LableWrapper>
      </InputLableWrapper>
      <InputLableWrapper>
        <DropDown
          dropdownlist={[location]}
          isDisabled
          value={location}
          width={'13.75rem'}
          height={'2rem'}
          menuProps={dropdownProps}
          hasNoBottomMargin={true}
          customSx={outlinedDropdownInputSx}
          labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
        />
      </InputLableWrapper>
    </ProjectScheduleWrapper>
  );
};

export const ProjectGeneralSettingTab = () => (
  <LoaderProvider>
    <ProjectGeneralSetting />
  </LoaderProvider>
);
