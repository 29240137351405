import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  NEW_DARK_GREEN,
  NEW_HOVER_DARK_GREEN,
  PROJECTED_DASHED_GRAY,
  QUILL_GRAY,
  WARNING_BOX_YELLOW,
  WHITE,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const MainWrapper = styled.div`
  border: 0.0625rem solid ${QUILL_GRAY};
  width: auto;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  border-bottom: 0.0625rem solid ${QUILL_GRAY};
`;
export const TabberBoxCustomStyle = {
  position: 'sticky',
  top: 0,
  marginTop: '0.263rem',
  backgroundColor: WHITE,
  border: 'none',
};
export const TextWrapper = styled.div`
  width: ${({ width }) => width};
  color: ${({ color }) => color};
  margin-left: ${({ marginLeft }) => marginLeft};
  align-self: center;
  line-height: ${({ lineHeight }) => lineHeight ?? '1.225rem'};
  font-size: ${({ fontSize }) => fontSize ?? '0.875rem'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  display: flex;
  display: -webkit-flex;
  align-items: center;
  gap: ${({ gap }) => gap ?? '0.25rem'};
  color: ${({ color }) => color ?? DARK_CHARCOAL};
  position: ${({ position }) => position ?? 'unset'};
  cursor: ${({ cursor }) => cursor};
  top: ${({ top }) => top ?? '0'};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'transparent'};
  z-index: ${({ zIndex }) => zIndex ?? 'auto'};
`;
export const ContentWrapper = styled.div`
  padding: 0.5rem 1rem;
`;
export const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  border-radius: 0.125rem 0rem 0rem 0rem;
  border: 0.0625rem solid ${WARNING_BOX_YELLOW};
  align-items: center;
`;
export const ContentBox = styled.div`
  display: flex;
  display: -webkit-flex;
  width: ${({ width }) => width};
  gap: 0.25rem;
  justify-content: ${({ content }) => content};
  cursor: ${({ cursor }) => cursor};
`;

export const StyledSpan = styled.span`
  display: flex;
  display: -webkit-flex;
  width: 0%;
  color: ${COOL_GRAY};
`;

export const MenuItemSx = {
  fontSize: '0.875rem',
  color: COOL_GRAY,
};

export const PoiTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2.875rem;
  align-content: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${WHITE};
`;

export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '33rem',
      padding: '1.5rem',
      margin: '0rem',
      gap: '1rem',
    },
  },
};

export const DialogPaddingStyle = {
  padding: '0rem',
};

export const FieldDialogTitleSx = {
  fontFamily: 'Open Sans',
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
  textAlign: 'left',
  color: DARK_CHARCOAL,
  maxWidth: '93%',
};

export const IconButtonSx = {
  position: 'absolute',
  right: 15,
  top: 15,
  color: COOL_GRAY,
};

export const DialogContentContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const FormControlAllFieldsSx = {
  minWidth: '70%',
  height: '2rem',
};

export const FormControlSelectStatusesSx = {
  minWidth: '25.5%',
  height: '2rem',
};

export const SelectSx = {
  color: COOL_GRAY,
  '& .MuiInputBase-input': {
    padding: '0.2rem 0',
  },
  '& .MuiSelect-select': {
    padding: '0.2813rem 0rem 0.2813rem 0.5rem',
    fontSize: '0.875rem',
    backgroundColor: 'transparent',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D0D0CE',
  },
  '& .MuiSelect-icon': {
    color: DARK_CHARCOAL,
    width: '1.5rem',
    height: '1.5rem',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: PROJECTED_DASHED_GRAY,
    },
  },
};

export const SelectLabelSx = {
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: 400,
  color: COOL_GRAY,
};

export const DataGridContainer = styled.div`
  height: 25rem;
  width: '100%';
  padding: 0 0.5rem 0 0;
`;

export const datagridSx = (numberOfFields) => ({
  border: '0.0625rem solid #D0D0CE',
  '& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit': {
    padding: '0rem',
    '.MuiSvgIcon-root': {
      width: '1.5em',
      height: '1.5em',
    },
  },
  '& .MuiTablePagination-root': {
    color: DARK_CHARCOAL,
  },
  '.MuiTablePagination-selectLabel': {
    display: 'block',
    left: checkTernaryCondition(numberOfFields < 10, '18%', '21.5%'),
    position: 'sticky',
    alignSelf: 'self-start',
  },
  '& .MuiInputBase-input': {
    backgroundColor: 'transparent',
  },
  '& .MuiTablePagination-input': {
    marginLeft: 0,
    marginRight: 0,
    position: 'sticky',
    left: checkTernaryCondition(numberOfFields < 10, '44%', '46%'),
  },
  '.MuiTablePagination-actions': {
    width: '11.25rem',
    justifyContent: 'space-between',
    display: 'flex',
    marginLeft: 0,
  },
  '& .MuiTablePagination-displayedRows': {
    position: 'sticky',
    left: checkTernaryCondition(numberOfFields < 10, '72%', '70%'),
    fontSize: '0.875rem',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.225rem',
    textAlign: 'left',
    color: DARK_CHARCOAL,
  },
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.225rem',
    textAlign: 'left',
    color: DARK_CHARCOAL,
  },
  '& .MuiDataGrid-virtualScroller': {
    overflowX: 'hidden',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
    outline: 'none',
    backgroundColor: 'transparent',
  },
});

export const proofBtnStyle = {
  height: '2.25rem',
  textTransform: 'none',
  backgroundColor: 'transparent',
  color: NEW_DARK_GREEN,
  fontWeight: 400,
  border: `1px solid ${NEW_DARK_GREEN}`,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: NEW_HOVER_DARK_GREEN,
  },
  '&:focus': {
    backgroundColor: 'transparent',
    color: NEW_HOVER_DARK_GREEN,
  },
};

export const searchIconStyle = {
  color: DARK_CHARCOAL,
  padding: '0.2rem 0',
  fontSize: '1rem',
};
