import { useContext, useEffect } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { SettingsContext } from 'contextAPI/settings';
import { isEmpty } from 'utils/helper';
import { useNavigate } from 'react-router';

/**
 * Interceptor component for attaching the axios interceptor
 */
export const RequestInterceptor = ({ children }) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const settings = useContext(SettingsContext);
  const navigation = useNavigate();

  useEffect(() => {
    const csrfHeader = settings?.csrf?.headerName ?? 'X-CSRF-TOKEN';
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common[csrfHeader] = settings?.csrf?.token ?? '';
  }, []);

  useEffect(() => {
    attachAxiosInterceptors();
  }, [currentUser]);

  function attachAxiosInterceptors() {
    axios.interceptors.request.use(
      async (config) => {
        if (!isEmpty(currentUser) && settings.isDevEnvironment) {
          await attachFirebaseTokens(config);
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );

    // Response interceptor for API calls
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        if (error?.response?.status === 403 && !originalRequest._retry) {
          originalRequest._retry = true;
          await currentUser?.getIdToken().then((token) => {
            axios.defaults.headers.common['Authorization'] = token;
          });

          return axios(originalRequest);
        }
        const status = error?.response?.status;
        const errorRoutes = {
          401: '/error/401',
          403: '/error/403',
          404: '/error/404',
          502: '/error/502',
          503: '/error/503',
          504: '/error/504',
        };

        /* istanbul ignore else */
        if (status in errorRoutes) {
          navigation(errorRoutes[status], { replace: true });
        }
        return Promise.reject(error);
      },
    );
  }

  /**
   * This function takes care of attaching the access token for every request in the
   * authorization header. This also internally takes care of refreshing the tokens
   * when expired using refresh_token.
   * This would only redirect to login screen when the refresh_token is slao expired
   * @param { axios config} config
   */
  async function attachFirebaseTokens(config) {
    await currentUser.getIdToken().then((token) => {
      config.headers['Authorization'] = `${token}`;
    });
  }
  return children;
};
