import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import { NotificationLogProvider } from 'containers/NotificationLogContext.react';
import { useStyles } from 'theme/GlobalStyles';
import Header from './header/Header.react';
import Sidenav from './sidenav/Sidenav.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { VIDEO_TUTORIAL_ROUTE } from './Layout.constants';
import { checkTernaryCondition } from 'utils/helper';
import { PaddingForVideoTutorialPage } from './Layout.style';

const MainLayout = () => {
  const { MainContainer, MainContent, OutletContainer } = useStyles();
  const { isAccessDenied /* ,loading */ } = useContext(userAuthRolesContext);
  const [isVideoTutorialRoute, setIsVideoTutorialRoute] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setIsVideoTutorialRoute(location.pathname.includes(VIDEO_TUTORIAL_ROUTE));
  }, [location.pathname]);
  return (
    <>
      <NotificationLogProvider>
        <Header />
      </NotificationLogProvider>
      <MainContainer
        disableGutters
        maxWidth="xl"
        isMainAccessDeniedPage={isAccessDenied}
        isAccessDenied={isAccessDenied}>
        {!isAccessDenied && !isVideoTutorialRoute && <Sidenav />}
        <MainContent
          marginLeft={checkTernaryCondition(
            isAccessDenied,
            '0rem',
            checkTernaryCondition(isVideoTutorialRoute, '5.375rem', '13.75rem'),
          )}
          padding={isVideoTutorialRoute && PaddingForVideoTutorialPage}
          fullWidth={isAccessDenied}>
          <OutletContainer>
            <Outlet />
          </OutletContainer>
        </MainContent>
      </MainContainer>
    </>
  );
};

export default MainLayout;
