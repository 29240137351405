import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import { ReactComponent as NotStartedIcon } from '../../../../assets/icons/smallNotStartedIcon.svg';
import { ReactComponent as InProgressIcon } from '../../../../assets/icons/smallInProgressIcon.svg';
import { ReactComponent as CompletedIcon } from '../../../../assets/icons/smallSuccessIcon.svg';
import { ReactComponent as ReviewIcon } from '../../../../assets/icons/inReviewIcon.svg';
import {
  TextWrapper,
  acreLabelSx,
  activityStatusSx,
} from './Participants.style';
import { DARK_CHARCOAL } from 'theme/GlobalColors';
import { USER_STATUS } from 'utils/config';

export const LIST_CONSTANTS = {
  expires: 'Expires: ',
};

export const PARTICIPANT_TYPES = {
  owner: 'PARTICIPANT_OWNER',
  contributor: 'PARTICIPANT_CONTRIBUTOR',
};

export const listingParticipantsColumn = {
  participantId: {
    label: 'participantId',
    field: 'participantId',
  },
  participant: {
    label: 'Name',
    field: 'participantfullname',
  },
  type: {
    label: 'Type',
    field: 'type',
  },
  status: {
    label: 'Activity status',
    field: 'status',
  },
  viewDetails: {
    label: 'View details',
    field: 'viewDetails',
  },
  contact: {
    label: 'Contact',
    field: 'participantEmail',
  },
  addedOn: {
    label: 'Added On',
    field: 'createdDate',
  },
  lastUpdated: {
    label: 'Last updated',
    field: 'lastUpdatedDate',
  },
  contracts: {
    label: 'Contract',
    field: 'contracts',
  },
  userStatus: {
    label: 'User status',
    field: 'userStatus',
  },
  actions: {
    label: 'Action',
    field: 'options',
  },
};
export const SortColValues = {
  participantFirstName: listingParticipantsColumn.participant.field,
  participantEmail: listingParticipantsColumn.contact.field,
  addedOn: listingParticipantsColumn.addedOn.field,
};

export const LABELS = {
  delete_message: 'Are you sure you want to remove from ?',
  addNewParticipant: 'Add participant owner',
  enrolledStatus: 'Enrolled',
  noDataContent: 'No participants added',
  addNewProjectLabel:
    'Add at least one individual in this project cycle to get started.',
  projectParticipants: 'Project participants',
  enterBaselineLabel: 'Initiate baseline reporting',
  stakeholder: 'Stakeholders',
  projects: 'Projects',
  type: 'Participant Owner',
  requestEmailSuccessMsg: 'Email sent successfully',
  requestEmailErrorMsg:
    'Unable to send email due to technical error. Please try again.',
  addContact: 'Add contract',
  reviewEnrollmentLabel: 'Review enrollment',
  reviewBaselineLabel: 'Review baseline reporting',
  reviewActivityLabel: 'Review activity reporting',
  searchBoxPlaceholder: 'Search by name or email',
  filter_content_width: '75%',
  filter_content: 'We couldn’t find any results for the participant',
  search_text_suffix: 'No results found',
  baseline_pdf_title: 'Baseline reporting review',
  enrollment_pdf_title: 'Enrollment review',
  pdf_project_title: 'Project',
  pdf_participant_owner_name: 'Owner',
  enrollment_review_tab: 'Enrollment responses',
  baseline_review_tab: 'Operations data',
  activityReviewTab: 'Proof of implementation',
  reopen_and_approve_enrollment: 'Reopen on behalf and approve enrollment',
  reopen_and_approve_activity:
    'Reopen on behalf and approve activity reporting',
  reopen_activity_approve_baseline: 'Reopen on behalf and approve baseline',
  contributor: 'Participant Contributor',
  participant_contributor: 'PARTICIPANT_CONTRIBUTOR',
  activityReportingReviewTitle: 'Review activity reporting',
  account_status: 'User status',
  activity_status: 'Activity status',
  enrollment_approved: 'Enrollment - Approved',
  baseline_not_started: 'Baseline Reporting - Not Started',
  activity_not_started: 'Activity Reporting - Not Started',
  baseline_approved: 'Baseline Reporting - Approved',
  Owner: 'Owner',
  Contributor: 'Contributor',
  not_applicable_label: 'Not applicable',
  APPROVAL_TEXT: 'Send message describing what’s needed for approval.',
  CONTACT_SUPPORT: 'Please contact for any questions you may have.',
  THANK_YOU_TEXT: 'Thank you.',
  DESCRIPTION_CONTENT: 'replace with description of needs for approval',
  REOPEN_ON_BEHALF: 'Reopen on behalf',
  SEND_REQUEST_WITH_MESSAGE: 'Send request with message',
  TYPE: 'Type',
  ROLE: 'role',
  STATUS: 'status',
  USER_STATUS_HEADING:
    'The user status column denotes the current authorization status of a participant to access ClearCarbon.',
  USER_STATUS_DESCRIPTION_1:
    'If a participant’s user status is “Not started”, “Pending activation”, or “Inactive” , the participant can not access ClearCarbon.',
  USER_STATUS_DESCRIPTION_2:
    'If a participant’s user status is “Active”, the participant can access ClearCarbon and submit their data.',
  USER_STATUS_DESCRIPTION_3:
    'Please refer to the user guide to review when a participant’s user status will be updated.',
  ACTIVITY_MENU_ITEMS: ['Recruitment', 'Enrollment', 'Activity', 'Baseline'],
  ACTIVITY_REPORTING: 'Activity reporting',
  BASELINE_REPORTING: 'Baseline reporting',
  ENROLLMENT: 'Enrollment',
  ACTIVITY_REPORTING_REOPEN_ACTION_CODE: 'ACTIVITYREOPENSENDREQPRTCPNT',
  BASELINE_REPORTING_REOPEN_ACTION_CODE: 'BASELINEREOPENSENDREQPRTCPNT',
  ENROLLMENT_REOPEN_ACTION_CODE: 'ENROLLREOPENSENDREQPRTCPNT',
  REQUEST_REOPEN_LINK_TARGET: '_blank',
  REQUEST_REOPEN_LINK_REL: 'noopener noreferrer',
  APPROVED: 'Approved',
};

export const org_ProjectDetails = {
  projectName: 'project p',
  lastUpdatedOn: '2023-05-03T13:26:38.893+00:00',
  projectTags: [
    {
      projectTagId: 1,
      internalProjectId: 0,
      tagName: 'Tag1',
      createdDate: null,
      updatedDate: null,
    },
    {
      projectTagId: 2,
      internalProjectId: 0,
      tagName: 'Tag2',
      createdDate: null,
      updatedDate: null,
    },
  ],
  participantList: {
    content: [
      {
        participantId: '39',
        farmId: 0,
        participantFirstName: 'Ria',
        participantLastName: 'Cho',
        participantEmail: 'test2@com',
        clientId: 100,
        createdDate: '2023-05-04T10:46:38.339+00:00',
        updatedDate: null,
        participantfullname: 'Ria Cho',
      },

      {
        participantId: '38',
        farmId: 0,
        participantFirstName: 'Johnss',
        participantLastName: 'Doe',
        participantEmail: 'test1@com',
        clientId: 100,
        createdDate: '2023-05-04T07:27:12.683+00:00',
        updatedDate: null,
        participantfullname: 'Johnss Doe',
      },
    ],
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      pageSize: 5,
      pageNumber: 0,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    totalPages: 1,
    totalElements: 2000,
    last: true,
    numberOfElements: 2,
    size: 5,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    number: 0,
    first: true,
    empty: false,
  },
};
export const PARTICIPANT_TABS = {
  summary: 'Summary',
  profile: 'Profile',
  activity: 'Activity',
};
export const PARTICIPANT_PATH = '/participant/';
export const PARTICIPANT_PARENT_PATH = '/stakeholders';
export const ALL_PROJECTS_PATH = '/projects';
export const latLongArray = [
  { lat: 33.7485, lng: -84.389302 },
  { lat: 33.748569, lng: -84.391621 },
  { lat: 33.750273, lng: -84.391803 },
  { lat: 33.750788, lng: -84.389882 },
];

export const baselineModalPropsInit = {
  open: false,
  navigateLink: null,
  navigateState: null,
  participantName: null,
};

export const displayToast = (type, message) => {
  return toast(<CustomSnackbar type={type} message={message} />, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const initialReviewEnrollmentProp = {
  title: 'Review enrollment',
  contentText:
    'Please access the enrollment responses to review and make your approval decision once complete.',
  firstSubmitBtnLabel: 'Approve enrollment',
  secondSubmitBtnLabel: 'Reopen enrollment',
  cancelBtnLabel: 'Cancel',
  firstSubmitBtnHandler: () => {},
  secondSubmitBtnHandler: () => {},
  cancelHandler: () => {},
  modalCloseHandler: () => {},
  modalData: { flag: false },
};

export const getActionIdFromActionCode = (actionCode, statusMappingList) => {
  return statusMappingList.find((data) => data.actionCode === actionCode)
    ?.actionId;
};

export const getActionCodeFromActionId = (actionId, statusMappingList) => {
  return statusMappingList.find((data) => data.actionId === actionId)
    ?.actionCode;
};

export const INITIATE_ACTIVITY_REPORTING_INITIALSTATE = {
  flag: false,
  id: 0,
  enrollmentInstanceId: null,
  projectId: 0,
  participantId: 0,
  participantName: '',
  navigateLink: null,
  navigateState: null,
  actionId: 0,
};

export const enrollmentApproveAndReopenBaselineContent = {
  title: 'Reopen baseline reporting and approve enrollment',
  emailPlaceholder: (projectName, projectCycleYear) =>
    `Your baseline reporting for the ${projectName} ${projectCycleYear} has been reopened. This was done in order to amend the baseline reporting with the latest approved enrollment instance. You may access your baseline reporting with the link below and submit once you’ve completed adding the additional required information amended from the new enrollment instance.`,
  additionalContextText:
    'You currently have baseline reporting in review. In order to approve this enrollment instance you must reopen baseline reporting. This will amend the baseline reporting with the new enrollment instance.',
};

export const baselineApproveAndReopenActivityContent = {
  title: 'Reopen activity reporting and approve baseline reporting',
  emailPlaceholder: (projectName, projectCycleYear) =>
    `Your activity reporting for the ${projectName} ${projectCycleYear} has been reopened. This was done in order to amend the activity reporting with the latest approved baseline instance. You may access your activity reporting with the link below and submit once you’ve completed adding the additional required information amended from the new baseline instance.`,
  additionalContextText:
    'You currently have activity reporting in review. In order to approve this baseline instance you must reopen activity reporting. This will amend the activity reporting with the new baseline instance.',
};

export const getActivityId = (id, activityData) => {
  return activityData?.[0]?.activityList.find((item) => item.formId === id)
    ?.enrollmentId;
};

export const getActivityType = (id, activityData) => {
  return activityData?.[0]?.activityList.find((item) => item.formId === id)
    ?.activityType;
};

export const getActivityDate = (id, activityData) => {
  return activityData?.[0]?.contractStartDate;
};

export const getReviewTabName = (title) => {
  if (title === LABELS.reviewEnrollmentLabel)
    return LABELS.enrollment_review_tab;
  else if (title === LABELS.reviewBaselineLabel)
    return LABELS.baseline_review_tab;
  else if (title === LABELS.reviewActivityLabel)
    return LABELS.baseline_review_tab;
};

export const contributorTableColumns = {
  participantId: {
    label: 'participantId',
    field: 'participantId',
  },
  participantName: {
    label: 'Name',
    field: 'participantfullname',
  },
  accountStatus: {
    label: 'Account status',
    field: 'accountStatus',
  },
  options: {
    label: 'Action',
    field: 'options',
  },
};

export const statusOrder = [
  'Recruitment',
  'Enrollment',
  'Baseline',
  'Activity',
];

export const getStatusIcon = (status) => {
  switch (status) {
    case 'Interested':
      return <NotStartedIcon />;
    case 'Not started':
      return <NotStartedIcon />;
    case 'Requested':
      return <InProgressIcon />;
    case 'In progress':
      return <InProgressIcon />;
    case 'Submitted':
      return <InProgressIcon />;
    case 'Contract added':
      return <CompletedIcon />;
    case 'Approved':
      return <CompletedIcon />;
    case 'In review':
      return <ReviewIcon />;
    default:
      return '';
  }
};

export const getParticipantStatus = (statusMappingList, instanceData) => {
  const recruitmentStatusValue = statusMappingList.find(
    (data) => data.actionId === instanceData.recruitmentActionId,
  );
  const enrollmentStatusValue = statusMappingList.find(
    (data) => data.actionId === instanceData.enrollmentActionId,
  );
  const baselineStatusValue = statusMappingList.find(
    (data) => data.actionId === instanceData.baselineActionId,
  );
  const activityStatusValue = statusMappingList.find(
    (data) => data.actionId === instanceData.activityActionId,
  );

  return (
    <div className={activityStatusSx}>
      {recruitmentStatusValue && instanceData.recruitmentActionId === 1 && (
        <TextWrapper fontSize="0.9rem" fontWeight={400} color={DARK_CHARCOAL}>
          {getStatusIcon('Interested')}
          {`Recruitment-Interested`}
        </TextWrapper>
      )}
      {enrollmentStatusValue && instanceData.enrollmentActionId != 12 && (
        <TextWrapper fontSize="0.9rem" fontWeight={400} color={DARK_CHARCOAL}>
          {getStatusIcon(enrollmentStatusValue?.actionStatus)}
          {`${enrollmentStatusValue?.phase}-${enrollmentStatusValue?.actionStatus}`}
          <span style={acreLabelSx}>
            {instanceData.enrollmentActionId <= 3
              ? ''
              : `  ${
                  instanceData.enrollmentArea === null
                    ? 0
                    : instanceData.enrollmentArea
                } acres`}
          </span>
        </TextWrapper>
      )}
      {instanceData.enrollmentActionId === 12 &&
        instanceData.baselineActionId <= 12 &&
        instanceData.baselineActionId !== null &&
        instanceData.baselineArea !== null && (
          <TextWrapper fontSize="0.9rem" fontWeight={400} color={DARK_CHARCOAL}>
            {getStatusIcon('Interested')}
            {`Baseline Reporting-Not Started`}
            <span style={acreLabelSx}>{` ${
              instanceData.baselineArea === null ? 0 : instanceData.baselineArea
            } acres`}</span>
          </TextWrapper>
        )}
      {baselineStatusValue && instanceData.baselineActionId != 22 && (
        <TextWrapper fontSize="0.9rem" fontWeight={400} color={DARK_CHARCOAL}>
          {getStatusIcon(baselineStatusValue?.actionStatus)}
          {`${baselineStatusValue?.phase}-${baselineStatusValue?.actionStatus}`}
          <span style={acreLabelSx}>{` ${
            instanceData.baselineArea === null ? 0 : instanceData.baselineArea
          } acres`}</span>
        </TextWrapper>
      )}
      {instanceData.baselineActionId === 22 &&
        instanceData.activityActionId <= 22 &&
        instanceData.activityActionId !== null &&
        instanceData.activityArea !== null && (
          <TextWrapper fontSize="0.9rem" fontWeight={400} color={DARK_CHARCOAL}>
            {getStatusIcon('Interested')}
            {`Activity Reporting-Not Started`}
            <span style={acreLabelSx}>{` ${
              instanceData.activityArea === null ? 0 : instanceData.activityArea
            } acres`}</span>
          </TextWrapper>
        )}
      {activityStatusValue && instanceData.activityActionId != 32 && (
        <TextWrapper fontSize="0.9rem" fontWeight={400} color={DARK_CHARCOAL}>
          {getStatusIcon(activityStatusValue?.actionStatus)}
          {`${activityStatusValue?.phase}-${activityStatusValue?.actionStatus}`}
          <span style={acreLabelSx}>{` ${
            instanceData.activityArea === null ? 0 : instanceData.activityArea
          } acres`}</span>
        </TextWrapper>
      )}
      {activityStatusValue && instanceData.activityActionId === 32 && (
        <TextWrapper fontSize="0.9rem" fontWeight={400} color={DARK_CHARCOAL}>
          {getStatusIcon(activityStatusValue?.actionStatus)}
          {`${activityStatusValue?.phase}-${activityStatusValue?.actionStatus}`}
          <span style={acreLabelSx}>{` ${
            instanceData.activityArea === null ? 0 : instanceData.activityArea
          } acres`}</span>
        </TextWrapper>
      )}
    </div>
  );
};

/**
 * @description returns display value of the user status
 * @param {String} [status=''] - User status
 * @returns {String}
 */
export const getParticipantUserStatus = (status = '') => {
  switch (status) {
    case USER_STATUS.STATUS.NA:
      return USER_STATUS.DISPLAY_VALUES.NA;
    case USER_STATUS.STATUS.PENDING_ACTIVATION:
      return USER_STATUS.DISPLAY_VALUES.PENDING_ACTIVATION;
    case USER_STATUS.STATUS.ACTIVE:
      return USER_STATUS.DISPLAY_VALUES.ACTIVE;
    case USER_STATUS.STATUS.INACTIVE:
      return USER_STATUS.DISPLAY_VALUES.INACTIVE;
    default:
      return '';
  }
};
/**
 * Returns the label corresponding to the participant type.
 *
 * @param {string} type - The participant type.
 * @returns {string} The label corresponding to the participant type.
 */
export const getParticipantType = (type) => {
  switch (type) {
    case PARTICIPANT_TYPES.owner:
      return LABELS.Owner;
    case PARTICIPANT_TYPES.contributor:
      return LABELS.Contributor;
    default:
      return '';
  }
};

/**
 * @description returns status code of the user status
 * @param {String} [statusString='']
 * @returns {String}
 */
export const getParticipantUserStatusCode = (statusString = '') => {
  switch (statusString) {
    case USER_STATUS.DISPLAY_VALUES.NA:
      return USER_STATUS.STATUS.NA;
    case USER_STATUS.DISPLAY_VALUES.PENDING_ACTIVATION:
      return USER_STATUS.STATUS.PENDING_ACTIVATION;
    case USER_STATUS.DISPLAY_VALUES.ACTIVE:
      return USER_STATUS.STATUS.ACTIVE;
    case USER_STATUS.DISPLAY_VALUES.INACTIVE:
      return USER_STATUS.STATUS.INACTIVE;
    default:
      return '';
  }
};
export const getUserTypeCode = (type) => {
  switch (type) {
    case LABELS.Owner:
      return PARTICIPANT_TYPES.owner;
    case LABELS.Contributor:
      return PARTICIPANT_TYPES.contributor;
    default:
      return '';
  }
};

export const viewDetailsLink = (participantId, projectId) => {
  return [
    {
      name: 'Profile',
      url: `${PARTICIPANT_PARENT_PATH}/${participantId}?projectid=${projectId}&tab=Profile`,
      state: { projectId: projectId },
    },
    {
      name: 'Summary',
      url: `${PARTICIPANT_PARENT_PATH}/${participantId}?projectid=${projectId}&tab=Summary`,
      state: { projectId: projectId },
    },
    {
      name: 'Activity',
      url: `${PARTICIPANT_PARENT_PATH}/${participantId}?projectid=${projectId}&tab=Activity`,
      state: { projectId: projectId },
    },
  ];
};

export const getDynamicRowHeight = (row) => {
  // Base height of the row
  const baseHeight = 72;
  // Additional height per enrollmentInstanceStatus beyond 3
  const additionalHeightPerInstance = 24;
  if (row.enrollmentInstanceStatus && row.enrollmentInstanceStatus.length > 3) {
    const extraInstances = row.enrollmentInstanceStatus.length - 3;
    const additionalHeight = extraInstances * additionalHeightPerInstance;
    // Return the total height considering the additional height for extra instances
    return baseHeight + additionalHeight;
  } else {
    // If there are 3 or less instances, return the base height
    return baseHeight;
  }
};
