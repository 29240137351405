import { checkTernaryCondition } from 'utils/helper';
import { uniqueId } from 'utils/uniqueIdGenerator';

export const FIELD_INFO_CONSTANT = {
  RESIDUE_TILLAGE_MANAGEMENT: 'Residue + tillage management',
  EDGE_OF_FIELD_PRACTICES: 'Edge of field practices',
  landUsePlaceHolder: 'Select land use',
  emptyPlaceHolder: '',
  PARTICIPANT_CONTRIBUTOR: 'PARTICIPANT_CONTRIBUTOR',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  yearPlaceholder: 'Year',
  commonLandUseType3Years:
    'What was the most common land use type for this field in the past 3 years?',
  placeholder: 'Select option',
  commonLandUseType3YearsError: 'A common land use type selection is required.',
  isConsistentFor20Years:
    'Has this been the consistent land use type throughout the past 20 years?',
  isConsistentFor3YearsError:
    'A consistent land use type throughout the past 20 years selection is required.',
  historicLandUseType:
    'Please identify the historic land use types for this field for the past 20 years along with approximate start and end years.',
  need2HistoricLandUseTypeError:
    'If No was selected for consistent land use type throughout the past 20 years then at least 2 land use type selections are required.',
  needStartAndEndDateForHistoricLandUseTypeError:
    'If a land use type is added then an approximate start year and end year are required.',
  dateIntersectError:
    'Land use types can’t have overlapping start year and end year dates.',
  endYearGreaterError: 'Start year can’t happen after end year.',
  deleteSuccessSuffix: 'was successfully deleted.',
  deleteErrorSuffix:
    "couldn't be deleted due to a technical error. Please try again.",
  fieldName: 'Field name',
  fsaTractId: 'FSA Tract ID',
  fsaFieldId: 'FSA Field ID',
  totalFieldArea: 'Total field area',
  fieldNamePlaceholder: 'Type the name of the field',
  fsaTractIdPlaceholder: 'Type tract ID',
  fsaFieldIdPlaceholder: 'Type field ID',
  totalFieldAreaPlaceholder: 'Type acreage',
  ACRES: 'acres',
  page_title: 'Field Information',
  save: 'Save',
  deleteModalCancelText: 'Cancel',
  deleteModalAcceptText: 'Delete',
  field_name_error: 'Field name is required.',
  fieldNameRepeatedError:
    'The field name entered already exists. Please enter a new field name.',
  fsa_tract_id_error: 'A FSA tract ID is required.',
  fsa_field_id_is_required: 'FSA field ID is required.',
  activity_error: 'A project activity is required.',
  total_area_error: 'Total field area is required.',
  project_activity_title: 'Planned project activities',
  project_activity_info: 'Project activity implementation for project cycle:',
  activity_button_label: '+ Activity type',
  baseline_title: 'Establish Field Baseline Scenario',
  baseline_info1: 'Baseline period being established',
  baseline_info2: 'Must be minimum 3 years prior to enrollment.',
  success: 'success',
  completed: 'Completed',
  in_progress: 'In Progress',
  submit_complete_modal_title: 'All enrollment forms are complete',
  submit_complete_modal_content:
    'You’re ready to review and submit or you may continue to add more fields to enroll.',
  cancel_btn_label: 'Cancel',
  continue_btn_label: 'Continue to add fields',
  review_btn_label: 'Review and submit',
  add_crop_type_btn: '+ Cash crop type',
  crop_type_label: 'Cash crop type',
  crop_type_placeholder: 'Select crop type',
  crop_type_required_error: 'A crop type selection is required.',
  single_total_field_area_error:
    "Total field area can't exceed total farm area. Please adjust as necessary.",
  multiple_total_field_area_error:
    "Total sum of field areas can't exceed total farm area. Please adjust field areas as necessary.",
  csaf_field_label:
    'Have Climate Smart Agriculture and Forestry (CSAF) practices been implemented on this field?',
  csaf_field_primary_error_message:
    'The implementation status of CSAF practices selection is required.',
  last_20_years_required_error:
    'It is required to provide the land use type for the past 20 years.',
  csaf_yes_tooltip: (
    <div>
      CSAF practices should be considered if they’ve been implemented in the
      past 10 years or since the current primary operator took control
      (whichever time period is shorter).
      <br></br>
      <br></br>
      Learn more about CSAF practices{' '}
      <a
        href="https://www.nrcs.usda.gov/sites/default/files/2023-10/NRCS-CSAF-Mitigation-Activities-List.pdf"
        target="_blank"
        rel="noreferrer">
        here
      </a>
      .
    </div>
  ),
  csaf_no_idontknow_tooltip: (
    <div>
      CSAF practices should be considered if they’ve been implemented in the
      past 10 years or since the current primary operator took control
      (whichever time period is shorter).
      <br></br>
      <br></br>
      Learn more about CSAF practices{' '}
      <a
        href="https://www.nrcs.usda.gov/sites/default/files/2023-10/NRCS-CSAF-Mitigation-Activities-List.pdf"
        target="_blank"
        rel="noreferrer">
        here
      </a>
      .<br></br>
      <br></br>
      If CSAF practices have been previously implemented on this field, please
      update your response to the related CSAF question within the farm
      enrollment section of this form to allow for field-level data entry here.
    </div>
  ),
  contained: 'contained',
  add_project_activity_btn_label: 'Add project activity',
  add_project_activity_btn_style: '4px 6.5px',
  medium: 'medium',
  DELETE_MODAL_HEADING_FIRST_HALF: 'Delete',
  delete_modal_content_first_half: 'Are you sure you want to delete',
  delete_modal_content_second_half: '? Any data entered will be lost.',
  field_enrollment_disclaimer:
    'Your previous selection of No or I don’t know to the implementation of CSAF practices during farm enrollment will be updated to Yes.',
  improve_grazing_management: 'Improve  grazing management',
  land_use_type: 'Land use type',
  activity: 'Activity',
  deletePropsType: 'field',
  ACRE_UOM: 'ac',
  FORM_DETAILS_SUCCESS: 'Form details saved successfully.',
  FORM_DETAILS_ERROR:
    'Form details failed to save due to technical error. Please try again.',
  DESABLE_SWITCH_TOOLTIP:
    'This farm has been locked for editing by another user.',
};
export const deleteModalInit = {
  open: false,
  title: '',
  subtitle: '',
  deleteProps: null,
};

export const cropTypeInititalData = {
  id: uniqueId(),
  cropTypeValue: '',
  activityTypeList: [],
  cropTypeValueError: false,
  activityTypeListError: false,
};

export const activityListLabels = (activity, activityDropDownList) => {
  const isImproveGrazingManagement =
    activityDropDownList?.find(
      (item) => item.value === activity?.projectActivityId,
    )?.label === FIELD_INFO_CONSTANT.improve_grazing_management;
  const finalData = [
    { label: 'Implementation year', value: activity.date?.slice(0, 4) },
    {
      label: checkTernaryCondition(
        isImproveGrazingManagement,
        'Total head count',
        'Total area',
      ),
      value: checkTernaryCondition(
        isImproveGrazingManagement,
        activity.totalHeadCount,
        activity.totalArea,
      ),
      unit: checkTernaryCondition(isImproveGrazingManagement, 'unit', 'acres'),
    },
  ];
  /*   for (const historyItem of activity.fieldPlannedActivityHistory) {
    if (
      historyItem.historicallyImplemented === 'Yes' &&
      activity?.historicallyImplemented === 'Yes'
    ) {
      finalData.push({
        label: historyItem.implementationYear?.slice(0, 4),
        value: historyItem.totalArea,
        unit: 'acres',
      });
    }
  } */
  return finalData;
};

export const activityListInitialData = {
  fieldCropProjectActivityId: 0,
  projectActivityId: 1,
  date: new Date().getFullYear()?.toString(),
  totalArea: 12,
  totalHeadCount: 0,
  historicallyImplemented: 'Yes',
  fieldPlannedActivityHistory: [
    {
      fieldProjectActivityHistoricalId: 0,
      implementationYear: (new Date().getFullYear() - 3)?.toString(),
      totalArea: 17500,
      historicallyImplemented: 'Yes',
    },
    {
      fieldProjectActivityHistoricalId: 0,
      implementationYear: (new Date().getFullYear() - 2)?.toString(),
      totalArea: 0,
      historicallyImplemented: 'No',
    },
    {
      fieldProjectActivityHistoricalId: 0,
      implementationYear: (new Date().getFullYear() - 1)?.toString(),
      totalArea: 1212,
      historicallyImplemented: 'Yes',
    },
  ],
};

export const getNext10Years = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < 10; i++) {
    const year = currentYear + i;
    years.push({ label: year.toString(), value: year.toString() });
  }
  return years;
};

export const YesNoIdontknowTypes = [
  {
    itemLabel: 'Yes',
    selected: false,
    isDisabled: false,
  },
  {
    itemLabel: 'No',
    selected: false,
    isDisabled: false,
  },
  {
    itemLabel: "I don't know",
    selected: false,
    isDisabled: false,
  },
];
export const YesNoTypes = [
  {
    itemLabel: 'Yes',
    selected: false,
    isDisabled: false,
  },
  {
    itemLabel: 'No',
    selected: false,
    isDisabled: false,
  },
];
export const plannedProjectActivityRequiredError =
  'Total area must be greater than 0.';
export const plannedProjectActivityGreaterThanFieldAreaError =
  "Total area for planned project activity can't exceed total field area.";

export const COVER_CROP = 'Cover crop';
export const NUTRIENT_MANAGEMENT = 'Nutrient management';
export const REDUCED_TILL = 'Residue + tillage management: Reduced tillage';
export const NO_TILL = 'Residue + tillage management: No tillage';
export const EDGE_OF_FIELD_FIELD_BORDER =
  'Edge of field practices: Field border';
export const RESIDUE_TILLAGE_TOOLTIP_FIRST =
  'Implemented activity acreage for ';
export const RESIDUE_TILLAGE_TOOLTIP_SECOND = 'must equal field acreage';
export const EDGE_OF_FIELD_ERROR =
  'Total area for edge of field activity cannot equal to or exceed total field area.';

export const MOCK_PROJECT_ACTIVITY = {
  id: 1,
  name: 'Residue + tillage management: Reduced tillage',
  totalAcres: '10',
  category: FIELD_INFO_CONSTANT.RESIDUE_TILLAGE_MANAGEMENT,
};
export const MOCK_PROJECT_ACTIVITY_2 = {
  id: 2,
  name: 'Cover Crop',
  totalAcres: '10',
  category: FIELD_INFO_CONSTANT.RESIDUE_TILLAGE_MANAGEMENT,
};
export const MOCK_PROJECT_ACTIVITY_3 = {
  id: 3,
  name: 'Edge of field practices: Contour buffer strips',
  totalAcres: '200',
  category: FIELD_INFO_CONSTANT.EDGE_OF_FIELD_PRACTICES,
};

export const MOCK_ERROR = {
  edgeOfFieldErrorList: [],
  plannedProjectActivityErrorList: [],
};
export const MOCK_ERROR_WITH_MESSAGES = {
  id: 3,
  isError: true,
  errorMessage: 'Error message',
};

export const fieldEnrolment = [
  {
    FIELD_AREA: [
      {
        dataElementId: 17,
        dataElementName: 'Field area',
        uomId: 16,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ac',
        uomNameFull: 'acre',
        uomNameDisplay: 'ac',
        uomType: 'area',
        uiModal: 'Field enrollment',
      },
    ],
  },
  {
    PLANNED_TOTAL_ACTIVITY_AREA: [
      {
        dataElementId: 18,
        dataElementName: 'Planned total activity area',
        uomId: 16,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ac',
        uomNameFull: 'acre',
        uomNameDisplay: 'ac',
        uomType: 'area',
        uiModal: 'Field enrollment',
      },
    ],
  },
];
