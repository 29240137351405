import { useDropzone } from 'react-dropzone';
import {
  AcceptedFileItem,
  DragAndDropText,
  DropZoneWrapper,
  ErroWrapper,
  FileName,
  FileUploadSubtext,
  FormFields,
  FormLabels,
  OptionalLabel,
  UploadWrapper,
  deleteIconSx,
} from '../FormStyles';
import {
  MULTIPLE_FILE_UPLOAD_CONSTANT,
  getError,
} from './MultipleFileUploadInput.content';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import { GLOBAL_STRING_CONSTANTS } from 'utils/config';

const MultipleFileUploadInput = ({
  label,
  acceptedFilesInfo = {},
  dragAndDropText,
  isOptional,
  updateFileData,
  marginBottom,
  marginTop,
  hasNoBottomMargin,
  showRejectedFiles = false,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [uploadErrorList, setUploadErrorList] = useState([]);
  const dropZoneProps = {
    ...(acceptedFilesInfo?.accept && {
      accept: acceptedFilesInfo?.accept,
    }),
    ...(acceptedFilesInfo?.maxNoOfFiles && {
      maxFiles: acceptedFilesInfo?.maxNoOfFiles,
    }),
    ...(acceptedFilesInfo?.maxFileSize && {
      maxSize: acceptedFilesInfo.maxFileSize,
    }),
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone(dropZoneProps);

  const addFiles = (files, addFilesHandler) => {
    if (files.length > 0) {
      addFilesHandler((prevFiles) => {
        return [...prevFiles, ...files];
      });
    }
  };

  const deleteFile = (fileIndex, fileHandler) => {
    fileHandler((prevFiles) =>
      prevFiles.filter((_, index) => fileIndex !== index),
    );
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      addFiles(
        acceptedFiles.map((file) => ({ file: file, id: uuid() })),
        setUploadedFiles,
      );
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (uploadedFiles) {
      updateFileData(uploadedFiles, uploadErrorList.length > 0);
    }
  }, [uploadedFiles, uploadErrorList]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.forEach((file) => {
        const errorMsg = getError(
          file.errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        );

        setUploadErrorList((prev) => [...prev, errorMsg]);
      });
      addFiles(
        fileRejections.map((fileItem) => ({ ...fileItem, id: uuid() })),
        setRejectedFiles,
      );
    }
  }, [fileRejections]);

  return (
    <FormFields hasNoBottomMargin={hasNoBottomMargin}>
      <FormLabels
        flexDirection="row"
        marginBottom={marginBottom}
        marginTop={marginTop}>
        {label}{' '}
        {uploadErrorList.length > 0 && <AlertIcon data-testId="error-icon" />}
        {isOptional && (
          <OptionalLabel fontSize={'0.75rem'} marginLeft={'0.5rem'}>
            {GLOBAL_STRING_CONSTANTS.OPTIONAL_TEXT}
          </OptionalLabel>
        )}
      </FormLabels>
      <DropZoneWrapper data-testid="dropzone">
        <UploadWrapper width="100%" className="center-content">
          <div {...getRootProps({ className: 'dropzone center-content' })}>
            <input {...getInputProps()} data-testid="fileDropzone" />
            <DragAndDropText>
              {`${dragAndDropText} or `}
              <FileUploadSubtext>
                {MULTIPLE_FILE_UPLOAD_CONSTANT.browse_text}
              </FileUploadSubtext>
            </DragAndDropText>
          </div>
        </UploadWrapper>

        {uploadErrorList.length > 0 &&
          //render only unique errors
          [...new Set(uploadErrorList)].map((message) => (
            <ErroWrapper marginTop="0.25rem" key={message}>
              {message}
            </ErroWrapper>
          ))}

        {uploadedFiles.map((fileItem, index) => (
          <AcceptedFileItem key={fileItem.id}>
            <FileName isError={false}>{fileItem.file.name}</FileName>
            <DeleteIcon
              fontSize="large"
              sx={deleteIconSx}
              onClick={() => {
                deleteFile(index, setUploadedFiles);
              }}
            />
          </AcceptedFileItem>
        ))}

        {showRejectedFiles &&
          rejectedFiles.map(({ file, id }, index) => (
            <AcceptedFileItem key={id}>
              <FileName isError={true}>{file.name}</FileName>
              <DeleteIcon
                fontSize="large"
                sx={deleteIconSx}
                onClick={() => {
                  deleteFile(index, setRejectedFiles);
                  //filter out the error message from the list
                  setUploadErrorList((prev) =>
                    prev.filter((_, errorIndex) => index !== errorIndex),
                  );
                }}
              />
            </AcceptedFileItem>
          ))}
      </DropZoneWrapper>
    </FormFields>
  );
};

MultipleFileUploadInput.propTypes = {
  label: PropTypes.string,
  acceptedFilesInfo: PropTypes.object,
  dragAndDropText: PropTypes.string,
  isOptional: PropTypes.bool,
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasNoBottomMargin: PropTypes.bool,
  updateFileData: PropTypes.func,
  showRejectedFiles: PropTypes.bool,
};

export default MultipleFileUploadInput;
