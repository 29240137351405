import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import ProTypes from 'prop-types';
import { FORM_CONTENT } from 'components/FormComponents/FormComponents.content';
import {
  RadioButtonStyle,
  RadioIconStyle,
} from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.style';
import { useContext, useEffect, useState } from 'react';

import {
  cancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogTitleStyle,
  IconButtonStyle,
  secondSubmitBtnStyle,
} from 'pages/ParticipantDataCollection/components/EnrollmentCompleteModal/EnrollmentCompleteModal.style';
import CloseIcon from '@mui/icons-material/Close';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import axios from 'axios';
import { FETCH_EMAIL_TEMPLATE, PARTICIPANT_REPORT_FIRST_PART } from 'urls';
import {
  EmailTextArea,
  EmailText,
  EmailLink,
  EditableTextArea,
  ReviewText,
  RadioBtnGroupSx,
  reopenDialogTitleStyle,
  reopenDialogContentStyle,
  reopenDialogActionStyle,
} from './Participants.style';
import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import { closeIconSx } from 'theme/GlobalStyles';
import { loaderContext } from 'contextAPI/loaderContext';
import { checkTernaryCondition, isEmpty } from '../../../../utils/helper';
import { getActionIdFromActionCode, LABELS } from './Participants.content';
import { Link } from 'react-router-dom';
import { USER_STATUS } from 'utils/config';
import { ConfigurationContext } from 'contextAPI/configurationContext';
import uuid from 'react-uuid';

export const ReOpenTemplate = ({ reopenProps, setReopenProps }) => {
  const [isRequestSent, setRequestSent] = useState(false);
  const [formattedContext, setFormattedContext] = useState('');
  const [additionalContext, setAdditionalContext] = useState('');
  const [participantEmailInfo, setParticipantEmailInfo] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const endGreetingsParts =
    participantEmailInfo?.endGreetings?.split('<br>') ?? [];

  /**
   * @description Determines the valid reopen action code based on the activity type.
   * @returns {string|null} The valid reopen action code corresponding to the activity type, or null if the activity type is not recognized.
   */
  const getValidReOpenActionCode = () => {
    let validActionCode;
    switch (reopenProps.activityType) {
      case LABELS.ACTIVITY_REPORTING:
        validActionCode = LABELS.ACTIVITY_REPORTING_REOPEN_ACTION_CODE;
        break;
      case LABELS.BASELINE_REPORTING:
        validActionCode = LABELS.BASELINE_REPORTING_REOPEN_ACTION_CODE;
        break;
      case LABELS.ENROLLMENT:
        validActionCode = LABELS.ENROLLMENT_REOPEN_ACTION_CODE;
        break;
      default:
        validActionCode = null;
        break;
    }
    return validActionCode;
  };
  useEffect(() => {
    const validActionCode = getValidReOpenActionCode();
    if (!isEmpty(validActionCode)) {
      const url = FETCH_EMAIL_TEMPLATE(
        validActionCode,
        reopenProps.participantId,
        reopenProps.projectCycleId,
      );

      axios.get(url).then((res) => {
        setParticipantEmailInfo(res.data);
      });
    }
  }, []);

  useEffect(() => {
    setFormattedContext(
      reopenProps.contentText(participantEmailInfo?.participantName),
    );
    setAdditionalContext(reopenProps.additionalContextText || null);
  }, [participantEmailInfo]);

  useEffect(() => {
    if (reopenProps?.modalData?.flag && reopenProps?.participantId) {
      axios
        .get(
          `${PARTICIPANT_REPORT_FIRST_PART}?participantId=${reopenProps.participantId}`,
        )
        .then((response) => {
          const userStatus = response.data.userStatus;
          setUserStatus(userStatus);
        });
    }
  }, [reopenProps?.modalData?.flag]);

  const changeReopenSelection = (e) => {
    setRequestSent(e.target.value === 'true');
    setReopenProps((previous) => ({
      ...previous,
      isOnBehalf: e.target.value === 'true',
      isValueSelected: true,
    }));
  };

  const handleKeyDown = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
    setReopenProps((previous) => ({
      ...previous,
      customEmailMessage: e.target.value,
    }));
  };

  return (
    <>
      {additionalContext && <ReviewText>{additionalContext}</ReviewText>}
      <ReviewText>{formattedContext}</ReviewText>
      <FormControl>
        <RadioGroup
          sx={RadioBtnGroupSx}
          row
          aria-labelledby="demo-radio-buttons-group-label"
          onChange={changeReopenSelection}
          name="radio-buttons-group">
          <FormControlLabel
            sx={RadioButtonStyle(null)}
            value={true}
            control={
              <Radio size={FORM_CONTENT.small} sx={RadioIconStyle(true)} />
            }
            label={LABELS.SEND_REQUEST_WITH_MESSAGE}
            disabled={userStatus !== USER_STATUS.STATUS.ACTIVE}
          />
          <FormControlLabel
            sx={RadioButtonStyle(null)}
            value={false}
            control={
              <Radio size={FORM_CONTENT.small} sx={RadioIconStyle(true)} />
            }
            label={
              reopenProps.customReopenRadioLabel ?? LABELS.REOPEN_ON_BEHALF
            }
          />
        </RadioGroup>
      </FormControl>
      {isRequestSent && (
        <>
          <ReviewText margin="1rem 0rem 0.25rem 0rem">
            {LABELS.APPROVAL_TEXT}
          </ReviewText>

          <EmailTextArea>
            <EmailText>
              {participantEmailInfo?.participantName}
              <br />
              <br />
              {participantEmailInfo?.body?.split('<br>').map((line) => {
                return (
                  <span key={uuid()}>
                    {line}
                    <br />
                  </span>
                );
              })}
              <br />
              <EditableTextArea
                placeholder={LABELS.DESCRIPTION_CONTENT}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyDown}></EditableTextArea>
              <br />
            </EmailText>
            <Link
              to={participantEmailInfo?.reportingLink}
              target={LABELS.REQUEST_REOPEN_LINK_TARGET}
              rel={LABELS.REQUEST_REOPEN_LINK_REL}>
              <EmailLink>{participantEmailInfo?.reportingLinkText}</EmailLink>
            </Link>
            <EmailText>
              {participantEmailInfo?.helpText}
              <br />
              {!isEmpty(endGreetingsParts) && endGreetingsParts[0]}
              <br />
              {participantEmailInfo?.projectDeveloperName}
              <br />
              {!isEmpty(endGreetingsParts) &&
                endGreetingsParts.length > 1 &&
                endGreetingsParts[1]}
            </EmailText>
          </EmailTextArea>
        </>
      )}
    </>
  );
};

ReOpenTemplate.propTypes = {
  reopenProps: ProTypes.shape({
    contentText: ProTypes.func,
    additionalContextText: ProTypes.string,
    emailPlaceholder: ProTypes.func,
    participantId: ProTypes.string,
    projectId: ProTypes.string,
    customReopenRadioLabel: ProTypes.string,
    enrollmentCta: ProTypes.string,
    modalData: ProTypes.object,
    isOnBehalf: ProTypes.bool,
    isValueSelected: ProTypes.bool,
    customEmailMessage: ProTypes.string,
    onReopenSubmit: ProTypes.func,
    instanceId: ProTypes.string,
    contractId: ProTypes.string,
    projectCycleId: ProTypes.string,
    formId: ProTypes.string,
    ishandleApprove: ProTypes.bool,
    handleApprove: ProTypes.func,
    activityType: ProTypes.string,
  }),
  setReopenProps: ProTypes.func,
};

export const ReopenCustomModal = ({
  reopenProps,
  setReopenProps,
  statusMappingList,
  onClose = () => {},
}) => {
  const {
    reOpenStatus,
    updateParticipantStatus,
    updateParticipantBaselineStatus,
  } = useContext(LifeCycleContext);
  const { setAreUiActionInProgress } = useContext(ConfigurationContext);
  const { setIsLoading } = useContext(loaderContext);

  const closeModal = () => {
    setReopenProps((previous) => ({
      ...previous,
      modalData: { flag: false },
    }));
    setAreUiActionInProgress((prev) => prev - 1);
  };
  return (
    <Dialog open={reopenProps.modalData.flag} onClose={() => {}} maxWidth="md">
      <IconButton
        sx={IconButtonStyle}
        onClick={() => {
          setReopenProps((previous) => ({
            ...previous,
            modalData: { flag: false },
          }));
          setAreUiActionInProgress((prev) => prev - 1);
        }}
        data-testid="close-icon">
        <CloseIcon sx={closeIconSx} />
      </IconButton>

      <DialogTitle sx={{ ...DialogTitleStyle, ...reopenDialogTitleStyle }}>
        {reopenProps.title}
      </DialogTitle>
      <DialogContent
        sx={{ ...DialogContentStyle, ...reopenDialogContentStyle }}>
        <ReOpenTemplate
          reopenProps={reopenProps}
          setReopenProps={setReopenProps}
        />
      </DialogContent>
      <DialogActions
        style={{ ...DialogActionStyle, ...reopenDialogActionStyle }}>
        <Button
          sx={[cancelBtnStyle, tertiaryButtonStyle]}
          onClick={() => {
            setReopenProps((previous) => ({
              ...previous,
              modalData: { flag: false },
            }));
            setAreUiActionInProgress((prev) => prev - 1);
          }}>
          Cancel
        </Button>
        <Button
          disabled={!reopenProps?.isValueSelected}
          sx={[secondSubmitBtnStyle, primaryButtonStyle()]}
          onClick={() => {
            const baselineActionId = checkTernaryCondition(
              !reopenProps.isOnBehalf,
              getActionIdFromActionCode(
                'BASELINE-REOPEN-ON-BEHALF',
                statusMappingList,
              ),
              getActionIdFromActionCode(
                'BASELINE-REOPEN-SEND-REQ-PRTCPNT',
                statusMappingList,
              ),
            );
            const actionId = checkTernaryCondition(
              !reopenProps.isOnBehalf,
              getActionIdFromActionCode(
                'ENROLL-REOPEN-ON-BEHALF',
                statusMappingList,
              ),
              getActionIdFromActionCode(
                'ENROLL-REOPEN-SEND-REQ-PRTCPNT',
                statusMappingList,
              ),
            );
            const activityId = checkTernaryCondition(
              !reopenProps.isOnBehalf,
              getActionIdFromActionCode(
                'ACTIVITY-REOPEN-ON-BEHALF',
                statusMappingList,
              ),
              getActionIdFromActionCode(
                'ACTIVITY-REOPEN-SEND-REQ-PRTCPNT',
                statusMappingList,
              ),
            );
            reOpenStatus(reopenProps);
            reopenProps.onReopenSubmit({
              customEmailMessage: reopenProps.customEmailMessage,
              isOnBehalf: reopenProps.isOnBehalf,
              setIsLoading: setIsLoading,
              closeModal: () => {
                closeModal();
                onClose();
              },
              // Note: instanceId is only used in enrollment reopen, not used in baseline reopen
              instanceId: reopenProps.instanceId,
              actionId: actionId,
              contractId: reopenProps.contractId,
              updateParticipantStatus: updateParticipantStatus,
              projectCycleId: reopenProps.projectCycleId,
              updateParticipantBaselineStatus: updateParticipantBaselineStatus,
              baselineActionId: baselineActionId,
              formId: reopenProps.formId,
              activityId: activityId,
              ishandleApprove: reopenProps.ishandleApprove,
              handleApprove: reopenProps.handleApprove,
            });
          }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReopenCustomModal.propTypes = {
  reopenProps: ProTypes.shape({
    title: ProTypes.string,
    contentText: ProTypes.func,
    additionalContextText: ProTypes.string,
    emailPlaceholder: ProTypes.func,
    participantId: ProTypes.string,
    projectId: ProTypes.string,
    customReopenRadioLabel: ProTypes.string,
    enrollmentCta: ProTypes.string,
    modalData: ProTypes.object,
    isOnBehalf: ProTypes.bool,
    isValueSelected: ProTypes.bool,
    customEmailMessage: ProTypes.string,
    onReopenSubmit: ProTypes.func,
    instanceId: ProTypes.string,
    contractId: ProTypes.string,
    projectCycleId: ProTypes.string,
    formId: ProTypes.string,
    ishandleApprove: ProTypes.bool,
    handleApprove: ProTypes.func,
  }),
  setReopenProps: ProTypes.func,
  statusMappingList: ProTypes.array,
  onClose: ProTypes.func,
};
