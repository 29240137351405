import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { TabberWrapperStyle } from '../Org_Projects.style';
import ParticipantProfile from './ParticipantSummary/ParticipantProfile.react';
import {
  GET_FARM_AND_FIELDS_FOR_PARTICIPANTS,
  GET_PARTICIPANT_INFO,
} from 'urls';
import axios from 'axios';
import {
  LABELS,
  PARTICIPANT_TABS,
  PARTICIPANT_PARENT_PATH,
  displayToast,
} from './Participants.content';
import ParticipantSummary from './ParticipantTabs/ParticipantSummary.react';
import ParticipantActivity from './ParticipantActivity/ParticipantActivity.react';
import SummaryViewHeader from 'components/PageHeaders/SummaryViewHeader/SummaryViewHeader.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { checkTernaryCondition, hasAnyRequiredAccess } from 'utils/helper';
import { ERROR_MSGS, PARTICIPANT, PARTICIPANT_CONTRIBUTOR } from 'utils/config';
import { pathName } from 'Routes/routes.const';
import {
  SummaryHeaderWrapper,
  SummaryViewHeaderWrapper,
} from './Participants.style';

const Participant_Tab = ({ disableVirtualization }) => {
  const [name, setName] = useState();
  const [projectList, setProjectList] = useState([]);
  const [serachParams] = useSearchParams();
  const projectId = serachParams.get('projectid');
  const tab = serachParams.get('tab');
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [participantInfo, setParticipantInfo] = useState([]);
  const [participantType, setParticipantType] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const { userRoles } = useContext(userAuthRolesContext);
  const location = useLocation();
  let participantTabs = [
    {
      title: PARTICIPANT_TABS.summary,
      component: (
        <ParticipantSummary
          projectList={projectList}
          loading={loading}
          participantInfo={participantInfo}
          participantId={id}
          projectId={projectId}
        />
      ),
    },
    {
      title: PARTICIPANT_TABS.profile,
      component: (
        <ParticipantProfile
          projectData={projectData}
          disableVirtualization={disableVirtualization}
        />
      ),
      isDisabled: hasAnyRequiredAccess(userRoles, [
        PARTICIPANT,
        PARTICIPANT_CONTRIBUTOR,
      ]),
    },
    {
      title: PARTICIPANT_TABS.activity,
      component: (
        <ParticipantActivity
          disableVirtualization={disableVirtualization}
          participantName={name}
          projectList={projectList}
          participantId={id}
          projectData={projectData}
        />
      ),
    },
  ];
  if (participantType === LABELS.participant_contributor) {
    participantTabs = [participantTabs[1]];
  }

  const fetchParticipantDetails = () => {
    setLoading(true);
    axios
      .post(`${GET_PARTICIPANT_INFO}`, {
        enrolmentType: 'Baseline',
        pageNumber: 0,
        pageSize: 10,
        participantId: id,
      })
      .then((response) => {
        setName(
          `${response?.data?.content[0]?.firstName} ${response?.data?.content[0]?.lastName}`,
        );
        const finalProjectsList = [];
        response?.data?.content.map((row) =>
          finalProjectsList.push(row?.project),
        );
        setProjectList(finalProjectsList);
        setParticipantType(response?.data?.content[0]?.participantType);
        setProjectData(
          finalProjectsList.find(
            (project) => project.internalProjectId === +projectId,
          ),
        );
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchFarmAndFieldDetails = () => {
    setLoading(true);
    axios
      .post(
        `${GET_FARM_AND_FIELDS_FOR_PARTICIPANTS(id)}?projectId=${
          projectData?.internalProjectId
        }`,
      )
      .then((response) => {
        const infoRecords = [];
        // Group input data by farmId
        const farmGroups = response.data.reduce((farmObj, field) => {
          const farmId = field.farmId;
          if (!Object.hasOwnProperty.call(farmObj, farmId)) {
            farmObj[farmId] = {
              farmId: field.farmId,
              farmName: field.farmName,
              fieldDetails: [],
            };
          }
          farmObj[farmId].fieldDetails.push({
            farmId: field.farmId,
            fieldId: field.fieldId,
            fieldName: field.fieldName,
            fieldArea: field.fieldArea,
            baseline: field.baseline,
            activity: field.activity,
            delta: field.delta,
            fieldBoundaries: field.fieldBoundaries,
            activities: field.activities,
          });
          return farmObj;
        }, {});
        // Convert grouped data to array
        for (const farmId in farmGroups) {
          if (Object.hasOwnProperty.call(farmGroups, farmId)) {
            infoRecords.push(farmGroups[farmId]);
          }
        }
        setParticipantInfo(infoRecords);
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchParticipantDetails();
  }, []);

  useEffect(() => {
    if (projectList.length > 0) {
      fetchFarmAndFieldDetails();
    }
  }, [projectList]);

  const getCurrentPage = () => {
    return participantTabs.find((tabData) => tabData.title === tab).component;
  };

  return (
    <div>
      <SummaryViewHeader
        gap={'0.5rem'}
        previouspath={`${pathName.originationProject.view}/${projectId}`}
        previousstate={{
          state: {
            from: PARTICIPANT_PARENT_PATH,
            cycleId: location.state?.cycleId,
          },
        }}
        type={checkTernaryCondition(
          participantType !== LABELS.participant_contributor,
          LABELS.type,
          LABELS.contributor,
        )}
        title={
          <SummaryHeaderWrapper>
            <>{tab}</> |
            <SummaryViewHeaderWrapper
              title={checkTernaryCondition(name?.length > 46, name, '')}>
              {name}
            </SummaryViewHeaderWrapper>
          </SummaryHeaderWrapper>
        }
      />
      {tab !== PARTICIPANT_TABS.activity && <Box sx={TabberWrapperStyle} />}
      {getCurrentPage()}
    </div>
  );
};

Participant_Tab.propTypes = {
  disableVirtualization: PropTypes.bool,
};
export default Participant_Tab;
