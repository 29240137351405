export const LABELS = {
  addNew: 'Add New',
};

export const assetsButtonOption = [
  { name: 'Project', link: '/assets/projects/add' },
  {
    name: 'Purchase Transaction',
    link: '/transactions/add-purchase-transaction',
  },
  {
    name: 'Sale Transaction',
    link: '/transactions/add-sale-transaction',
  },
  {
    name: 'Stakeholder Profile',
    link: '/assets/stakeholders/add',
  },
];

export const originationAddNewButtonOptions = [
  { name: 'Origination Project', link: '/projects/add' },
];

export const participantEnrolmentRoutes = [
  '/stakeholders/participant-data-collection',
  '/stakeholders/enrollment-menu-page',
  '/stakeholders/enrollment-review-and-finalize-page',
  '/stakeholders/participant-field-baseline',
  '/stakeholders/baseline-menu-page',
  '/stakeholders/participant-field-activity',
  '/stakeholders/activity-reporting-menu-page',
  '/stakeholders/landing-page',
  '/logout',
];

export const baselineAndLandingPages = [
  '/stakeholders/landing-page',
  '/logout',
];
export const VIDEO_TUTORIAL_ROUTE = '/video-tutorials';
