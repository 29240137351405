import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import {
  DataGridContainer,
  datagridSx,
  DialogContentContainer,
  DialogPaddingStyle,
  DialogStyle,
  FieldDialogTitleSx,
  FormControlAllFieldsSx,
  FormControlSelectStatusesSx,
  IconButtonSx,
  MenuItemSx,
  searchIconStyle,
  SelectLabelSx,
  SelectSx,
} from './ProofOfImplementationMainComp.style';
import { PARTICIPANT_CONTENT } from 'pages/Origination/Org_Project/Participants/ParticipantSummary/ParticipantProfile.content';
import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';
import {
  PAGE_CONTENT,
  PROOF_UPLOAD_MESSAGE,
} from './ProofOfImplementationMainComp.content';
import { checkTernaryCondition } from 'utils/helper';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';

const FieldDataModal = ({
  open,
  fieldList,
  onClose,
  title,
  uploadedFields,
  totalFields,
}) => {
  const [selectedField, setSelectedField] = useState(PAGE_CONTENT.ALL_FIELDS);
  const [selectedStatus, setSelectedStatus] = useState(
    PAGE_CONTENT.ALL_STATUSES,
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(
    PARTICIPANT_CONTENT.default_page_size,
  );

  const handleFieldChange = (event) => {
    setSelectedField(
      checkTernaryCondition(
        event.target.value,
        event.target.value,
        PAGE_CONTENT.ALL_FIELDS,
      ),
    );
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(
      checkTernaryCondition(
        event.target.value,
        event.target.value,
        PAGE_CONTENT.ALL_STATUSES,
      ),
    );
  };

  const handleClose = () => {
    setSelectedField(PAGE_CONTENT.ALL_FIELDS);
    setSelectedStatus(PAGE_CONTENT.ALL_STATUSES);
    setPageNumber(0);
    onClose();
  };

  const columns = [
    {
      field: PAGE_CONTENT.NAME,
      headerName: PAGE_CONTENT.FIELD_HEADER_NAME,
      width: 390,
      sortable: false,
    },
    {
      field: PAGE_CONTENT.STATUS,
      headerName: PAGE_CONTENT.STATUS_HEADER_NAME,
      width: 126,
      sortable: false,
    },
  ];

  const filteredRows = fieldList
    ?.filter(
      (field) =>
        (field?.name.toLowerCase().includes(selectedField.toLowerCase()) ||
          selectedField === PAGE_CONTENT.ALL_FIELDS) &&
        (field.status === selectedStatus ||
          selectedStatus === PAGE_CONTENT.ALL_STATUSES),
    )
    ?.map((field, index) => ({
      id: index,
      name: field.name,
      status: field.status,
    }));

  const fieldTitle = PROOF_UPLOAD_MESSAGE.replace('{title}', title)
    .replace('{uploadedFields}', uploadedFields)
    .replace('{totalFields}', totalFields);

  return (
    <Dialog open={open} onClose={handleClose} sx={DialogStyle}>
      <DialogTitle sx={DialogPaddingStyle}>
        <Typography sx={FieldDialogTitleSx}>{fieldTitle}</Typography>
        <IconButton
          aria-label="close"
          data-testid="close-modal"
          onClick={handleClose}
          sx={IconButtonSx}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={DialogPaddingStyle}>
        <DialogContentContainer>
          <FormControl sx={FormControlAllFieldsSx}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search"
              onChange={handleFieldChange}
              sx={SelectSx}
              InputProps={{
                sx: { padding: '0' },
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="small">
                      <SearchIcon sx={searchIconStyle} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl sx={FormControlSelectStatusesSx}>
            <Select
              value={selectedStatus}
              onChange={handleStatusChange}
              sx={SelectSx}>
              <MenuItem value={PAGE_CONTENT.ALL_STATUSES}>
                <Typography style={SelectLabelSx}>
                  {PAGE_CONTENT.ALL_STATUSES}
                </Typography>
              </MenuItem>
              {Array.from(new Set(fieldList?.map((field) => field.status))).map(
                (status) => (
                  <MenuItem key={status} value={status} sx={MenuItemSx}>
                    {status}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </DialogContentContainer>
        <DataGridContainer>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            disableColumnMenu
            disableColumnFilter
            disableSelectionOnClick
            sx={datagridSx(totalFields)}
            page={pageNumber}
            paginationMode={PAGE_CONTENT.CLIENT}
            onPageChange={(newPage) => setPageNumber(newPage)}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
              setPageNumber(0); // Reset to first page when page size changes
            }}
            pagination
            rowCount={filteredRows?.length}
            rowsPerPageOptions={PARTICIPANT_CONTENT.default_pagination_options}
            componentsProps={{
              pagination: {
                labelRowsPerPage: PAGE_CONTENT.ITEMS_PER_PAGE,
                SelectProps: TablePaginationMenuListStyle,
                labelDisplayedRows: ({ from }) => {
                  const pageNumber = Math.floor(from / pageSize) + 1;
                  return `${pageNumber} of ${
                    Math.floor(filteredRows.length / pageSize) + 1
                  }`;
                },
              },
            }}
          />
        </DataGridContainer>
      </DialogContent>
    </Dialog>
  );
};

export default FieldDataModal;

FieldDataModal.propTypes = {
  open: PropTypes.bool,
  fieldList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ),
  onClose: PropTypes.func,
  title: PropTypes.string,
  uploadedFields: PropTypes.number,
  totalFields: PropTypes.number,
};
