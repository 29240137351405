import PropTypes from 'prop-types';
import { Input } from '@mui/material';
import { FORM_CONTENT } from '../FormComponents.content';
import {
  FormFields,
  FormLabels,
  ErrorWrapper,
  RightSideFormLabel,
  RightSideLabelErrorWrapper,
  DeleteWrapper,
  PredefinedUnitWrapper,
  DropdownInputSx,
  OptionalLabel,
  LabelToolTipWrapper,
  LeftSideFormLabel,
  LabelTextWrapper,
  ToolTipSx,
  AlertIconSx,
  ToolTipAndAlertWrapper,
  InputInfoToolTipSx,
} from '../FormStyles';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { checkTernaryCondition } from 'utils/helper';
import InfoTooltip from '../InfoTooltip/InfoTooltip.react';
import {
  BLACK,
  COOL_GRAY,
  NEUTRAL_BACKGROUND_COLOR,
  WHITE,
} from 'theme/GlobalColors';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import { RadioErrorIconStyle } from '../RadioButtonGroup/RadioButtonGroup.style';
import { alertIconStyling } from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/NitrogenFertilizerModal/NitrogentFertilizerModal.style';
import { GLOBAL_STRING_CONSTANTS, INPUT_CATEGORY } from 'utils/config';
const InputField = ({
  label,
  value,
  placeholder,
  name,
  optionalLabelStyle,
  onUpdate,
  onBlurHandler,
  onFocusHandler,
  width,
  maxWidthForPrimaryError,
  maxWidthForSecondaryError,
  primaryError,
  primaryErrorMessage,
  secondaryError,
  secondaryErrorMessage,
  isOptional,
  readOnly,
  isDisabled,
  labelOnRightSide,
  labelOnLeftSide,
  display,
  rightSideLabelFormLabelMargin,
  hasDeleteIcon,
  onDelete,
  deleteTestId,
  noLabel = false,
  customStyles,
  minWidth,
  hasPredefinedUnit = false,
  predefinedUnit,
  testId,
  hasTooltip = false,
  tooltipContent,
  tooltipTitle = '',
  labelWidth,
  thirdError,
  thirdErrorMessage,
  customInputSx,
  labelOnRightSideColor,
  labelMarginTop,
  labelMarginBottom,
  labelLineHeight,
  frontLabel,
  listItemName,
  listItemNameColor,
  paddingForError,
  listItemMarginLeft,
  frontlabelMarginLeft,
  cropValueLineHeight,
  customPattern,
  noGap = false,
  inputTextFieldColor,
  textDisabledColor,
  optionalOpacity = 0.5,
  textAlignment,
  optionalLabelOnRight = false,
  rightSideLabelErrorMarginBottom,
  headerColor,
  paddingForInput,
  leftMarginForRightSideLabel,
  topMarginForRightSideLabel,
  marginForError,
  nonEditable,
  infoTooltipPlacement,
  maxLength = 100,
  category = INPUT_CATEGORY.TEXT,
  hasElementAsLabel = false,
}) => {
  const hasError = primaryError || secondaryError || thirdError;

  const validateRegex = (e) => {
    const inputValue = e?.target?.value;

    switch (category) {
      case INPUT_CATEGORY.TEXT: {
        const textFieldPattern = /^[ a-zA-Z0-9]*$/;
        return textFieldPattern.test(inputValue);
      }

      case INPUT_CATEGORY.NAME_FIELD: {
        const namePattern = /^[a-zA-Z0-9 ,'.-]*$/;
        return namePattern.test(inputValue);
      }

      case INPUT_CATEGORY.EMAIL: {
        const emailPattern = /^[a-zA-Z0-9@._-]*$/;
        return emailPattern.test(inputValue);
      }

      case INPUT_CATEGORY.LONG_NUMBER: {
        return inputValue <= 50000000;
      }

      case INPUT_CATEGORY.NUMBER:
      case INPUT_CATEGORY.DATE:
      case INPUT_CATEGORY.PHONE: {
        return true;
      }

      case INPUT_CATEGORY.PERCENT: {
        return inputValue <= 100;
      }

      default:
        return true;
    }
  };

  return (
    <div style={customStyles}>
      <FormFields
        marginBottom={rightSideLabelFormLabelMargin}
        readOnly={readOnly}
        nonEditable={nonEditable}
        display={display}
        minWidth={minWidth}>
        {checkTernaryCondition(
          noLabel,
          null,
          !labelOnRightSide && !labelOnLeftSide && (
            <FormLabels
              iserror={hasError}
              className={hasError && FORM_CONTENT.error_class}
              marginTop={labelMarginTop}
              marginBottom={labelMarginBottom}
              width={labelWidth}
              lineHeight={labelLineHeight}>
              <LabelToolTipWrapper
                color={headerColor}
                noGap={noGap}
                iserror={hasError}>
                <LabelTextWrapper
                  hasElementAsLabel={hasElementAsLabel}
                  hasError={hasError}
                  labelWidth={labelWidth}>
                  {label}
                </LabelTextWrapper>
                <ToolTipAndAlertWrapper>
                  {isOptional && (
                    <OptionalLabel
                      opacity={optionalOpacity}
                      marginLeft={optionalLabelStyle}
                      lineHeight={cropValueLineHeight}>
                      {GLOBAL_STRING_CONSTANTS.OPTIONAL_TEXT}
                    </OptionalLabel>
                  )}
                  {hasTooltip && tooltipContent && (
                    <InfoTooltip
                      title={tooltipTitle}
                      content={tooltipContent}
                      customSx={{ ...InputInfoToolTipSx, ...ToolTipSx }}
                      placement={infoTooltipPlacement}
                    />
                  )}
                  {hasError && (
                    <AlertIcon
                      data-testid="custom-date-error"
                      style={{ ...alertIconStyling, ...AlertIconSx }}
                    />
                  )}
                </ToolTipAndAlertWrapper>
              </LabelToolTipWrapper>
            </FormLabels>
          ),
        )}
        {frontLabel && (
          <OptionalLabel opacity={1} marginLeft={frontlabelMarginLeft}>
            {frontLabel}
          </OptionalLabel>
        )}
        <Input
          value={value ?? ''}
          name={name}
          placeholder={placeholder}
          required={!isOptional}
          onChange={(e) => {
            validateRegex(e) && onUpdate(e);
          }}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          disabled={isDisabled}
          inputProps={{
            maxlength: maxLength,
            sx: {
              textAlign: textAlignment,
              '&.Mui-disabled': {
                '-webkit-text-fill-color': textDisabledColor || BLACK,
              },
              '&.MuiInputBase-input': {
                boxShadow: checkTernaryCondition(
                  nonEditable,
                  `0 0 0 62.5rem ${NEUTRAL_BACKGROUND_COLOR} inset`,
                  'none',
                ),
              },
              '&::placeholder': {
                color: COOL_GRAY,
                size: '14px',
                fontSize: '14px',
                opacity: 1,
                visibility: 'hidden',
                backgroundColor: checkTernaryCondition(
                  isDisabled,
                  NEUTRAL_BACKGROUND_COLOR,
                  WHITE,
                ),
              },
            },
            pattern: customPattern,
            'data-testid': testId ?? 'input-field',
          }}
          sx={[
            {
              color: !nonEditable ? inputTextFieldColor : BLACK,
              width: width,
              maxWidth: '34.5rem',
              fontSize: '14px',
              padding: paddingForInput,
            },
            hasError ? {} : DropdownInputSx,
            customInputSx,
          ]}
        />
        {hasPredefinedUnit && (
          <PredefinedUnitWrapper>{predefinedUnit}</PredefinedUnitWrapper>
        )}
        {optionalLabelOnRight && (
          <OptionalLabel opacity={1} marginLeft="1rem">
            {GLOBAL_STRING_CONSTANTS.OPTIONAL_TEXT}
          </OptionalLabel>
        )}
        {listItemName && (
          <OptionalLabel
            color={listItemNameColor}
            opacity={1}
            marginLeft={listItemMarginLeft}>
            {listItemName}
          </OptionalLabel>
        )}
        {hasDeleteIcon && (
          <DeleteWrapper data-testid={deleteTestId} onClick={onDelete}>
            <DeleteIcon color={COOL_GRAY} />
          </DeleteWrapper>
        )}
        {labelOnRightSide && (
          <RightSideFormLabel
            color={labelOnRightSideColor}
            marginLeft={leftMarginForRightSideLabel}
            marginTop={topMarginForRightSideLabel}>
            {label}
            {hasError && (
              <AlertIcon
                data-testid="input-error-icon"
                style={RadioErrorIconStyle}
              />
            )}
          </RightSideFormLabel>
        )}
        {labelOnLeftSide && (
          <LeftSideFormLabel color={BLACK}>
            {label}
            {hasError && (
              <AlertIcon
                data-testid="input-error-icon"
                style={RadioErrorIconStyle}
              />
            )}
          </LeftSideFormLabel>
        )}
        {!labelOnRightSide && primaryError && (
          <ErrorWrapper
            marginTop={marginForError}
            paddingLeft={paddingForError}
            maxWidth={maxWidthForPrimaryError}>
            {primaryErrorMessage}
          </ErrorWrapper>
        )}
        {!labelOnRightSide && secondaryError && (
          <ErrorWrapper
            marginTop={marginForError}
            paddingLeft={paddingForError}
            maxWidth={maxWidthForSecondaryError}>
            {secondaryErrorMessage}
          </ErrorWrapper>
        )}
        {!labelOnRightSide && thirdError && (
          <ErrorWrapper>{thirdErrorMessage}</ErrorWrapper>
        )}
      </FormFields>
      {labelOnRightSide && primaryError && (
        <RightSideLabelErrorWrapper
          marginBottom={rightSideLabelErrorMarginBottom}>
          {primaryErrorMessage}
        </RightSideLabelErrorWrapper>
      )}
      {labelOnRightSide && secondaryError && (
        <RightSideLabelErrorWrapper
          marginBottom={rightSideLabelErrorMarginBottom}>
          {secondaryErrorMessage}
        </RightSideLabelErrorWrapper>
      )}
      {labelOnRightSide && thirdError && (
        <RightSideLabelErrorWrapper
          marginBottom={rightSideLabelErrorMarginBottom}>
          {thirdErrorMessage}
        </RightSideLabelErrorWrapper>
      )}
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onUpdate: PropTypes.func,
  onBlurHandler: PropTypes.func,
  onFocusHandler: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  primaryError: PropTypes.bool,
  primaryErrorMessage: PropTypes.string,
  secondaryError: PropTypes.bool,
  secondaryErrorMessage: PropTypes.string,
  isOptional: PropTypes.bool,
  readOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  labelOnRightSide: PropTypes.bool,
  labelOnLeftSide: PropTypes.bool,
  display: PropTypes.string,
  rightSideLabelFormLabelMargin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hasDeleteIcon: PropTypes.bool,
  onDelete: PropTypes.func,
  deleteTestId: PropTypes.string,
  noLabel: PropTypes.bool,
  customStyles: PropTypes.object,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasPredefinedUnit: PropTypes.bool,
  predefinedUnit: PropTypes.string,
  testId: PropTypes.string,
  hasTooltip: PropTypes.bool,
  textDisabledColor: PropTypes.string,
  optionalLabelStyle: PropTypes.number,
  tooltipContent: PropTypes.string,
  tooltipTitle: PropTypes.string,
  labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thirdError: PropTypes.bool,
  thirdErrorMessage: PropTypes.string,
  customInputSx: PropTypes.object,
  labelOnRightSideColor: PropTypes.string,
  labelMarginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelMarginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelLineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  frontLabel: PropTypes.string,
  listItemName: PropTypes.string,
  listItemNameColor: PropTypes.string,
  paddingForError: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noGap: PropTypes.bool,
  optionalOpacity: PropTypes.number,
  optionalLabelOnRight: PropTypes.bool,
  rightSideLabelErrorMarginBottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  paddingForInput: PropTypes.string,
  leftMarginForRightSideLabel: PropTypes.string,
  topMarginForRightSideLabel: PropTypes.string,
  nonEditable: PropTypes.bool,
  maxLength: PropTypes.number,
  category: PropTypes.oneOf(Object.values(INPUT_CATEGORY)),
  cropValueLineHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  infoTooltipPlacement: PropTypes.string,
  textAlignment: PropTypes.string,
  headerColor: PropTypes.string,
  marginForError: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customPattern: PropTypes.string,
  listItemMarginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  frontlabelMarginLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  inputTextFieldColor: PropTypes.string,
  hasElementAsLabel: PropTypes.bool,
  maxWidthForPrimaryError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  maxWidthForSecondaryError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default InputField;
