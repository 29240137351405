import { Backdrop, Button, Checkbox, CircularProgress } from '@mui/material';
import { useParticipantEnrollmentDetails } from 'hooks/useParticipantEnrollmentDetails';
import StatusBox from 'pages/ParticipantDataCollection/components/StatusBox/StatusBox.react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { pathName } from 'Routes/routes.const';
import {
  checkAccess,
  checkTernaryCondition,
  isValueEmptyNullorUndefined,
  openPDFFromLink,
} from 'utils/helper';
import {
  FINAL_PAGE_CONTENT,
  getActivityStatus,
  getActivitySubHeading,
  getBaselineStatus,
  getBaselineSubHeading,
  getDownloadHandlerApiUrl,
  getEnrollmentType,
  getMarginTop,
  getMenuPageSubmitButtonLabel,
  getProjectNameHeading,
  getSubHeading,
  LANDING_PAGE_CONTENT,
  MENU_PAGE_CONTENT,
} from './ParticipantPages.content';
import {
  MainWrapper,
  MenuOrReviewContentWrapper,
  MenuFooter,
  MenuOrReviewSubHeading,
  MenuOrReviewPageWrapper,
  MenuOrReviewHeading,
  DownloadResponseWrapper,
  DownloadResponseText,
  buttonStyleSx,
  AttestationCheckBoxLabel,
  ReturnToHomeLink,
  LeftArrowButton,
  MenuOrReviewSubHeading1,
  StyledSpan,
  AlmostDonePageWrapper,
  ParentContainer,
  MenuOrReviewContent,
} from './ParticipantPages.style';
import { ReactComponent as UpdatedPersonIcon } from '../../../assets/icons/updatedPersonIcon.svg';
import { ReactComponent as UpdatedFarmIcon } from '../../../assets/icons/updatedFarmIcon.svg';
import { ReactComponent as DisabledFarmIcon } from '../../../assets/icons/disabledFarmIcon.svg';
import { ReactComponent as MapIcon } from '../../../assets/icons/mapIcon.svg';
import { ReactComponent as DisabledFieldIcon } from '../../../assets/icons/disabledFieldIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/smallDownloadIcon.svg';
import { ReactComponent as DisabledDownloadIcon } from '../../../assets/icons/DisabledDownloadIcon.svg';
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/LeftArrow.svg';
import axios from 'axios';
import { FETCH_PROJECT_LIFECYCLES } from 'urls';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { primaryButtonStyle } from 'components/FormComponents/FormStyles';
import {
  ORIGINATION_PROJECT_ACTIVITY_REPORT_CREATE,
  ORIGINATION_PROJECT_BASELINE_REPORT_CREATE,
  ORIGINATION_PROJECT_ENROLLMENT_REPORT_CREATE,
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
  REPORTING_STATUS,
} from 'utils/config';
import { TermsOfAttestation } from '../components/TermsOfAttestation/TermsOfAttestation.react';
import PropTypes from 'prop-types';
import moment from 'moment';

const MenuPage = ({ moduleName }) => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectcycleId = searchParams.get('projectcycleid');
  const { isEmailLinkClicked } = useContext(userAuthRolesContext);
  const { pageDetails, fetchPageDetails, loading, finishEnrollmentHandler } =
    useParticipantEnrollmentDetails();
  const navigate = useNavigate();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [showWelcomeBackText, setShowWelcomeBackText] = useState(false);
  const { state } = useLocation();
  const [fieldTab, setFieldTab] = useState({});
  const [subHeading, setSubHeading] = useState('');
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [mainHeading, setMainHeading] = useState('');
  const [projectNameHeading, setProjectNameHeading] = useState('');
  const [disableStatusOutline, setDisableStatusOutline] = useState(false);
  const [showDownloadResponse, setShowDownloadResponse] = useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const [attestationCheck, setAttestationCheck] = useState(false);
  const [isTermsAndConditionsFullyRead, setIsTermsAndConditionsFullyRead] =
    useState(false);
  const [cycleYear, setCycleYear] = useState('');
  const { userRoles, permissions, userDetails } =
    useContext(userAuthRolesContext);
  const isParticipantContributor = userRoles?.includes(PARTICIPANT_CONTRIBUTOR);
  const isParticipantOwner = userRoles?.includes(PARTICIPANT);
  const participantFirstName = checkTernaryCondition(
    isParticipantContributor,
    userDetails?.firstName,
    pageDetails?.participantFirstName,
  );

  // creating an IIFE to resolve sonar issue
  const initialHomePageCondition = (() => {
    return (
      (moduleName === LANDING_PAGE_CONTENT.activityModuleName &&
        pageDetails?.activityReportingStatus === 'Interested') ||
      (moduleName === LANDING_PAGE_CONTENT.baselineModuleName &&
        pageDetails?.baselineEnrollmentStatus === 'Interested')
    );
  })();

  const isAlmostDonePage = mainHeading === FINAL_PAGE_CONTENT.REVIEW_HEADING;

  const hasReportGenerateAccess = () => {
    switch (moduleName) {
      case LANDING_PAGE_CONTENT.baselineModuleName:
        return checkAccess(
          permissions,
          ORIGINATION_PROJECT_BASELINE_REPORT_CREATE,
        );
      case LANDING_PAGE_CONTENT.activityModuleName:
        return checkAccess(
          permissions,
          ORIGINATION_PROJECT_ACTIVITY_REPORT_CREATE,
        );
      case LANDING_PAGE_CONTENT.enrollmentModuleName:
        return checkAccess(
          permissions,
          ORIGINATION_PROJECT_ENROLLMENT_REPORT_CREATE,
        );
      default:
        return false;
    }
  };

  const fetchLifeCycleDates = () => {
    axios.get(`${FETCH_PROJECT_LIFECYCLES}/${projectId}`).then((res) => {
      const cycleObject = res.data.filter(
        (item) => +item.projectCycleId === +projectcycleId,
      );
      const startDate = moment(cycleObject[0]?.cycleStartDate).format(
        'MM/DD/YYYY',
      );
      const endDate = moment(cycleObject[0]?.cycleEndDate).format('MM/DD/YYYY');
      if (cycleObject?.length > 0) {
        setCycleYear(`${startDate} - ${endDate}`);
      }
    });
  };

  useEffect(() => {
    fetchPageDetails(
      projectId,
      participantId,
      getEnrollmentType(moduleName).toLocaleUpperCase(),
    );
    fetchLifeCycleDates();
  }, []);

  /**
   * @description function to update the status list based on the enrollment status
   * @param {Boolean} isEnrollmentInReview
   */
  const updateStatusList = (isEnrollmentInReview) => {
    if (isParticipantContributor) {
      setStatusList([
        {
          id: 2,
          status: getFarmStatus(),
          label: MENU_PAGE_CONTENT.farm_enrollment,
          isDisabled: false,
          totalSections: pageDetails.farmDetails.length,
          completedSections: checkTernaryCondition(
            pageDetails.farmDetails[0]?.farmStatus === 'Completed',
            1,
            0,
          ),
          icon: <UpdatedFarmIcon />,
          disabledIcon: <DisabledFarmIcon />,
        },
        {
          id: 3,
          status: getAllFieldsStatus(),
          label: MENU_PAGE_CONTENT.fields_enrollment,
          isDisabled:
            getInProgressField(pageDetails.farmDetails, 'formStatus') ===
              null || pageDetails.farmDetails.length === 0,
          totalSections: pageDetails.farmDetails[0]?.fieldDetails.length || 0,
          completedSections: getFieldSectionCount('Completed'),
          icon: <MapIcon />,
          disabledIcon: <DisabledFieldIcon />,
        },
      ]);
      if (isEnrollmentInReview) {
        setIsSubmitEnabled(true);
        setSubHeading(FINAL_PAGE_CONTENT.REVIEW_CONTENT);
        setMainHeading(FINAL_PAGE_CONTENT.REVIEW_HEADING);
      } else if (pageDetails.enrollmentStatus === 'Enrolled') {
        setMainHeading(
          <MenuOrReviewContent>
            {FINAL_PAGE_CONTENT.FINISHED_PAGE_HEADING}
          </MenuOrReviewContent>,
        );
      }
    } else {
      setStatusList([
        {
          id: 1,
          status: pageDetails.participantProfileStatus,
          label: MENU_PAGE_CONTENT.participant_profile,
          isDisabled: false,
          totalSections: 0,
          completedSections: 0,
          icon: <UpdatedPersonIcon />,
          disabledIcon: null,
        },
        {
          id: 2,
          status: getFarmStatus(),
          label: MENU_PAGE_CONTENT.farm_enrollment,
          isDisabled: pageDetails.farmDetails.length === 0,
          totalSections: pageDetails.farmDetails.length,
          completedSections: checkTernaryCondition(
            pageDetails.farmDetails[0]?.farmStatus === 'Completed',
            1,
            0,
          ),
          icon: <UpdatedFarmIcon />,
          disabledIcon: <DisabledFarmIcon />,
        },
        {
          id: 3,
          status: getAllFieldsStatus(),
          label: MENU_PAGE_CONTENT.fields_enrollment,
          isDisabled:
            getInProgressField(pageDetails.farmDetails, 'formStatus') ===
              null || pageDetails.farmDetails.length === 0,
          totalSections: pageDetails.farmDetails[0]?.fieldDetails.length || 0,
          completedSections: getFieldSectionCount('Completed'),
          icon: <MapIcon />,
          disabledIcon: <DisabledFieldIcon />,
        },
      ]);
      if (isEnrollmentInReview) {
        setIsSubmitEnabled(true);
        setSubHeading(FINAL_PAGE_CONTENT.REVIEW_CONTENT);
        setMainHeading(FINAL_PAGE_CONTENT.REVIEW_HEADING);
      } else if (pageDetails.enrollmentStatus === 'Enrolled') {
        setMainHeading(
          <MenuOrReviewContent>
            {FINAL_PAGE_CONTENT.FINISHED_PAGE_HEADING}
          </MenuOrReviewContent>,
        );
      }
    }
  };

  useEffect(() => {
    if (pageDetails !== null && moduleName === 'enrollment') {
      const isEnrollmentInProgress =
        pageDetails.enrollmentStatus === 'Enrollment In Progress';
      const isFarmOrFieldsNotCompleted =
        getFarmStatus() !== 'Completed' || getAllFieldsStatus() !== 'Completed';
      const isParticipantOrContributor =
        isParticipantOwner || isParticipantContributor;
      const isNotFromLanding = false;
      const isEnrollmentInReview =
        (pageDetails.participantProfileStatus === 'Completed' ||
          isParticipantContributor) &&
        pageDetails.farmDetails?.filter(
          (farm) =>
            farm.farmStatus === 'In Progress' ||
            farm.farmStatus === 'Not Started',
        ).length === 0 &&
        getInProgressField(pageDetails.farmDetails, 'formStatus') !== null &&
        getInProgressField(pageDetails.farmDetails, 'formStatus').isCompleted &&
        pageDetails.enrollmentStatus !== 'Enrolled' &&
        pageDetails?.farmDetails[0]?.fieldDetails?.length > 0;

      setShowWelcomeBackText(
        isEnrollmentInProgress &&
          isFarmOrFieldsNotCompleted &&
          isParticipantOrContributor &&
          isNotFromLanding,
      );
      setFieldTab(getInProgressField(pageDetails.farmDetails, 'formStatus'));
      setSubHeading(
        getSubHeading(
          pageDetails.enrollmentStatus,
          pageDetails.projectName,
          userRoles,
        ),
      );
      updateStatusList(isEnrollmentInReview);
      setDisableStatusOutline(pageDetails?.enrollmentStatus === 'Enrolled');
      setShowDownloadResponse(pageDetails?.enrollmentStatus !== 'Interested');
      setShowSubmitBtn(pageDetails?.enrollmentStatus !== 'Enrolled');
    }
    if (
      pageDetails !== null &&
      moduleName === LANDING_PAGE_CONTENT.baselineModuleName
    ) {
      setBaselinePageDetails();
    }
    if (
      pageDetails !== null &&
      moduleName === LANDING_PAGE_CONTENT.activityModuleName
    ) {
      setActivityPageDetails();
    }
  }, [pageDetails]);

  const setBaselinePageDetails = () => {
    const inProgressField = getInProgressField(
      pageDetails?.farmDetails,
      'mainStatus',
    );
    let isOverallbaselineCompleted = checkTernaryCondition(
      inProgressField,
      inProgressField?.isCompleted,
      false,
    );
    setShowWelcomeBackText(
      pageDetails?.baselineEnrollmentStatus ===
        LANDING_PAGE_CONTENT.ENROLLMENT_PROGRESS_STATUS &&
        (isParticipantOwner || isParticipantContributor) &&
        !isOverallbaselineCompleted &&
        !state?.fromNotLanding,
    );
    setProjectNameHeading(
      MENU_PAGE_CONTENT.baseline_heading.replace(
        '{{project_name}}',
        pageDetails?.projectName || '',
      ),
    );
    setSubHeading(
      getBaselineSubHeading(
        isOverallbaselineCompleted,
        pageDetails?.baselineEnrollmentStatus,
        pageDetails.projectName,
      ),
    );
    setShowDownloadResponse(true);
    setDisableStatusOutline(
      pageDetails?.baselineEnrollmentStatus === 'Enrolled',
    );
    setMainHeading(getBaselineMainHeading(isOverallbaselineCompleted));
    setShowSubmitBtn(
      isOverallbaselineCompleted &&
        (pageDetails.baselineEnrollmentStatus ===
          LANDING_PAGE_CONTENT.ENROLLMENT_PROGRESS_STATUS ||
          pageDetails.baselineEnrollmentStatus === null),
    );
    setIsSubmitEnabled(isOverallbaselineCompleted);
    setStatusList([
      {
        id: 1,
        status: getBaselineStatus(
          pageDetails?.baselineEnrollmentStatus,
          getInProgressField(pageDetails?.farmDetails, 'mainStatus'),
        ),
        label: MENU_PAGE_CONTENT.BASELINE_REPORTING,
        isDisabled: false,
        totalSections: 0,
        completedSections: 0,
        icon: <MapIcon />,
        disabledIcon: null,
      },
    ]);
  };

  const setActivityPageDetails = () => {
    let isOverallActivityCompleted = pageDetails.farmDetails.every(
      (farm) => farm.farmActivityReportingStatus === REPORTING_STATUS.COMPLETED,
    );
    setShowWelcomeBackText(
      pageDetails?.activityReportingStatus ===
        LANDING_PAGE_CONTENT.activityProgressStatus &&
        (isParticipantOwner || isParticipantContributor) &&
        !isOverallActivityCompleted &&
        !state?.fromNotLanding,
    );
    setProjectNameHeading(
      MENU_PAGE_CONTENT.activity_reporting_heading.replace(
        '{{project_name}}',
        pageDetails?.projectName || '',
      ),
    );
    setSubHeading(
      getActivitySubHeading(
        isOverallActivityCompleted,
        pageDetails?.activityReportingStatus,
        pageDetails.projectName,
        participantName,
      ),
    );
    setShowDownloadResponse(true);
    setDisableStatusOutline(
      pageDetails?.activityReportingStatus === 'Enrolled',
    );
    setMainHeading(getActivityMainHeading(isOverallActivityCompleted));
    setShowSubmitBtn(
      isOverallActivityCompleted &&
        (pageDetails.activityReportingStatus ===
          LANDING_PAGE_CONTENT.activityProgressStatus ||
          pageDetails.activityReportingStatus === null),
    );
    setIsSubmitEnabled(isOverallActivityCompleted);
    setStatusList([
      {
        id: 1,
        status: getActivityStatus(
          pageDetails?.activityReportingStatus,
          isOverallActivityCompleted,
        ),
        label: MENU_PAGE_CONTENT.activity_reporting,
        isDisabled: false,
        totalSections: 0,
        completedSections: 0,
        icon: <MapIcon />,
        disabledIcon: null,
      },
    ]);
  };

  const getBaselineMainHeading = (isOverallbaselineCompleted) => {
    if (isOverallbaselineCompleted) {
      return checkTernaryCondition(
        pageDetails?.baselineEnrollmentStatus ===
          LANDING_PAGE_CONTENT.ENROLLMENT_PROGRESS_STATUS,
        FINAL_PAGE_CONTENT.REVIEW_HEADING,

        <MenuOrReviewContent>
          {FINAL_PAGE_CONTENT.FINISHED_PAGE_HEADING}
        </MenuOrReviewContent>,
      );
    }
  };
  const getActivityMainHeading = (isOverallActivityCompleted) => {
    if (isOverallActivityCompleted) {
      return checkTernaryCondition(
        pageDetails?.activityReportingStatus ===
          LANDING_PAGE_CONTENT.activityProgressStatus,
        FINAL_PAGE_CONTENT.REVIEW_HEADING,
        <MenuOrReviewContent>
          {FINAL_PAGE_CONTENT.FINISHED_PAGE_HEADING}
        </MenuOrReviewContent>,
      );
    }
  };
  const getInProgressField = (farmList, key) => {
    let firstField = null;
    for (const farm of farmList) {
      let fieldList = farm.fieldDetails;

      // Note: key is "formStatus" for Enrollment and "mainStatus" for Baseline, we are filtering only in enrollment flow
      if (key === 'formStatus') {
        fieldList = fieldList.filter(
          (field) =>
            field.enrollmentInstanceID === Number(enrollmentInstanceId),
        );
      }

      for (const field of fieldList) {
        if (firstField === null) {
          firstField = field;
        }
        if (
          field[key] === 'In Progress' ||
          field[key] === null ||
          field[key] === 'Not Started'
        ) {
          return { ...field, isCompleted: false };
        }
      }
    }

    return checkTernaryCondition(
      firstField !== null,
      { ...firstField, isCompleted: true },
      null,
    );
  };

  const getFarmStatus = () => {
    if (pageDetails.farmDetails.length > 0) {
      return checkTernaryCondition(
        pageDetails.farmDetails.filter(
          (farm) =>
            farm.farmStatus?.toLowerCase() ===
            LANDING_PAGE_CONTENT.in_progress.toLowerCase(),
        ).length > 0,
        LANDING_PAGE_CONTENT.in_progress,
        checkTernaryCondition(
          pageDetails.farmDetails.every(
            (farm) =>
              farm.farmStatus?.toLowerCase() ===
              LANDING_PAGE_CONTENT.not_started.toLowerCase(),
          ),
          LANDING_PAGE_CONTENT.not_started,
          checkTernaryCondition(
            pageDetails.farmDetails.every(
              (farm) =>
                farm.farmStatus?.toLowerCase() ===
                LANDING_PAGE_CONTENT.completed.toLowerCase(),
            ),
            LANDING_PAGE_CONTENT.completed,
            LANDING_PAGE_CONTENT.in_progress,
          ),
        ),
      );
    } else {
      return null;
    }
  };

  const getFieldSectionCount = (status) => {
    return pageDetails.farmDetails[0]?.fieldDetails.filter(
      (detail) => detail.formStatus === status,
    ).length;
  };

  const getAllFieldsStatus = () => {
    if (
      getInProgressField(pageDetails.farmDetails, 'formStatus') === null ||
      pageDetails.farmDetails.length === 0
    ) {
      return null;
    } else {
      return checkTernaryCondition(
        !getInProgressField(pageDetails.farmDetails, 'formStatus').isCompleted,
        'In Progress',
        'Completed',
      );
    }
  };

  const downloadPDFHandler = () => {
    let API_URL = getDownloadHandlerApiUrl(moduleName);
    API_URL = API_URL.replace(':participnatId', participantId);
    if (API_URL.includes('baseline') || API_URL.includes('activity')) {
      API_URL = API_URL.replace(':projectId', projectId);
    }
    setDownloadLoading(true);
    axios
      .get(API_URL)
      .then((response) => {
        openPDFFromLink(response.data.reportLink);
      })
      .catch(() => {
        displayToast('error', `${MENU_PAGE_CONTENT.pdf_download_failed}`);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const navigateHandler = (id, isFieldClicked) => {
    if (
      pageDetails?.enrollmentStatus !== 'Enrolled' &&
      moduleName === LANDING_PAGE_CONTENT.enrollmentModuleName
    ) {
      navigate(
        `${pathName.participant.participantDataCollection}?projectid=${projectId}&participantid=${participantId}&enrollmentInstanceId=${enrollmentInstanceId}&projectcycleid=${projectcycleId}`,
        {
          state: {
            id: checkTernaryCondition(isFieldClicked, 2, id),
            projectId: projectId,
            projectName: pageDetails.projectName,
            participantId: participantId,
            participantFirstName: pageDetails.participantFirstName,
            participantLastName: pageDetails.participantLastName,
            participantEmail: pageDetails.participantEmail,
            fieldData: checkTernaryCondition(
              isFieldClicked,
              {
                farmId: fieldTab?.farmId,
                ...fieldTab,
              },
              null,
            ),
          },
        },
      );
    } else if (
      pageDetails?.baselineEnrollmentStatus !== 'Enrolled' &&
      moduleName === LANDING_PAGE_CONTENT.baselineModuleName
    ) {
      navigate(
        `${pathName.participant.participantFieldBaseline}?projectid=${projectId}&participantid=${participantId}&projectcycleid=${projectcycleId}`,
      );
    } else if (
      pageDetails?.activityReportingStatus !== 'Enrolled' &&
      moduleName === LANDING_PAGE_CONTENT.activityModuleName
    ) {
      navigate(
        `${pathName.participant.participantFieldActivity}?projectid=${projectId}&participantid=${participantId}&projectcycleid=${projectcycleId}`,
      );
    }
  };

  const navigateLandingPageHandler = () => {
    navigate(-1);
  };

  const navigateActivityReportingPageHandler = () => {
    window.open(
      `${pathName.originationParticipants.view}/${participantId}?projectid=${projectId}&tab=Activity`,
    );
    window.close();
  };

  const getEnrollmentIdsForBaseline = () => {
    const enrollmentIds = new Set();
    for (const farm of pageDetails.farmDetails) {
      if (
        farm.enrolmentStatus === 'APPROVED' &&
        (farm.baselineStatus === 'NOT_APPROVED' ||
          farm.baselineStatus === 'IN_PROGRESS')
      )
        enrollmentIds.add(farm.enrollmentInstanceID);
      for (const field of farm.fieldDetails) {
        if (
          field.enrolmentStatus === 'APPROVED' &&
          (field.baselineStatus === 'NOT_APPROVED' ||
            field.baselineStatus === 'IN_PROGRESS')
        )
          enrollmentIds.add(field.enrollmentInstanceID);
      }
    }
    return Array.from(enrollmentIds);
  };
  const getEnrollmentIdsForActivity = () => {
    const enrollmentIds = new Set();
    for (const farm of pageDetails.farmDetails) {
      if (
        farm.enrolmentStatus === 'APPROVED' &&
        farm.baselineStatus === 'APPROVED' &&
        (farm.activityReportingStatus === 'NOT_APPROVED' ||
          farm.activityReportingStatus === 'IN_PROGRESS')
      )
        enrollmentIds.add(farm.enrollmentInstanceID);
      for (const field of farm.fieldDetails) {
        if (
          field.enrolmentStatus === 'APPROVED' &&
          field.baselineStatus === 'APPROVED' &&
          (field.activityReportingStatus === 'NOT_APPROVED' ||
            field.activityReportingStatus === 'IN_PROGRESS')
        )
          enrollmentIds.add(field.enrollmentInstanceID);
      }
    }
    return Array.from(enrollmentIds);
  };
  const getEnrollmentIds = () => {
    switch (moduleName) {
      case LANDING_PAGE_CONTENT.enrollmentModuleName:
        return [];
      case LANDING_PAGE_CONTENT.baselineModuleName:
        return getEnrollmentIdsForBaseline();
      case LANDING_PAGE_CONTENT.activityModuleName:
        return getEnrollmentIdsForActivity();
      default:
        return [];
    }
  };

  const getModuleName = () => {
    switch (moduleName) {
      case LANDING_PAGE_CONTENT.enrollmentModuleName:
        return LANDING_PAGE_CONTENT.enrollmentModuleName;
      case LANDING_PAGE_CONTENT.baselineModuleName:
        return `${LANDING_PAGE_CONTENT.baselineModuleName} ${LANDING_PAGE_CONTENT.reporting}`;
      case LANDING_PAGE_CONTENT.activityModuleName:
        return `${LANDING_PAGE_CONTENT.activityModuleName} ${LANDING_PAGE_CONTENT.reporting}`;
      default:
        return '';
    }
  };

  const hereOnCLickHandler = () => {
    navigateHandler(
      statusList[0].id,
      statusList[0].status.label === MENU_PAGE_CONTENT.fields_enrollment,
    );
  };

  const getSubmitOnclickHandler = () => {
    const enrollmentIds = getEnrollmentIds();
    const enrollmentType = getEnrollmentType(moduleName);
    finishEnrollmentHandler(
      participantId,
      projectId,
      enrollmentType,
      enrollmentIds,
      parseInt(enrollmentInstanceId),
      getEnrollmentType(moduleName).toLocaleUpperCase(),
      projectcycleId,
    );
  };
  const participantName = `${pageDetails?.participantFirstName} ${pageDetails?.participantLastName}'s`;
  const almostDonePageForOwnerAndContributor = () => (
    <ParentContainer
      height={checkTernaryCondition(
        isParticipantContributor,
        undefined,
        '736px',
      )}>
      <AlmostDonePageWrapper
        marginTop={checkTernaryCondition(
          isParticipantContributor,
          '0rem',
          '3.5rem',
        )}
        height={checkTernaryCondition(
          isParticipantContributor,
          undefined,
          '100vh',
        )}>
        <LeftArrowButton>
          <LeftArrowIcon />
          <ReturnToHomeLink onClick={navigateLandingPageHandler}>
            {MENU_PAGE_CONTENT.BACK}
          </ReturnToHomeLink>
        </LeftArrowButton>

        {!showWelcomeBackText && (
          <MenuOrReviewHeading
            fontWeight={400}
            fontSize={checkTernaryCondition(
              isAlmostDonePage,
              '4rem',
              undefined,
            )}>
            {mainHeading}
          </MenuOrReviewHeading>
        )}
        {checkTernaryCondition(
          isParticipantContributor,
          <MenuOrReviewSubHeading1 customFontSize={'1.25rem'}>
            {`${
              FINAL_PAGE_CONTENT.LANDING_PAGE_SUB_HEADING_TWO
            } ${FINAL_PAGE_CONTENT.LANDING_PAGE_SUB_HEADING_THREE.replace(
              '{{moduleName}}',
              getModuleName(),
            )
              .replace('{{projectName}}', pageDetails?.projectName)
              .replace('{{projectCycle}}', cycleYear)
              .replace('{{participantName}}', participantName)} `}
            <StyledSpan onClick={hereOnCLickHandler}>here.</StyledSpan>
          </MenuOrReviewSubHeading1>,
          <MenuOrReviewSubHeading1 customFontSize={'1.25rem'}>
            {`${FINAL_PAGE_CONTENT.LANDING_PAGE_SUB_HEADING_THREE.replace(
              '{{moduleName}}',
              getModuleName(),
            )
              .replace('{{projectName}}', pageDetails?.projectName)
              .replace('{{projectCycle}}', cycleYear)
              .replace('{{participantName}}', participantName)} `}
            <StyledSpan onClick={hereOnCLickHandler}>here.</StyledSpan>
            {` ${LANDING_PAGE_CONTENT.confirmMessage} ${LANDING_PAGE_CONTENT.below}`}
          </MenuOrReviewSubHeading1>,
        )}

        {showDownloadResponse && (
          <>
            <DownloadResponseWrapper
              disabled={initialHomePageCondition}
              onClick={downloadPDFHandler}>
              {checkTernaryCondition(
                initialHomePageCondition,
                <DisabledDownloadIcon
                  style={{ height: '1rem', width: '1rem' }}
                />,
                <DownloadIcon />,
              )}
              <DownloadResponseText>
                {MENU_PAGE_CONTENT.download_reponse_label}
              </DownloadResponseText>
            </DownloadResponseWrapper>

            {showSubmitBtn && !isParticipantContributor && (
              <>
                {isSubmitEnabled && (
                  <>
                    <TermsOfAttestation
                      setTermsAndConditionsFullyRead={
                        setIsTermsAndConditionsFullyRead
                      }
                    />
                    <div className="projectid_checkfield">
                      <Checkbox
                        onChange={(e) => setAttestationCheck(e.target.checked)}
                        name="attestation"
                        inputProps={{ 'aria-label': 'registry_id_checkbox' }}
                        color="success"
                        className="projectid_checkbox"
                        checked={attestationCheck}
                        disabled={!isTermsAndConditionsFullyRead}
                      />
                      <AttestationCheckBoxLabel>
                        Please confirm you&apos;ve read our terms of attestation
                        to submit enrollment.
                      </AttestationCheckBoxLabel>
                    </div>
                  </>
                )}
                <Button
                  variant={MENU_PAGE_CONTENT.contained}
                  size={MENU_PAGE_CONTENT.medium}
                  color={MENU_PAGE_CONTENT.success}
                  sx={[buttonStyleSx, primaryButtonStyle('0.78rem 0.875rem')]}
                  disabled={
                    !(
                      isSubmitEnabled &&
                      attestationCheck &&
                      !isParticipantContributor
                    )
                  }
                  onClick={getSubmitOnclickHandler}
                  disableElevation>
                  {MENU_PAGE_CONTENT.submit}
                </Button>
              </>
            )}
          </>
        )}
      </AlmostDonePageWrapper>
    </ParentContainer>
  );

  /**
   * Determines the style object based on the current page and participant type.
   *
   * This function returns a style object with specific properties depending on
   * whether the current page is the "Almost Done" page and the type of participant
   * (Contributor or Owner). If the page is not the "Almost Done" page, it returns
   * an empty style object.
   *
   * @returns {Object} The style object containing CSS properties.
   */
  const getMainContainerStyles = () => {
    if (!isAlmostDonePage) {
      return {};
    }
    if (isParticipantContributor) {
      return {
        minHeight: 'calc(100vh - 53px)',
        marginTop: 0,
        marginBottom: 0,
      };
    }
    if (isParticipantOwner) {
      return {
        minHeight: 'calc(100vh + 100px)',
      };
    }
    return {};
  };

  const CongratsPage = () => {
    return (
      (pageDetails?.enrollmentStatus === 'Enrolled' &&
        moduleName === 'enrollment') ||
      (pageDetails?.baselineEnrollmentStatus === 'Enrolled' &&
        moduleName === 'baseline') ||
      (pageDetails?.activityReportingStatus === 'Enrolled' &&
        moduleName === 'activity')
    );
  };

  return (
    <MainWrapper {...getMainContainerStyles()}>
      {checkTernaryCondition(
        loading || pageDetails === null || downloadLoading,
        <Backdrop
          open={true}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="success" />
        </Backdrop>,
        checkTernaryCondition(
          isAlmostDonePage,
          almostDonePageForOwnerAndContributor(),
          checkTernaryCondition(
            CongratsPage(),
            <MenuOrReviewPageWrapper
              marginTop={checkTernaryCondition(
                showWelcomeBackText ||
                  (typeof mainHeading === 'string' &&
                    !isValueEmptyNullorUndefined(mainHeading)),
                '5rem',
                '7.5rem',
              )}
              width={'600px'}
              height={'267px'}>
              {
                <LeftArrowButton style={{ cursor: 'pointer' }}>
                  <LeftArrowIcon className="left-arrow-icon"></LeftArrowIcon>
                  <ReturnToHomeLink
                    onClick={navigateActivityReportingPageHandler}>
                    {MENU_PAGE_CONTENT.RETURN_TO_PARTICIPANT_ACTIVITY}
                  </ReturnToHomeLink>
                </LeftArrowButton>
              }
              {!showWelcomeBackText && (
                <MenuOrReviewHeading
                  fontSize={checkTernaryCondition(
                    isAlmostDonePage,
                    '4rem',
                    undefined,
                  )}>
                  {mainHeading}
                </MenuOrReviewHeading>
              )}
              <MenuOrReviewSubHeading1 customFontSize={'1.25rem'}>
                {FINAL_PAGE_CONTENT.CONGRATS_PAGE_SUB_HEADING.replace(
                  '{{participantName}}',
                  `${pageDetails?.participantFirstName} ${pageDetails?.participantLastName}`,
                )}
                <StyledSpan onClick={navigateActivityReportingPageHandler}>
                  here.
                </StyledSpan>
              </MenuOrReviewSubHeading1>
              {pageDetails?.enrollmentStatus === 'Interested' &&
                moduleName !== LANDING_PAGE_CONTENT.baselineModuleName && (
                  <MenuFooter>{MENU_PAGE_CONTENT.section_footer}</MenuFooter>
                )}
              {showDownloadResponse && (
                <DownloadResponseWrapper
                  disabled={
                    !hasReportGenerateAccess() || initialHomePageCondition
                  }
                  onClick={checkTernaryCondition(
                    hasReportGenerateAccess(),
                    downloadPDFHandler,
                    {},
                  )}>
                  {checkTernaryCondition(
                    initialHomePageCondition || !hasReportGenerateAccess(),
                    <DisabledDownloadIcon
                      style={{ height: '1rem', width: '1rem' }}
                    />,
                    <DownloadIcon />,
                  )}
                  <DownloadResponseText>
                    {MENU_PAGE_CONTENT.download_reponse_label}
                  </DownloadResponseText>
                </DownloadResponseWrapper>
              )}
            </MenuOrReviewPageWrapper>,
            <MenuOrReviewPageWrapper
              marginTop={checkTernaryCondition(
                showWelcomeBackText ||
                  (typeof mainHeading === 'string' &&
                    !isValueEmptyNullorUndefined(mainHeading)),
                '5rem',
                '7.5rem',
              )}>
              {showWelcomeBackText && (
                <MenuOrReviewHeading>
                  {MENU_PAGE_CONTENT.welcome_back_label}
                  {participantFirstName}
                </MenuOrReviewHeading>
              )}
              {isEmailLinkClicked && !showWelcomeBackText && mainHeading && (
                <LeftArrowButton style={{ cursor: 'pointer' }}>
                  <LeftArrowIcon className="left-arrow-icon"></LeftArrowIcon>
                  <ReturnToHomeLink onClick={navigateLandingPageHandler}>
                    {MENU_PAGE_CONTENT.return_to_home_text}
                  </ReturnToHomeLink>
                </LeftArrowButton>
              )}
              {!showWelcomeBackText && (
                <MenuOrReviewHeading
                  fontSize={checkTernaryCondition(
                    isAlmostDonePage,
                    '4rem',
                    undefined,
                  )}>
                  {mainHeading}
                </MenuOrReviewHeading>
              )}
              {getProjectNameHeading(
                moduleName,
                pageDetails?.enrollmentStatus,
                pageDetails?.baselineEnrollmentStatus,
                pageDetails?.activityReportingStatus,
                pageDetails?.projectName,
                projectNameHeading,
              )}
              <MenuOrReviewSubHeading>{subHeading}</MenuOrReviewSubHeading>
              <MenuOrReviewContentWrapper
                marginTop={getMarginTop(moduleName)}
                isFinalPage={true}>
                {statusList.map((status) => (
                  <StatusBox
                    key={status.id}
                    disabled={status.isDisabled}
                    statusLabel={status.label}
                    status={status.status}
                    icon={status.icon}
                    disableOutline={disableStatusOutline}
                    onClickHandler={() =>
                      navigateHandler(
                        status.id,
                        status.label === MENU_PAGE_CONTENT.fields_enrollment,
                      )
                    }
                    isBaselineMenu={
                      moduleName === LANDING_PAGE_CONTENT.baselineModuleName ||
                      moduleName === LANDING_PAGE_CONTENT.activityModuleName
                    }
                    disabledIcon={status.disabledIcon}
                  />
                ))}
              </MenuOrReviewContentWrapper>
              {pageDetails?.enrollmentStatus === 'Interested' &&
                moduleName !== LANDING_PAGE_CONTENT.baselineModuleName && (
                  <MenuFooter>{MENU_PAGE_CONTENT.section_footer}</MenuFooter>
                )}
              {showDownloadResponse && (
                <>
                  <DownloadResponseWrapper
                    disabled={
                      !hasReportGenerateAccess() || initialHomePageCondition
                    }
                    onClick={checkTernaryCondition(
                      hasReportGenerateAccess(),
                      downloadPDFHandler,
                      {},
                    )}>
                    {checkTernaryCondition(
                      initialHomePageCondition || !hasReportGenerateAccess(),
                      <DisabledDownloadIcon
                        style={{ height: '1rem', width: '1rem' }}
                      />,
                      <DownloadIcon />,
                    )}
                    <DownloadResponseText>
                      {MENU_PAGE_CONTENT.download_reponse_label}
                    </DownloadResponseText>
                  </DownloadResponseWrapper>
                  {showSubmitBtn && (
                    <>
                      {isSubmitEnabled && (
                        <>
                          <TermsOfAttestation
                            setTermsAndConditionsFullyRead={
                              setIsTermsAndConditionsFullyRead
                            }
                          />
                          <div className="projectid_checkfield">
                            <Checkbox
                              onChange={(e) =>
                                setAttestationCheck(e.target.checked)
                              }
                              name="attestation"
                              inputProps={{
                                'aria-label': 'registry_id_checkbox',
                              }}
                              color="success"
                              className="projectid_checkbox"
                              checked={attestationCheck}
                              disabled={!isTermsAndConditionsFullyRead}
                            />
                            <AttestationCheckBoxLabel>
                              Please confirm you&apos;ve read our terms of
                              attestation to submit enrollment.
                            </AttestationCheckBoxLabel>
                          </div>
                        </>
                      )}
                      <Button
                        variant={MENU_PAGE_CONTENT.contained}
                        size={MENU_PAGE_CONTENT.medium}
                        color={MENU_PAGE_CONTENT.success}
                        sx={[
                          buttonStyleSx,
                          primaryButtonStyle('0.78rem 0.875rem'),
                        ]}
                        disabled={
                          !(
                            isSubmitEnabled &&
                            attestationCheck &&
                            !isParticipantContributor
                          )
                        }
                        onClick={getSubmitOnclickHandler}
                        disableElevation>
                        {getMenuPageSubmitButtonLabel(moduleName)}
                      </Button>
                    </>
                  )}
                </>
              )}
            </MenuOrReviewPageWrapper>,
          ),
        ),
      )}
    </MainWrapper>
  );
};

MenuPage.propTypes = {
  moduleName: PropTypes.string,
};

export default MenuPage;
