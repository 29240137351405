import styled from 'styled-components';
import {
  BLUE,
  COOL_GRAY,
  DARK_CHARCOAL,
  DOWNLOAD_LINK,
  GLOBAL_BLUE,
  TABS_HIGHLIGHT_BORDER,
  WHITE,
} from 'theme/GlobalColors';
import { Link } from 'react-router-dom';

export const MainWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0rem;
  position: relative;
`;
export const CustomBreadCrumbLink = styled(Link)`
  color: ${GLOBAL_BLUE};
  text-align: left;
  font: 400 14px/20px 'OpenSans-Regular', sans-serif;
  position: relative;
  text-decoration: none;
  display: flex;
`;
export const CustomBreadCrumbItem = styled.div`
  color: ${COOL_GRAY};
  text-align: left;
  font-size: 14px;
  position: relative;
  display: flex;
`;
export const ProjectNameWrapper = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #313233;
  display: flex;
  grid-column-gap: 0.5rem;
`;
export const DividerStyles = {
  position: 'absolute',
  left: '0',
  right: '0',
  top: '2.5rem',
  height: '5px',
};
export const ProjectName = styled.div`
  color: ${DARK_CHARCOAL};
  text-align: left;
  font: 400 20px/140% 'OpenSans-Regular', sans-serif;
  margin: 0.5rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  text-wrap: nowrap;
`;
export const ParentContainer = styled.div`
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  min-height: calc(100vh - 125px);
  margin-top: 2px;
`;
export const LeftSectionWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  background-color: white;
  border-right: solid 1px #d0d0ce;
  min-height: 46rem;
`;
export const MainContainer = styled.div`
  display: flex;
  padding: ${({ padding }) => padding};
  flex-direction: ${({ direction }) => direction || 'column'};
  background-color: white;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
`;
export const TabberBoxCustomStyle = {
  position: 'sticky',
  top: 0,
  zIndex: 100,
  backgroundColor: WHITE,
  marginBottom: '0.5rem',
};
export const TabsStyle = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
`;

export const TabStylesSx = {
  textTransform: 'none',
  width: 'unset',
  '&.Mui-selected': {
    borderBottom: `3px solid ${TABS_HIGHLIGHT_BORDER}`,
  },
  '&.MuiTabs-indicator': {
    backgroundColor: TABS_HIGHLIGHT_BORDER,
    borderBottom: `3px solid ${TABS_HIGHLIGHT_BORDER}`,
  },
  '&.MuiTab-root.Mui-disabled': {
    color: DOWNLOAD_LINK,
  },
};

export const backButtonStyle = {
  padding: 0,
  color: BLUE,
  textTransform: 'none',
  fontSize: '0.875rem',
  fontWeight: 400,
  minWidth: 'unset',
};

export const projectNameStyle = {
  padding: '0rem 2rem',
};
