import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  AcresError,
  ActivityFlexItems,
  ActivityItemData,
  ActivityTitle,
  EdgeOfFieldError,
  InputWithDeleteWrapper,
  JumpLink,
  NoteSection,
  NoteTag,
  PredefinedUnit,
  Subtitle,
  TextWrapper,
  Title,
  deleteIconStyles,
  inputStyleProps,
  saveBtnSx,
} from './ImplementedActivitiesV2.style';
import {
  JumpToOpsData,
  PAGE_CONTENT,
  residueTillageNote,
} from './ImplementedActivitiesV2.content';
import MenuOptionsButton from 'components/Buttons/MenuOptionsButton/MenuOptionsButton.react';
import AddIcon from '@mui/icons-material/Add';
import { useReportingApis } from 'hooks/useReportingApis';
import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  formatActivityAcreageData,
  getActivityError,
  getLargestCropAreaWithSoilAmendment,
} from './ImplementedActivitiesV2.functions';
import {
  checkTernaryCondition,
  findObjectWithKey,
  isEmpty,
} from 'utils/helper';
import { ReactComponent as DeleteIcon } from 'assets/icons/OutlinedDeleteWIthHoverEffect.svg';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import { projectActivitiesMapping } from '../../ActivityReporting.content';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { uniqueId } from 'utils/uniqueIdGenerator';
import ActivityDeleteModal from 'pages/ParticipantDataCollection/components/ActivityDeleteModal/ActivityDeleteModal.react';
import {
  ACTIVITY_CATEGORY,
  INPUT_CATEGORY,
  GLOBAL_STRING_CONSTANTS,
  ERROR_MSGS,
} from 'utils/config';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import {
  ACTUAL_TOTAL_ACTIVITY_AREA,
  ACTIVITY_PRACTICES,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';
import { pathName } from 'Routes/routes.const';
import { disableIconStyle } from 'theme/GlobalStyles';
import EdgeOfFieldCalcModal from 'pages/ParticipantDataCollection/components/EdgeOfFieldCalcModal/EdgeOfFieldCalcModal.react';
import { MODAL_CONTENT } from 'pages/ParticipantDataCollection/components/EdgeOfFieldCalcModal/EdgeOfFieldCalcModal.content';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';

const ImplementedActivitiesV2 = ({
  currentFieldId,
  currentFarmId,
  currentYearData,
  disableAllFieldActions = false,
  refreshStatusHandler = () => null,
  makeFormDirty = () => null,
  setUserActivityDetected = () => null,
  shouldNavigateToLandingPage,
  isEditable = true,
}) => {
  const { fetchActivityAcreage, fetchProjectActivities, fetchActivitiesList } =
    useReportingApis();
  const {
    fieldArea,
    largestCoverCropArea,
    cropAreasWithSoilAmendments,
    activityAcreageList,
    setActivityAcreageList,
    activityList,
    setActivityList,
    fetchEdgeOfFieldSpeciesTypeDropDown,
    startApiProgress,
    endApiProgress,
    fetchSoilAmendmentsList,
    setDeletedActivities,
    isFieldDisabled,
    saveDataHandler,
    activityDeleteModalProps,
    handleActivityDelete,
    closeActivityDeleteModal,
    updateImplementedActivities,
    fetchBaseLineFieldData,
    totalOperationCropArea,
    isInBaselineYear,
    handleDeleteFromOpsData,
    tillageDataInOps,
  } = useContext(implementedActivitiesWithOpsDataContext);
  const {
    unitOfMeasureList: { implementedActivities },
  } = useContext(uomContext);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isEOFModalOpen, setIsEOFModalOpen] = useState(false);

  const addNewActivity = (option) => {
    makeFormDirty();
    // Note: Populating field area directly for "Residue + tillage management" activity category(reduced till / No till)
    const newActivityArea = checkTernaryCondition(
      option.activityCategory === ACTIVITY_CATEGORY.RESIDUE_PLUS_TILLAGE,
      fieldArea,
      '',
    );
    const newActivity = {
      // Unique key to identify each activity(just for UI purpose -> key for mapping)
      uniqueKey: uniqueId(),
      fieldProjectActivityId: 0,
      activityId: option.projectActivityId,
      area: newActivityArea,
      activityCategory: option.activityCategory,
      projectActivityType: option.projectActivityType,
      label: option.label,
      edgeOfFieldSampleId: 0,
      // Note: Edge of field related fields
      speciesCategory: '',
      stripWidth: '',
      barrierWidth: '',
      rowsCount: '',
      speciesDensity: '',
      // Errors
      acresError: '',
      acresRequiredError: false,
    };

    setActivityAcreageList((prevActivityAcreageList) => [
      ...prevActivityAcreageList,
      newActivity,
    ]);
  };

  const handleActivityAcresUpdate = (
    activityId,
    activityCategory,
    projectActivityType,
    value,
  ) => {
    const decimalNumberRegex = /^\d{0,10}(\.\d{0,5})?$/;

    if (decimalNumberRegex.test(value)) {
      const largestCropAreaWithSoilAmendment =
        getLargestCropAreaWithSoilAmendment(
          cropAreasWithSoilAmendments,
          projectActivityType,
        );

      const area = checkTernaryCondition(value === '.', '0.', value);
      const error = getActivityError({
        activityCategory: activityCategory,
        activityAcres: value,
        fieldArea: fieldArea,
        largestCoverCropArea: largestCoverCropArea,
        largestCropAreaWithSoilAmendment: largestCropAreaWithSoilAmendment,
        totalOperationCropArea,
      });

      setActivityAcreageList((prevActivityAcreageList) => {
        return prevActivityAcreageList.map((activity) => {
          if (activity.activityId === activityId) {
            return {
              ...activity,
              area: area,
              acresError: error,
              acresRequiredError: value === '',
            };
          }

          return activity;
        });
      });
    }
  };

  const fetchPageData = () => {
    startApiProgress();
    fetchActivityAcreage(
      currentFieldId,
      currentYearData?.sampleId,
      currentYearData?.projectCycleId,
    )
      .then((acreageList) => {
        setActivityAcreageList(
          formatActivityAcreageData(
            acreageList,
            fieldArea,
            largestCoverCropArea,
            cropAreasWithSoilAmendments,
          ),
        );
        setDeletedActivities([]);
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(endApiProgress);
  };

  const handleSave = (event) => {
    event.target.blur();
    saveDataHandler()
      .then(() => {
        updateImplementedActivities();
        fetchBaseLineFieldData(currentYearData?.yearValue);
        refreshStatusHandler();
      })
      .finally(() => {
        setUserActivityDetected(false);
      });
  };

  useEffect(() => {
    if (
      currentFarmId &&
      currentFieldId &&
      currentYearData.sampleId &&
      currentYearData.projectCycleId
    ) {
      fetchPageData();
    }
  }, [
    currentFarmId,
    currentFieldId,
    currentYearData.sampleId,
    currentYearData.projectCycleId,
  ]);

  useEffect(() => {
    startApiProgress();
    const fetchActivities = checkTernaryCondition(
      pathname?.includes(pathName.participant.participantFieldBaseline),
      () => fetchActivitiesList(),
      () => fetchProjectActivities(projectId),
    );
    fetchActivities()
      .then((activities) =>
        setActivityList(
          activities.map((activity) => ({
            ...activity,
            activityCategory:
              projectActivitiesMapping[activity.activityCategory],
          })),
        ),
      )
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(endApiProgress);
  }, [pathname]);

  useEffect(() => {
    fetchEdgeOfFieldSpeciesTypeDropDown();
    fetchSoilAmendmentsList();
  }, []);

  const filteredActivityDropdownList = useMemo(() => {
    const populatedActivityIds = activityAcreageList?.map(
      (activity) => activity.activityId,
    );

    const residueTillageActivitySelected = activityAcreageList?.some(
      (activity) =>
        activity.activityCategory === ACTIVITY_CATEGORY.RESIDUE_PLUS_TILLAGE,
    );

    const activities = activityList?.filter((activity) => {
      // If one of the residue + tillage management activity(reduced till / no till) is already selected, then don't show the other activity.
      if (
        residueTillageActivitySelected &&
        activity.activityCategory === ACTIVITY_CATEGORY.RESIDUE_PLUS_TILLAGE
      ) {
        return false;
      }

      // Filter based on operations data tillage selection
      if (
        tillageDataInOps &&
        activity.activityCategory === ACTIVITY_CATEGORY.RESIDUE_PLUS_TILLAGE
      ) {
        // If "No tillage" is selected in operations data, then show only "No tillage" activity in the dropdown, but if anything else is selected in operations data, then show only "Reduced tillage" activity in the dropdown
        if (activity.projectActivityType === ACTIVITY_PRACTICES.NO_TILLAGE) {
          return tillageDataInOps === ACTIVITY_PRACTICES.NO_TILLAGE;
        }

        return tillageDataInOps !== ACTIVITY_PRACTICES.NO_TILLAGE;
      }

      return !populatedActivityIds?.includes(activity.projectActivityId);
    });

    return activities.map((activity) => {
      let label = activity.activityCategory;
      // Note: Since 'Cover crop' and 'Nutrient management' have both category and activity as same name, we are not showing both category and activity name in the UI
      if (
        label !== ACTIVITY_CATEGORY.NUTRIENT_MANAGEMENT &&
        label !== ACTIVITY_CATEGORY.COVER_CROP
      ) {
        label += `: ${activity.projectActivityType}`;
      }
      return {
        id: activity.projectActivityId,
        projectActivityId: activity.projectActivityId,
        activityCategory: activity.activityCategory,
        projectActivityType: activity.projectActivityType,
        label: label,
      };
    });
  }, [activityAcreageList, activityList, tillageDataInOps]);

  /**
   * @description function to render input field with delete icon
   * @param {Object} activityData
   * @param {String} marginTop
   * @param {Boolean} isDisabled
   * @param {Boolean} showErrorIcon
   * @param {Boolean} showEdgeOfFieldError
   * @param {Boolean} isFieldDisabled
   * @returns {Element} Input field with delete icon
   */
  const getInputWithDelete = (
    activityData,
    marginTop = null,
    isDisabled = false,
    showErrorIcon = false,
    showEdgeOfFieldError = false,
    isFieldDisabled = false,
    deleteButtonStyles = deleteIconStyles,
  ) => {
    return (
      <div>
        <InputWithDeleteWrapper marginTop={marginTop}>
          <InputField
            category={INPUT_CATEGORY.LONG_NUMBER}
            testId={activityData.label}
            isDisabled={isDisabled || disableAllFieldActions || !isEditable} // Disable input field for Residue + tillage
            value={activityData.area}
            predefinedUnit={
              <PredefinedUnit>
                {
                  findObjectWithKey(
                    implementedActivities,
                    ACTUAL_TOTAL_ACTIVITY_AREA,
                  )?.ACTUAL_TOTAL_ACTIVITY_AREA[0]?.uomNameDisplay
                }
                {showErrorIcon && <AlertIcon />}
              </PredefinedUnit>
            }
            onUpdate={(event) => {
              handleActivityAcresUpdate(
                activityData.activityId,
                activityData.activityCategory,
                activityData.projectActivityType,
                event.target.value,
              );
              makeFormDirty();
            }}
            {...inputStyleProps}
          />
          {/* Note: handling error layout differently for edge of field since it has different structure */}

          {!isFieldDisabled && !disableAllFieldActions && (
            <DeleteIcon
              onClick={() => {
                handleActivityDelete(activityData);
                makeFormDirty();
              }}
              style={{
                ...deleteButtonStyles,
                ...disableIconStyle(!isEditable),
              }}
              data-testid="delete-icon"
            />
          )}
        </InputWithDeleteWrapper>
        {showEdgeOfFieldError && (
          <EdgeOfFieldError>{activityData.acresError}</EdgeOfFieldError>
        )}
      </div>
    );
  };

  /**
   * @description function to render activity item data
   * @param {Object} activityData
   * @returns {Element} Activity item data
   */
  const getActivityItemData = (activityData) => {
    const isResidueTillage =
      activityData.activityCategory === ACTIVITY_CATEGORY.RESIDUE_PLUS_TILLAGE;
    const isCoverCrop =
      activityData.activityCategory === ACTIVITY_CATEGORY.COVER_CROP;
    const isSoilAmendment =
      activityData.activityCategory === ACTIVITY_CATEGORY.SOIL_CARBON_AMENDMENT;
    // Note: Edge of field related flags
    const isEdgeOfField =
      activityData.activityCategory === ACTIVITY_CATEGORY.EDGE_OF_FIELD;

    // Note: Error handling for acres field
    const acresFieldHasError = activityData.acresError?.length > 0;
    const showErrorIcon = acresFieldHasError || activityData.acresRequiredError;
    const disableDeleteInput = isResidueTillage || isFieldDisabled;

    return (
      <ActivityItemData key={activityData.uniqueKey}>
        <ActivityFlexItems>
          <ActivityTitle>{activityData.label}</ActivityTitle>
          {!isFieldDisabled && !disableAllFieldActions && (
            <JumpToOpsData category={activityData.activityCategory} />
          )}
          {isEdgeOfField && (
            <JumpLink
              onClick={() => {
                setIsEOFModalOpen(true);
              }}>
              {MODAL_CONTENT.LABEL}
            </JumpLink>
          )}
        </ActivityFlexItems>
        {isResidueTillage && (
          <NoteSection>
            <NoteTag>Note: </NoteTag>
            {residueTillageNote(activityData.projectActivityType)}
          </NoteSection>
        )}
        {isCoverCrop && !isInBaselineYear && (
          <NoteSection>
            <NoteTag>Note: </NoteTag>
            {PAGE_CONTENT.coverCropNote}
          </NoteSection>
        )}
        {isSoilAmendment && !isInBaselineYear && (
          <NoteSection>
            <NoteTag>Note: </NoteTag>
            {PAGE_CONTENT.soilAmendmentNote}
          </NoteSection>
        )}
        {!isEdgeOfField &&
          getInputWithDelete(
            activityData,
            null,
            disableDeleteInput,
            showErrorIcon,
            acresFieldHasError && isEdgeOfField,
            isFieldDisabled,
          )}
        {/* Note: handling error layout differently for edge of field and other activities */}
        {acresFieldHasError && !isEdgeOfField && (
          <AcresError>{activityData.acresError}</AcresError>
        )}
        {isEdgeOfField && (
          <div>
            {getInputWithDelete(
              activityData,
              '1rem',
              disableDeleteInput,
              showErrorIcon,
              acresFieldHasError && isEdgeOfField,
              isFieldDisabled,
              {
                ...deleteIconStyles,
                alignSelf: 'baseline',
                marginTop: '0.3rem',
              },
            )}
          </div>
        )}
      </ActivityItemData>
    );
  };

  useEffect(() => {
    return () => {
      // Clear acreage data on unmout of the component
      setActivityAcreageList([]);
    };
  }, []);

  const handleSaveClick = async (event) => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    handleSave(event);
  };

  return (
    <div>
      <Title>
        {checkTernaryCondition(
          isInBaselineYear,
          <TextWrapper gap={'0.25rem'}>
            <Title>{PAGE_CONTENT.titleForBaseline}</Title>
            <Title fontWeight={400}>
              {GLOBAL_STRING_CONSTANTS.OPTIONAL_TEXT}
            </Title>
          </TextWrapper>,
          <Title>{PAGE_CONTENT.title}</Title>,
        )}
      </Title>
      <Subtitle>
        {checkTernaryCondition(
          isInBaselineYear,
          PAGE_CONTENT.subtitleForBaseline,
          PAGE_CONTENT.subtitle,
        )}
      </Subtitle>
      {activityAcreageList?.map((activityData) =>
        getActivityItemData(activityData),
      )}

      {!isFieldDisabled && (
        <MenuOptionsButton
          btnLabel={PAGE_CONTENT.activityDropdownBtnLabel}
          startIcon={<AddIcon />}
          optionsList={filteredActivityDropdownList}
          handleOptionClick={addNewActivity}
          disabled={
            isEmpty(filteredActivityDropdownList) ||
            disableAllFieldActions ||
            !isEditable
          }
        />
      )}

      {!isFieldDisabled && (
        <Button
          sx={saveBtnSx}
          disabled={disableAllFieldActions || !isEditable} // Keep it always enabled
          disableElevation
          onClick={handleSaveClick}>
          Save
        </Button>
      )}

      <ActivityDeleteModal
        open={activityDeleteModalProps?.open}
        closeModal={closeActivityDeleteModal}
        handleDeleteFromOpsData={handleDeleteFromOpsData}
        activityTitle={activityDeleteModalProps?.activityTitle}
      />
      <EdgeOfFieldCalcModal
        isOpen={isEOFModalOpen}
        setIsOpen={setIsEOFModalOpen}
      />
    </div>
  );
};

// proptypes
ImplementedActivitiesV2.propTypes = {
  currentFieldId: PropTypes.number,
  currentFarmId: PropTypes.number,
  currentYearData: PropTypes.object,
  refreshStatusHandler: PropTypes.func,
  makeFormDirty: PropTypes.func,
  setUserActivityDetected: PropTypes.func,
  disableAllFieldActions: PropTypes.bool,
  shouldNavigateToLandingPage: PropTypes.func,
  isEditable: PropTypes.bool,
};

export default ImplementedActivitiesV2;
