import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import {
  DataGridWrapper,
  FarmFieldWrapper,
  FilterWrapper,
  ImgAndIconWrapper,
  ImgTag,
  ImplementedOnTypographySx,
  ImplemnetedOnBoxSx,
  MainWrapper,
  StatusTextWrapper,
  TextWrapper,
  acceptAllBtnStyle,
  countStyle,
  datagridSx,
  statusStyle,
} from './ActivityReportingPOIReviewComp.style';
import { TablePaginationMenuListStyle, toastStyle } from 'theme/GlobalStyles';
import { Box, Button, Typography } from '@mui/material';
import {
  BUTTON_CONTENT,
  PAGE_CONTENT,
} from './ActivityReportingPOIReviewComp.content';
import { DOWNLOAD_LINK } from 'theme/GlobalColors';
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/downloadpoi.svg';
import { useEffect, useState } from 'react';
import ImageModalComp from './ImageModal/ImageModalComp.react';
import axios from 'axios';
import {
  FETCH_ALL_USER_ACTIONS,
  FETCH_POI_REPORT_DATA,
  POI_FILTER_DROPDOWN,
  DOWNLOAD_POI_FILE,
  ACCEPT_POI_STATUS,
  FLAG_POI_DATA,
  ACCEPT_ALL_POI_DATA,
} from 'urls';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  checkTernaryCondition,
  ifAnyIsTrue,
  isEmptyOrUndefined,
  openPDFFromLink,
  removeStringsFromArray,
} from 'utils/helper';
import FilterPanel from 'components/FilterPanel/FilterPanel.react';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import { ReactComponent as CircleIcon } from '../../../../../assets/icons/circle.svg';
import { ReactComponent as FlagIcon } from '../../../../../assets/icons/flag.svg';
import { ReactComponent as RedFlagIcon } from '../../../../../assets/icons/redflag.svg';
import { ReactComponent as CheckedCircleIcon } from '../../../../../assets/icons/checkedcircle.svg';
import { FilterPanelContent } from 'components/FilterPanel/FilterPanel.content';
import { ERROR_MSGS } from 'utils/config';
import { displayToast } from '../Participants.content';

const ImplementedOnFieldsAndAcres = (reportData) => {
  return (
    <Box sx={ImplemnetedOnBoxSx}>
      <Typography sx={ImplementedOnTypographySx}>
        <>
          {PAGE_CONTENT.implemented_count.split('{{fields}}')[0]}
          <TextWrapper display={'inline'} fontWeight={600}>
            {reportData?.totalPOIImplementedFields ?? PAGE_CONTENT.empty_text}
          </TextWrapper>
          {
            PAGE_CONTENT.implemented_count
              .split('{{fields}}')[1]
              .split('{{acres}}')[0]
          }
          <TextWrapper display={'inline'} fontWeight={600}>
            {reportData?.totalAcresAccounted ?? PAGE_CONTENT.empty_text}
          </TextWrapper>
          {PAGE_CONTENT.implemented_count.split('{{acres}}')[1]}
        </>
      </Typography>
    </Box>
  );
};
const ActivityReportingPOIReviewComp = ({
  projectId,
  participantId,
  disableVirtualization = false,
  setIsApproveDisabled,
  activityId,
}) => {
  const [pendingItems, setPendingItems] = useState(0);
  const [poiData, setPoiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_CONTENT.default_page_size);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProperties, setModalProperties] = useState(null);
  const [totalElements, setTotalElements] = useState();
  const [filterList, setFilterList] = useState();
  const [farm, setFarm] = useState('');
  const [projectActivity, setProjectActivity] = useState('');
  const [allStatus, setAllStatus] = useState('');
  const [flag, setFlag] = useState(false);
  const [statusMapList, setStatusMapList] = useState([]);
  const [reportData, setReportData] = useState({});
  const fetchFilterDropdownData = () => {
    axios
      .get(`${POI_FILTER_DROPDOWN}${projectId}/${participantId}/${activityId}`)
      .then((response) => {
        setFilterList(response.data);
      });
  };

  const fetchAllUserActions = () => {
    statusMapList.length === 0 &&
      axios.get(FETCH_ALL_USER_ACTIONS).then((response) => {
        setStatusMapList(response.data);
      });
  };

  const fetchAllFileStatuses = () => {
    const reportDataArray = reportData?.data || [];
    let isAllAccepted = reportDataArray.every((report) => {
      const reportRows = report?.data || [];
      return reportRows.every(
        (row) => row?.fileStatus === PAGE_CONTENT.accepted,
      );
    });
    setIsApproveDisabled(
      ifAnyIsTrue(!isAllAccepted, +pendingItems > 0, reportData?.flagged > 0),
    );
  };
  useEffect(() => {
    fetchPoiData();
    fetchFilterDropdownData();
    fetchAllUserActions();
  }, [pageNumber, pageSize, farm, projectActivity, allStatus, flag]);

  useEffect(() => {
    if (Object.keys(reportData)?.length > 0) {
      fetchAllFileStatuses();
    }
  }, [reportData]);

  const reportDataComparator = (a, b) => {
    return a.poiFileId - b.poiFileId;
  };

  const fetchPoiData = () => {
    setLoading(true);
    const itemsToRemove = ['', FilterPanelContent.isNone];
    axios
      .post(`${FETCH_POI_REPORT_DATA}`, {
        pageNumber: pageNumber,
        pageSize: pageSize,
        participantId: Number(participantId),
        projectId: projectId,
        farmIds: removeStringsFromArray([farm], itemsToRemove),
        projectActivityIds: removeStringsFromArray(
          [projectActivity],
          itemsToRemove,
        ),
        reviewStatuses: removeStringsFromArray([allStatus], itemsToRemove),
        showFlagged: flag,
        formId: activityId,
      })
      .then((response) => {
        const responseData = response.data;
        setReportData(responseData);

        const poiReportData = responseData?.data?.flatMap((item) => {
          return item?.data?.map((subItem) => ({
            fileType: item?.fileType,
            id: uniqueId(),
            fileThumbnail: subItem?.fileThumbnail,
            fileUrl: subItem?.fileUrl,
            fieldCountForFarm: subItem.fieldCountForFarm,
            fileName: subItem?.fileName,
            farmName: subItem?.farmName,
            poiFileId: subItem?.poiFileId,
            flaggedStatus: subItem?.flaggedStatus,
            fileStatus: subItem?.fileStatus,
            projectActivityType: subItem?.filePracticeFieldMapDtoList.map(
              (activity) => activity?.projectActivityType,
            ),
            projectActivityCategory: subItem?.filePracticeFieldMapDtoList.map(
              (activity) => activity?.projectActivityCategory,
            ),
            fieldName: subItem?.filePracticeFieldMapDtoList.flatMap(
              (activity) =>
                activity?.activityToFieldMapList
                  .filter((obj, index) => {
                    return (
                      index ===
                      activity?.activityToFieldMapList.findIndex(
                        (o) => obj.fieldId === o.fieldId,
                      )
                    );
                  })
                  .map((field) => field?.fieldName),
            ),
          }));
        });
        poiReportData.sort(reportDataComparator);
        setPoiData(poiReportData);
        setTotalElements(responseData?.pageable?.totalElements);
        setPendingItems(responseData?.pending);
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAcceptStatusClick = (poiFileId, isFlagged) => {
    /* istanbul ignore else*/
    if (!isFlagged) {
      const url = `${ACCEPT_POI_STATUS}/${poiFileId}`;

      setLoading(true);
      axios
        .put(url)
        .then(() => {
          fetchPoiData();
        })
        .catch(() => {
          /* istanbul ignore next */
          displayToast('error', ERROR_MSGS.UNEXPECTED);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleFlagClick = (poiFileId) => {
    const url = `${FLAG_POI_DATA}/${poiFileId}`;

    setLoading(true);
    axios
      .put(url)
      .then(() => {
        fetchPoiData();
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.UNEXPECTED);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAcceptAll = () => {
    const url = `${ACCEPT_ALL_POI_DATA}/${participantId}/${projectId}`;

    setLoading(true);
    axios
      .put(url)
      .then(() => {
        fetchPoiData();
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.UNEXPECTED);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = (
    fileThumbnail,
    projectActivityType,
    fieldName,
    fieldCountForFarm,
  ) => {
    setModalProperties({
      imageURL: fileThumbnail,
      title: projectActivityType,
      fieldName: fieldName,
      fieldCountForFarm: fieldCountForFarm,
    });
    setIsModalOpen({ flag: true });
  };

  const handleDownload = (fileUrl) => {
    openPDFFromLink(`${fileUrl}`);
  };

  const doHandlePOIFileDownload = async (
    poiFileId,
    isDownloadClicked,
    projectActivityType,
    fieldName,
    fieldCountForFarm,
  ) => {
    setLoading(true);
    axios
      .get(DOWNLOAD_POI_FILE.replace(':poiFileId', poiFileId))
      .then((response) => {
        if (response?.data) {
          if (isDownloadClicked) {
            handleDownload(response.data);
          } else {
            handleClick(
              response.data,
              projectActivityType,
              fieldName,
              fieldCountForFarm,
            );
          }
        } else {
          toast(
            <CustomSnackbar
              type="error"
              message={PAGE_CONTENT.file_download_error}
            />,
            toastStyle,
          );
        }
      })
      .catch(() =>
        toast(
          <CustomSnackbar
            type="error"
            message={PAGE_CONTENT.file_download_error}
          />,
          toastStyle,
        ),
      )
      .finally(() => {
        setLoading(false);
      });
  };
  const columns = [
    {
      field: PAGE_CONTENT.file_thumbnail,
      headerName: PAGE_CONTENT.file,
      disableColumnMenu: true,
      flex: 0.00001,
      minWidth: 130,
      sortable: false,
      renderCell: (params) => (
        <MainWrapper>
          <ImgAndIconWrapper>
            <ImgTag
              src={`data:image/png;base64,${params?.row?.fileThumbnail}`}
              onClick={(event) => {
                event.stopPropagation();
                doHandlePOIFileDownload(
                  params?.row?.poiFileId,
                  false,
                  params?.row?.projectActivityType,
                  params?.row?.fieldName,
                  params?.row?.fieldCountForFarm,
                );
              }}
              data-testid="imageTag"
            />
            {isModalOpen && (
              <ImageModalComp
                isModalOpen={isModalOpen}
                src={modalProperties}
                onClose={() => setIsModalOpen(false)}
              />
            )}
            <DownloadIcon
              onClick={(event) => {
                event.stopPropagation();
                doHandlePOIFileDownload(params?.row?.poiFileId, true);
              }}
              data-testid="downloadIcon"
            />
          </ImgAndIconWrapper>
        </MainWrapper>
      ),
    },
    {
      field: PAGE_CONTENT.file_type,
      headerName: PAGE_CONTENT.fileType,
      disableColumnMenu: true,
      flex: 0.00001,
      minWidth: 250,
      sortable: false,
      renderCell: (params) => (
        <MainWrapper onClick={(event) => event.stopPropagation()}>
          {params?.row?.fileType}
        </MainWrapper>
      ),
    },
    {
      field: PAGE_CONTENT.project_activity_type_field,
      headerName: PAGE_CONTENT.projectActivityType,
      disableColumnMenu: true,
      flex: 0.00001,
      minWidth: 358,
      sortable: false,
      renderCell: (params) => {
        if (params?.row?.projectActivityType.length > 1) {
          return (
            <MainWrapper
              onClick={(event) => {
                event.stopPropagation();
              }}>
              {params?.row?.projectActivityCategory[0]?.toString()} {'-'}
              <TextWrapper color={DOWNLOAD_LINK}>
                {params?.row?.projectActivityType.length}{' '}
                {PAGE_CONTENT.practices}
              </TextWrapper>
            </MainWrapper>
          );
        }
        if (
          params?.row?.projectActivityType[0].toString() ===
          params?.row?.projectActivityCategory[0].toString()
        ) {
          return (
            <MainWrapper
              onClick={(event) => {
                event.stopPropagation();
              }}>
              {params?.row?.projectActivityCategory[0]?.toString()}
            </MainWrapper>
          );
        }
        return (
          <MainWrapper
            onClick={(event) => {
              event.stopPropagation();
            }}>
            {params?.row?.projectActivityCategory[0]?.toString()} {'-'}{' '}
            {params?.row?.projectActivityType[0]?.toString()}
          </MainWrapper>
        );
      },
    },
    {
      field: PAGE_CONTENT.fieldFarm,
      headerName: PAGE_CONTENT.fieldFarm,
      disableColumnMenu: true,
      flex: 0.0001,
      minWidth: 357,
      sortable: false,
      renderCell: (params) => {
        if (
          params?.row?.fileType
            .toLowerCase()
            .includes(PAGE_CONTENT.geo_referenced_file.toLowerCase()) ||
          +params?.row?.fieldName.length === 1
        ) {
          return (
            <FarmFieldWrapper
              flexDirection="column"
              onClick={(event) => {
                event.stopPropagation();
              }}>
              <div>
                <span>{params?.row?.fieldName[0]}</span>
                <TextWrapper fontWeight={400} fontSize="12px">
                  {params?.row?.farmName}
                </TextWrapper>
              </div>
            </FarmFieldWrapper>
          );
        } else if (
          +params?.row?.fieldName.filter(
            (item, index) => params?.row?.fieldName.indexOf(item) === index,
          ).length === +params?.row?.fieldCountForFarm
        ) {
          return (
            <FarmFieldWrapper
              flexDirection="column"
              onClick={(event) => {
                event.stopPropagation();
              }}>
              <TextWrapper
                color={DOWNLOAD_LINK}
                fontWeight={400}
                fontSize="14px">
                {PAGE_CONTENT.allFields}
              </TextWrapper>
              <TextWrapper fontWeight={400} fontSize="12px">
                {params?.row?.farmName}
              </TextWrapper>
            </FarmFieldWrapper>
          );
        } else {
          return (
            <FarmFieldWrapper
              flexDirection="column"
              onClick={(event) => {
                event.stopPropagation();
              }}>
              <TextWrapper
                color={DOWNLOAD_LINK}
                fontWeight={400}
                fontSize="14px">
                {params?.row?.fieldName.length} {PAGE_CONTENT.fields}
              </TextWrapper>
              <TextWrapper fontWeight={400} fontSize="12px">
                {params?.row?.farmName}
              </TextWrapper>
            </FarmFieldWrapper>
          );
        }
      },
    },
    {
      field: PAGE_CONTENT.flag,
      headerName: PAGE_CONTENT.flag,
      disableColumnMenu: true,
      flex: 0.000007,
      minWidth: 51,
      sortable: false,
      renderCell: (params) => (
        <MainWrapper
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            handleFlagClick(params?.row?.poiFileId);
          }}
          data-testid="flagged">
          {params?.row?.flaggedStatus ? <RedFlagIcon /> : <FlagIcon />}
        </MainWrapper>
      ),
    },
    {
      field: PAGE_CONTENT.accept,
      headerName: PAGE_CONTENT.accept,
      disableColumnMenu: true,
      flex: 0.000007,
      minWidth: 70,
      sortable: false,
      renderCell: (params) => (
        <MainWrapper
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            handleAcceptStatusClick(
              params?.row?.poiFileId,
              params?.row?.flaggedStatus,
            );
          }}
          data-testid="accepted">
          {params?.row?.fileStatus === PAGE_CONTENT.accepted ? (
            <CheckedCircleIcon />
          ) : (
            <CircleIcon />
          )}
        </MainWrapper>
      ),
    },
  ];

  return (
    <DataGridWrapper>
      <FilterWrapper>
        <FilterPanel
          dropDownList={filterList}
          farm={checkTernaryCondition(
            isEmptyOrUndefined(farm),
            FilterPanelContent.isNone,
            farm,
          )}
          setFarm={setFarm}
          allStatus={checkTernaryCondition(
            isEmptyOrUndefined(allStatus),
            FilterPanelContent.isNone,
            allStatus,
          )}
          setAllStatus={setAllStatus}
          projectActivity={checkTernaryCondition(
            isEmptyOrUndefined(projectActivity),
            FilterPanelContent.isNone,
            projectActivity,
          )}
          setProjectActivity={setProjectActivity}
          flag={flag}
          setFlag={setFlag}
        />
        <div style={statusStyle}>
          <StatusTextWrapper>
            {PAGE_CONTENT.flagged}{' '}
            <span data-testid="flagged-count" style={countStyle}>
              {reportData?.flagged}
            </span>
          </StatusTextWrapper>
          <StatusTextWrapper>
            {PAGE_CONTENT.pending}{' '}
            <span style={countStyle}>{reportData?.pending}</span>
          </StatusTextWrapper>
          <StatusTextWrapper>
            {PAGE_CONTENT.accepted}{' '}
            <span style={countStyle}>{reportData?.accepted}</span>
          </StatusTextWrapper>
          <Button
            data-testid="accept-all-button"
            className="accept-all"
            onClick={handleAcceptAll}
            size={BUTTON_CONTENT.small}
            color={BUTTON_CONTENT.success}
            sx={acceptAllBtnStyle}
            disabled={reportData?.flagged > 0}
            disableElevation>
            {BUTTON_CONTENT.accept_all_button}
          </Button>
        </div>
      </FilterWrapper>
      <DataGrid
        headerHeight={40}
        disableVirtualization={disableVirtualization || false}
        disableSelectionOnClick
        loading={loading}
        sx={datagridSx(poiData?.length < 7)}
        components={{
          Toolbar: () =>
            projectActivity !== FilterPanelContent.activities_placeholder &&
            ImplementedOnFieldsAndAcres(reportData),
        }}
        rowCount={totalElements}
        columns={columns}
        rows={poiData || []}
        page={pageNumber}
        paginationMode={PAGE_CONTENT.pagination_mode}
        onPageChange={(newPage) => setPageNumber(newPage)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        rowsPerPageOptions={PAGE_CONTENT.default_pagination_options}
        componentsProps={{
          pagination: {
            labelRowsPerPage: PAGE_CONTENT.label_rows_per_page,
            SelectProps: TablePaginationMenuListStyle,
          },
        }}
      />
    </DataGridWrapper>
  );
};
export default ActivityReportingPOIReviewComp;

ActivityReportingPOIReviewComp.propTypes = {
  disableVirtualization: PropTypes.bool,
  participantId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  setIsApproveDisabled: PropTypes.func,
  activityId: PropTypes.string.isRequired,
};
