const AUTH_REQ = 'Authorization Required';
const AUTH_REQ_MSG =
  'The page you are trying to view requires authorization credentials. If you believe this is an error, return to the previous page and try again or contact your administrator to confirm access.';

export const PAGE_NOT_FOUND = {
  title: '404 Error',
  subHeading: 'Page not found',
  text: 'The page you are trying to access may not exist. Return to the previous page and try again.',
};

export const UNAUTHORIZED_ACCESS = {
  title: '403 Error',
  subHeading: AUTH_REQ,
  text: AUTH_REQ_MSG,
};

export const UNAUTHENTICATED_ACCESS = {
  title: '401 Error',
  subHeading: AUTH_REQ,
  text: AUTH_REQ_MSG,
};

export const SERVER_RESPONSE_TIMEOUT = {
  title: '504 Error',
  subHeading: 'Server response timeout',
  text: 'We didn’t receive a response from the server. Please try refreshing or return to the previous page.',
};

export const SERVICE_UNAVAILABLE = {
  title: '503 Error',
  subHeading: 'Service unavailable',
  text: 'The service is temporarily unavailable. Try refreshing the page or return to the previous page.',
};

export const LOST_NETWORK_CONNECTIVITY = {
  title: '502 Error',
  subHeading: 'Lost network connectivity',
  text: 'The connection to the network gateway was lost. Try refreshing the page or return to the previous page.',
};
export const SERVER_REQUEST_FAILED = {
  title: '500 Error',
  subHeading: 'Server request failed',
  text: 'A problem connecting to the server occurred. Try refreshing the page or return to the previous page.',
};
