import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  COUNTRY,
  REGION,
  REGISTRY,
  PROJECT_TYPES,
  PROJECT_REGISTRY_ID,
  PROJECT_CONTAINS,
  PRICE_ASSUMPTION_TYPE,
  REGISTRY_METHODOLOGY,
  PROJECT_NEW,
  STATUS,
  PROJECT_TAGS,
  PROJECT_ALL_TAGS,
} from '../urls';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { useNavigate } from 'react-router';
import { pathName } from 'Routes/routes.const';
import { PAGE_CONTENT } from 'pages/AddProject/AddProject.content';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import { ERROR_MSGS } from 'utils/config';

export const useProjectDetails = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [registryData, setRegistryData] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [methodology, setMethodology] = useState([]);
  const [countryList, setCountry] = useState([]);
  const [regionList, setregion] = useState([]);
  const [status, setStatus] = useState([]);
  const [priceAssumptionTypes, setPriceAssimptionTypes] = useState([]);
  const [developerProjectName, setDeveloperProjectName] = useState({});
  const [_internalProjectForm, setInternalProjectForm] = useState();
  const [_editProjectForm, setEditProjectForm] = useState();
  const [projectRegistryIdOptions, setProjectRegistryIdOptions] = useState([]);
  const [projectRegistryValue, setProjectRegistryValue] = useState('');
  const [registryId, setRegistryId] = useState('');
  const [tags, setTags] = useState([]);

  const [existingNameError, setExistingNameError] = useState({
    flag: false,
    message: '',
  });

  const [registryProjectIDError, SetRegistryProjectIDError] = useState({
    flag: false,
    message: '',
  });

  const [completeProjectRegistryValue, setCompleteProjectRegistryValue] =
    useState('');

  const projectDataEndpoints = [
    REGISTRY,
    COUNTRY,
    REGION,
    PROJECT_TYPES,
    STATUS,
    PRICE_ASSUMPTION_TYPE,
    REGISTRY_METHODOLOGY,
  ];

  const navigate = useNavigate();

  useEffect(() => {
    axios.all(projectDataEndpoints.map((promise) => axios.get(promise))).then(
      axios.spread(
        (
          _registry,
          _country,
          _region,
          _types,
          _status,
          _priceAssumption,
          _registryMethodology,
        ) => {
          const modifiedRegistry = _registry.data.map((r) => ({
            label: r['standardsBody'],
            value: r['registryId'],
            name: r['registryName'],
          }));
          setRegistryData(modifiedRegistry);
          setCountry(_country.data);

          setMethodology(_registryMethodology.data);

          const modifiedStatus = _status.data.map((data) => data);
          setStatus(modifiedStatus);

          const modifiedPriceAssumptionType = _priceAssumption.data.map(
            (data) => ({
              label: data,
              value: data,
            }),
          );
          setPriceAssimptionTypes(modifiedPriceAssumptionType);
          setregion(_region.data);

          const modifiedProjectTypes = _types.data;
          setProjectTypes(modifiedProjectTypes);
        },
      ),
    );
  }, []);

  useEffect(() => {
    if (_internalProjectForm) {
      submitProjectForm(_internalProjectForm).then(() => {});
    }
  }, [_internalProjectForm]);

  useEffect(() => {
    if (_editProjectForm) {
      EditProjectForm(_editProjectForm).then(() => {});
    }
  }, [_editProjectForm]);

  useEffect(() => {
    if (projectRegistryValue && registryId) {
      if (projectRegistryValue !== '' && registryId !== '') {
        axios
          .get(
            `${PROJECT_CONTAINS}?registry-project-id=${projectRegistryValue}&registry-id=${registryId}`,
          )
          .then((response) => {
            if (response.data.length === 0) {
              SetRegistryProjectIDError({
                flag: true,
                message: PAGE_CONTENT.project_registry_id_error,
              });
            }
            setProjectRegistryIdOptions(() => [
              ...response.data
                .filter((registry) => registry.registryProjectName !== '')
                .map((registry) => ({
                  registryOptions:
                    registry['projectRegistryId'].split('-').pop() +
                    '-' +
                    registry['registryProjectName'],
                  registry: registry['projectRegistryId'].split('-')[0],
                })),
            ]);
          });
      }
    }
  }, [projectRegistryValue]);

  useEffect(() => {
    if (
      completeProjectRegistryValue !== '' &&
      completeProjectRegistryValue.length > 2
    ) {
      let projectRegistryId = completeProjectRegistryValue.slice(
        0,
        completeProjectRegistryValue.indexOf('-'),
      );
      let projectRegistry = projectRegistryIdOptions.filter(
        (data) =>
          data.registryOptions.slice(
            0,
            completeProjectRegistryValue.indexOf('-'),
          ) === projectRegistryId,
      )[0];
      const registryProjectId = projectRegistryId.split('-').pop();
      axios
        .get(
          PROJECT_REGISTRY_ID +
            '/' +
            projectRegistry.registry +
            '-' +
            projectRegistryId +
            '/auto-populate',
        )
        .then((response) => {
          if (
            response?.data?.['projectDeveloper'] &&
            response?.data?.['projectName']
          ) {
            const developerName = response.data['projectDeveloper'];
            const registry = response.data['registry'];
            const projectName = response.data['projectName'];
            const projectType = response.data['projectType'];
            const projectStatus = response.data['projectStatus'];
            const projectMethodology = response.data['projectMethodology'];
            const projectCountry = response.data['projectCountry'];
            const projectRegion = response.data['projectRegion'];
            setDeveloperProjectName({
              developerName,
              projectName,
              projectType,
              projectStatus,
              projectMethodology,
              projectCountry,
              projectRegion,
              registryProjectId,
              registry,
            });
          } else {
            SetRegistryProjectIDError({
              flag: true,
              message: 'Please select correct registry type',
            });
          }
        })
        .catch((error) => {
          if (error) {
            if (
              error.response.data?.message.includes('Project registry with id')
            ) {
              SetRegistryProjectIDError(() => ({
                flag: true,
                message: error.response.data?.message,
              }));
            }
          }
        });
    }
  }, [completeProjectRegistryValue]);

  const fetchTags = (tagName) => {
    if (tagName.length > 2) {
      axios
        .get(`${PROJECT_TAGS}?tag=${tagName}`)
        .then((response) => {
          setTags((prev) => [
            ...response.data.filter((tag) => prev.indexOf(tag) === -1),
            ...prev,
          ]);
        })
        .catch(() => {});
    }
  };

  const fetchAllTags = () => {
    axios
      .get(PROJECT_ALL_TAGS)
      .then((response) => {
        setTags(response.data);
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      });
  };

  const submitProjectForm = (formData) => {
    setExistingNameError({ flag: false, message: '' });
    SetRegistryProjectIDError({ flag: false, message: '' });
    setSubmitLoading(true);
    const projectSubmitBody = {
      clientId: 100,
      // registryProjectId: developerProjectName.registryProjectId && !formData.newProjectId ? developerProjectName.registryProjectId : null,
      projectRegistryId:
        developerProjectName.registryProjectId && !formData.newProjectId
          ? formData.registries + '-' + developerProjectName.registryProjectId
          : null,
      // ccProjectType: formData.projectSubType,
      projectName: formData.projectName,
      projectDeveloper: formData.developerName,
      // projectNatureVsNonnature: formData.projectNatureType,
      projectType: formData.projectSubType,
      projectMethodology: formData.methodology,
      projectCountry: formData.country ? formData.country : '',
      projectRegion: formData.region ? formData.region : '',
      projectStatus: formData.impStatus,
      internalReviewStatus: formData.reviewStatus,
      projectDescription: formData.projectdesc,
      projectNotes: formData.projectNotes,
      createdBy: '',
      tags: formData.tags,
      projectTypePriceAssumptionGroup: formData.priceAssumptionType,
      registryId: formData.registries === '' ? -1 : formData.registries,
    };

    return axios
      .post(PROJECT_NEW, projectSubmitBody)
      .then(() => {
        navigate(pathName.assets.projects.view, { state: 'edit-project' });
        toast(
          <CustomSnackbar
            type="success"
            message={`Success! ${projectSubmitBody.projectName} has been added to your portfolio.`}
          />,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      })
      .catch((error) => {
        if (error.response.data?.message.includes('name')) {
          setExistingNameError(() => ({
            flag: true,
            message:
              'The project name entered has already been added to the portfolio',
          }));
        } else if (
          error.response.data?.message.includes('project registry ID')
        ) {
          SetRegistryProjectIDError(() => ({
            flag: true,
            message: error.response.data?.message,
          }));
        } else {
          navigate(-1);
          toast(
            <CustomSnackbar
              type="error"
              message={`Error ${projectSubmitBody.projectName} has not been added to your portfolio. Try Again`}
            />,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const EditProjectForm = (formData) => {
    setExistingNameError({ flag: false, message: '' });
    SetRegistryProjectIDError({ flag: false, message: '' });
    setSubmitLoading(true);
    const projectEditBody = {
      clientId: 100,
      // internalProjectId: formData.internalProjectId,
      // registryProjectId: formData.registryProjectId ? formData.registryProjectId : null,
      projectRegistryId: formData.projectId
        ? formData.registries +
          '-' +
          formData.projectId.slice(0, formData.projectId.indexOf('-'))
        : '-1',
      // ccProjectType: formData.projectSubType,
      projectName: formData.projectName,
      projectType: formData.projectSubType,
      projectDeveloper: formData.developerName,
      // projectNatureVsNonnature: formData.projectNatureType,
      priceAssumptionTypeId: 1,
      projectMethodology: formData.methodology,
      projectCountry: formData.country ? formData.country : '',
      projectRegion: formData.region ? formData.region : '',
      projectStatus: formData.impStatus,
      internalReviewStatus: formData.reviewStatus,
      projectTypePriceAssumptionGroup: formData.priceAssumptionType,
      projectDescription: formData.projectdesc,
      projectNotes: formData.projectNotes,
      createdBy: '',
      tags: formData.tags,
      registryId: formData.registries === '' ? -1 : formData.registries,
    };

    return axios
      .request(`${PROJECT_NEW}/${formData.internalProjectId}`, {
        method: 'put',
        data: projectEditBody,
      })
      .then((response) => {
        navigate(pathName.assets.projects.view, { state: 'edit-project' });
        toast(
          <CustomSnackbar
            type="success"
            message={`Success! ${response.data.projectName} has been updated.`}
          />,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      })
      .catch((error) => {
        if (error.response.data?.message.includes('name')) {
          setExistingNameError(() => ({
            flag: true,
            message:
              'The project name entered has already been added to the portfolio',
          }));
        } else if (error.response.data?.message.includes('project ID')) {
          SetRegistryProjectIDError(() => ({
            flag: true,
            message: error.response.data?.message,
          }));
        } else {
          navigate(-1);
          toast(
            <CustomSnackbar
              type="error"
              message={`Error ${projectEditBody.projectName} has not been updated. Try Again`}
            />,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const projectRegistryHandler = (inputValue) => {
    if (inputValue !== null) {
      setProjectRegistryValue(inputValue);
    }
  };

  const registryHandler = (inputValue) => {
    if (inputValue !== '') {
      setRegistryId(inputValue);
    }
  };

  return {
    registryData,
    submitLoading,
    methodology,
    countryList,
    status,
    regionList,
    tags,
    setTags,
    priceAssumptionTypes,
    projectTypes,
    setInternalProjectForm,
    projectRegistryIdOptions,
    setProjectRegistryIdOptions,
    projectRegistryHandler,
    developerProjectName,
    setCompleteProjectRegistryValue,
    existingNameError,
    registryProjectIDError,
    EditProjectForm,
    setExistingNameError,
    SetRegistryProjectIDError,
    setEditProjectForm,
    fetchTags,
    fetchAllTags,
    registryHandler,
  };
};
