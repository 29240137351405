import { checkTernaryCondition, isNullOrEmpty } from 'utils/helper';
import { projectActivitiesMapping } from '../../ActivityReporting.content';
import { PAGE_CONTENT } from './ImplementedActivitiesV2.content';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { ACTIVITY_CATEGORY } from 'utils/config';
import { ACTIVITY_PRACTICES } from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';

export const getActivityError = ({
  activityCategory,
  activityAcres,
  fieldArea,
  largestCoverCropArea,
  largestCropAreaWithSoilAmendment,
  totalOperationCropArea = 0,
}) => {
  const area = checkTernaryCondition(
    activityAcres === '.',
    '0.',
    activityAcres,
  );

  if (isNullOrEmpty(activityAcres)) return '';
  if (!isNullOrEmpty(activityAcres) && Number(area) <= 0)
    return PAGE_CONTENT.acresZeroError;

  const acres = Number(area);

  switch (activityCategory) {
    case ACTIVITY_CATEGORY.NUTRIENT_MANAGEMENT:
      return checkTernaryCondition(
        acres > fieldArea,
        `For each Nutrient management section , The acreage recorded within the implemented activity section must be less than or equal to the field area (${fieldArea} acres)`,
        '',
      );
    case ACTIVITY_CATEGORY.COVER_CROP:
      if (totalOperationCropArea === 0 && acres > fieldArea) {
        return `The cover crop acreage cannot exceed the total field acreage of (${fieldArea} acres).`;
      }

      if (totalOperationCropArea === 0) return '';

      if (totalOperationCropArea > fieldArea && acres > fieldArea) {
        return `The total cover crop acreage cannot exceed the total field acreage of (${fieldArea} acres).`;
      }
      if (acres > totalOperationCropArea) {
        return `The cover crop acreage cannot exceed ${totalOperationCropArea} acres  (the sum of cover crops)  in the operations data.`;
      }
      return '';
    case ACTIVITY_CATEGORY.SOIL_CARBON_AMENDMENT:
      return checkTernaryCondition(
        acres > fieldArea || acres < largestCropAreaWithSoilAmendment,
        `For each soil amendment type, the acreage recorded within the implemented activity section must be less than or equal to the field area (${fieldArea} acres) and greater than or equal to the largest crop area for crops with that soil amendment (${largestCropAreaWithSoilAmendment} acres) .`,
        '',
      );
    case ACTIVITY_CATEGORY.EDGE_OF_FIELD:
      return checkTernaryCondition(
        acres >= fieldArea,
        `For each edge of field practice type, the acreage recorded within the implemented activity section must be less than the field area (${fieldArea} acres) .`,
        '',
      );
    default:
      return '';
  }
};

export const formatActivityAcreageData = (
  acreageList,
  fieldArea,
  largestCoverCropArea,
  cropAreasWithSoilAmendments,
) => {
  return (
    acreageList?.map((activity) => {
      const largestCropAreaWithSoilAmendment =
        getLargestCropAreaWithSoilAmendment(
          cropAreasWithSoilAmendments,
          activity.projectActivityType,
        );

      const projectActivityCategory =
        projectActivitiesMapping[activity.activityCategory];
      let label = projectActivityCategory;

      // Note: Since 'Cover crop' and 'Nutrient management' have both category and activity as same name, we are not showing both category and activity name in the UI
      if (
        label !== ACTIVITY_CATEGORY.NUTRIENT_MANAGEMENT &&
        label !== ACTIVITY_CATEGORY.COVER_CROP
      ) {
        label += `: ${activity.projectActivityType}`;
      }

      // Note: Label is used to show the activity name in the dropdown
      return {
        ...activity,
        activityCategory: projectActivityCategory,
        label: label,
        // Creating separate error since "acres required" error is only shown as error icon without any message and others have message.
        acresRequiredError: isNullOrEmpty(activity.area),
        acresError: getActivityError({
          activityCategory: projectActivityCategory,
          activityAcres: activity.area,
          fieldArea: fieldArea,
          largestCoverCropArea: largestCoverCropArea,
          largestCropAreaWithSoilAmendment: largestCropAreaWithSoilAmendment,
        }),

        // Unique key to identify each activity
        uniqueKey: uniqueId(),
      };
    }) ?? []
  );
};

export const getLargestCropAreaWithSoilAmendment = (
  cropAreaList,
  activityName,
) => {
  return (
    cropAreaList?.reduce((maxArea, crop) => {
      if (crop.soilAmendmentType === activityName) {
        return Math.max(maxArea, Number(crop.cropArea));
      }
      return maxArea;
    }, 0) ?? 0
  );
};

export const getActivityTypeForDelete = (activityData) => {
  const { activityCategory } = activityData;

  switch (activityCategory) {
    case ACTIVITY_CATEGORY.COVER_CROP:
      return ACTIVITY_CATEGORY.COVER_CROP;
    case ACTIVITY_CATEGORY.RESIDUE_PLUS_TILLAGE:
      return 'Tillage';
    case ACTIVITY_CATEGORY.SOIL_CARBON_AMENDMENT:
      return ACTIVITY_CATEGORY.SOIL_CARBON_AMENDMENT;
    default:
      return '';
  }
};

export const checkAcres = (acres) => {
  if (acres === 0) {
    return PAGE_CONTENT.acresZeroError;
  }
  return PAGE_CONTENT.VALID_ACRES;
};

export const filterActivities = (
  activityList,
  residueTillageActivitySelected,
  tillageDataInOps,
  populatedActivityIds,
) => {
  return activityList?.filter((activity) => {
    if (
      residueTillageActivitySelected &&
      activity.activityCategory === ACTIVITY_CATEGORY.RESIDUE_PLUS_TILLAGE
    ) {
      return false;
    }

    if (
      tillageDataInOps &&
      activity.activityCategory === ACTIVITY_CATEGORY.RESIDUE_PLUS_TILLAGE
    ) {
      if (activity.projectActivityType === ACTIVITY_PRACTICES.NO_TILLAGE) {
        return tillageDataInOps === ACTIVITY_PRACTICES.NO_TILLAGE;
      }

      return tillageDataInOps !== ACTIVITY_PRACTICES.NO_TILLAGE;
    }

    return !populatedActivityIds?.includes(activity.projectActivityId);
  });
};
