import { RED, COOL_GRAY } from 'theme/GlobalColors';
import styled from 'styled-components';

export const CropTypeModalDialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '37rem',
      padding: '1.5rem',
      position: 'relative',

      '*': {
        fontFamily: 'Open Sans, sans-serif',
      },
      '& .MuiDialogContent-root': {
        overflow: 'visible',
      },
    },
  },
};
export const CropTypeModalDialogStyleForHarvestEfficiencyUom = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '44rem',
      padding: '1.5rem',
      position: 'relative',

      '*': {
        fontFamily: 'Open Sans, sans-serif',
      },
      '& .MuiDialogContent-root': {
        overflow: 'visible',
      },
    },
  },
};
export const CropTypeModalDialogContentStyle = {
  marginBottom: '1rem',
  padding: '20px 0rem',
  paddingBottom: 0,
};
export const CropTypeModalIconButtonStyle = {
  ml: 'auto',
  position: 'absolute',
  right: 4,
  top: 4,
};
export const CropTypeModalDialogTitleStyle = {
  padding: '0px',
};
export const CropTypeModalToolTipStyle = {
  marginLeft: '0.5rem',
};
export const CropTypeModalDialogActionStyle = {
  justifyContent: 'flex-end',
  padding: 0,
  fontFamily: 'Open Sans, sans-serif',
};

export const ValidationErrorStyles = {
  color: RED,
  fontSize: '0.75rem',
  padding: '0.25rem 0rem 0rem 0.5rem',
};

export const RadioGroupStyle = {
  gap: '1rem',
  '& .MuiFormControlLabel-root': {
    marginLeft: '-0.625rem',
  },
};

export const TillageHeading = styled.div`
  color: ${COOL_GRAY};
  font-size: 0.875rem;
  margin-top: 0.5rem;
  font-weight: 600;
`;
export const CropValueInput = styled.div`
  display: flex;
`;
export const CropValueDollar = styled.div`
  margin-top: 3rem;
`;
export const cropIconToolTip = {
  marginTop: '0.15rem',
  width: '1rem',
  height: '1rem',
};
