import React from 'react';
import Typography from '@mui/material/Typography';
import {
  CustomPopupBox,
  ItemsWrapper,
  GridWrapper,
} from '../ValueTable/ValueTable.style';
import { StrikeOutText, ValueWrapper } from './DeliveryDetails.style';
import { LABELS } from './DeliveryDetails.const';
import { dateFormatter } from '../../../../../utils/dateFormatter';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { WhiteChip } from 'components/ActionModal/Components/AddedDeletedTable/AddedDeletedTable.style';
import { checkTernaryCondition } from 'utils/helper';
import PropTypes from 'prop-types';

const getText = (type, oldValue, newValue) => {
  let _oldValue = oldValue;
  let _newValue = newValue;

  if (type == 'date') {
    _oldValue = oldValue ? dateFormatter(oldValue) : oldValue;
    _newValue = newValue ? dateFormatter(newValue) : newValue;
  }
  if (
    _newValue == null ||
    _newValue == undefined ||
    _newValue == '' ||
    _newValue == 'Deleted'
  ) {
    return (
      <span>
        <StrikeOutText color="#A7A8AA">{_oldValue}</StrikeOutText>
      </span>
    );
  } else if (_oldValue != _newValue) {
    return (
      <span>
        {(_oldValue != '' || _newValue == null) && (
          <StrikeOutText color="#333333">{_oldValue}</StrikeOutText>
        )}
        {_newValue}
      </span>
    );
  } else {
    return _newValue;
  }
};
const table = (data) => {
  return (
    <CustomPopupBox direction="column">
      <ItemsWrapper borderBottom="1px solid #D0D0CE">
        <ValueWrapper width="15.5rem">
          <b>{LABELS.ocuursOn}</b>
        </ValueWrapper>
        <ValueWrapper width="15.5rem">
          <span>
            <b>{LABELS.amount}</b>
            {LABELS.mtCo2}
          </span>
        </ValueWrapper>
        <ValueWrapper width="15.5rem">
          <b>{LABELS.deliveryStatus}</b>
        </ValueWrapper>
        <ValueWrapper width="4rem"></ValueWrapper>
      </ItemsWrapper>
      <GridWrapper>
        {data?.map((item) => (
          <ItemsWrapper key={uniqueId()}>
            <ValueWrapper width="15.5rem">
              {getText(
                'text',
                item?.auditDeliveryEvents[1]?.oldValue,
                item?.auditDeliveryEvents[1]?.newValue,
              )}
            </ValueWrapper>

            <ValueWrapper width="15.5rem" fontWeight="600">
              {getText(
                'text',
                item?.auditDeliveryEvents[0]?.oldValue,
                item?.auditDeliveryEvents[0]?.newValue,
              )}
            </ValueWrapper>
            <ValueWrapper width="15.5rem" fontWeight="600">
              {getText(
                'text',
                item?.auditDeliveryEvents[2]?.oldValue,
                item?.auditDeliveryEvents[2]?.newValue,
              )}
            </ValueWrapper>
            {item?.auditDeliveryEvents[0]?.action && (
              <ValueWrapper width="4rem">
                <WhiteChip>
                  {checkTernaryCondition(
                    item?.auditDeliveryEvents[0]?.action === 'INSERT',
                    'Added',
                    'Deleted',
                  )}
                </WhiteChip>
              </ValueWrapper>
            )}
          </ItemsWrapper>
        ))}
      </GridWrapper>
    </CustomPopupBox>
  );
};

const DeliveryDetails = ({ title, data /* totalAmount */ }) => {
  return (
    <div>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: '600',
          padding: '0rem 0rem 0rem 0rem',
        }}>
        {title}
      </Typography>
      <div style={{ marginTop: '8px', paddingLeft: '0rem' }}>{table(data)}</div>
      {/* <FinalStatment>
        <s>{totalAmount.oldValue} MT</s> <b>{totalAmount.newValue}</b> MT CO
        <sub>2</sub>e out of{' '}
        <b>{totalAmount.oldValue + totalAmount.newValue}</b> MT CO2e units has
        been scheduled
      </FinalStatment> */}
    </div>
  );
};

DeliveryDetails.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
};

export default DeliveryDetails;
