import DialogBox from 'components/DialogBox/DialogBox.react';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { COOL_GRAY } from 'theme/GlobalColors';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import axios from 'axios';
import {
  FETCH_PARTICIPANT_DETAILS,
  FETCH_POSSIBLE_USER_STATUS,
  UPDATE_ACCOUNT_STATUS,
} from 'urls';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import PropTypes from 'prop-types';
import { loaderContext } from 'contextAPI/loaderContext';
import {
  CONFIRMATIONAL_MODAL_CONTENT,
  MODAL_INPUTS,
  MODAL_TITLE,
} from './UpdateAccountModal.content';
import {
  confirmationalDialogBoxSx,
  ConfirmationalModalContent,
  ConfirmationalModalContentWrapper,
  dialogBoxSx,
  dialogBoxTitleSx,
  inputProps,
  InputSx,
  modalContentCustomSx,
  updateStateSx,
} from './UpdateAccountModal.style';
import InputField from 'components/FormComponents/InputField/InputField.react';
import './styles.scss';
import { Typography } from '@mui/material';
import { getParticipantUserStatus } from '../Participants.content';
import { checkTernaryCondition } from 'utils/helper';
import { ERROR_MSGS } from 'utils/config';

export const AccountContext = createContext();

export const useAccountContext = () => useContext(AccountContext);

export const UpdateAccount = ({
  isUpdateAccountModalOpen,
  setUpdateAccountModalOpen,
  refreshHandler,
}) => {
  const { setIsLoading } = useContext(loaderContext);
  const [status, setStatus] = useState([]);
  const [participant, setParticipant] = useState();
  const [currentStatus, setCurrentStatus] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const fetchStatus = async () => {
    setIsLoading(true);
    try {
      const {
        params: {
          row: { participantId },
        },
      } = isUpdateAccountModalOpen;

      // chaining for api calls
      await axios
        .get(`${FETCH_PARTICIPANT_DETAILS}?participantId=${participantId}`)
        .then((response) => {
          setParticipant(response.data);
          setCurrentStatus(response.data.userStatus);
          return response.data.userStatus;
        })
        .then((userStatus) => {
          return axios.get(
            `${FETCH_POSSIBLE_USER_STATUS}?userStatus=${userStatus}`,
          );
        })
        .then(({ data }) => {
          const formattedData = data.map(
            ({ displayValue, participantUserStatus }) => ({
              label: displayValue,
              value: participantUserStatus,
            }),
          );
          setStatus(formattedData);
          setSelectedStatus(formattedData?.[0]?.value);
        })
        .catch(() => {
          /* istanbul ignore next */
          displayToast('error', ERROR_MSGS.FETCH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      /* istanbul ignore next */
      displayToast('error', ERROR_MSGS.UNEXPECTED);
    } finally {
      setIsLoading(false);
    }
  };

  const updateAccountStatusSelection = async (e) => {
    setSelectedStatus(e.target.value);
  };

  const saveAccountStatus = async () => {
    setIsLoading(true);
    try {
      const {
        params: {
          row: { participantId },
        },
      } = isUpdateAccountModalOpen;
      await axios.put(
        `${UPDATE_ACCOUNT_STATUS}/${selectedStatus}/${participantId}`,
      );
      closeUpdateAccountModal();
      displayToast('success', `Account status updated successfully`);
      refreshHandler();
    } catch (error) {
      displayToast('error', `Failed to updated account status`);
    } finally {
      setIsLoading(false);
    }
  };

  const closeUpdateAccountModal = () => {
    setUpdateAccountModalOpen((pre) => ({ ...pre, open: false }));
  };

  const isModalOpen = isUpdateAccountModalOpen.open;

  const contextValue = useMemo(
    () => ({
      closeUpdateAccountModal,
      currentStatus,
      setSelectedStatus,
      isModalOpen,
      participant,
      fetchStatus,
      status,
      saveAccountStatus,
      updateAccountStatusSelection,
      selectedStatus,
    }),
    [
      closeUpdateAccountModal,
      currentStatus,
      isModalOpen,
      participant,
      fetchStatus,
      status,
      saveAccountStatus,
      selectedStatus,
    ],
  );

  if (!isUpdateAccountModalOpen.open) {
    return null;
  }

  return (
    <AccountContext.Provider value={contextValue}>
      <UpdateAccountModal />
    </AccountContext.Provider>
  );
};

const UpdateAccountModal = () => {
  const {
    isModalOpen,
    closeUpdateAccountModal,
    fetchStatus,
    saveAccountStatus,
    participant,
    status,
    selectedStatus,
  } = useAccountContext();

  const [confirmationalModalOpen, setConfirmationalModalOpen] = useState(false);

  /**
   * @description toggle confirmational modal state
   */
  const updateConfirmationalModalState = () => {
    setConfirmationalModalOpen((prevState) => !prevState);
  };

  /**
   * @description Confirmational modal confirm button click handler
   */
  const handleConfirmationalModalConfirm = () => {
    closeUpdateAccountModal();
    saveAccountStatus();
    updateConfirmationalModalState();
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  if (confirmationalModalOpen) {
    const participantName = `${participant?.participantFirstName} ${participant?.participantLastName}`;
    // Split the content to insert participant name dynamically
    // &nbsp; will be required to add space between the name and the text
    const confirmationalModalTitle = (
      <ConfirmationalModalContentWrapper>
        {CONFIRMATIONAL_MODAL_CONTENT.split('participantName').map(
          (part, index, array) => {
            const keyVal = index;
            return (
              <React.Fragment key={keyVal}>
                {part}
                {index < array.length - 1 && (
                  <ConfirmationalModalContent
                    title={checkTernaryCondition(
                      participantName?.length > 7,
                      participantName,
                      '',
                    )}>
                    &nbsp;{participantName}&nbsp;
                  </ConfirmationalModalContent>
                )}
              </React.Fragment>
            );
          },
        )}
        <Typography sx={updateStateSx(participantName?.length < 14)}>
          {status?.find((state) => state.value === selectedStatus)?.label}?
        </Typography>
      </ConfirmationalModalContentWrapper>
    );

    return (
      <DialogBox
        dialogActions
        acceptCtnLabel="OK"
        declineCtnLabel="Cancel"
        isOpen={confirmationalModalOpen}
        title={confirmationalModalTitle}
        onCancel={updateConfirmationalModalState}
        onConfirm={handleConfirmationalModalConfirm}
        customSx={confirmationalDialogBoxSx}
      />
    );
  }

  return (
    <DialogBox
      customSx={dialogBoxSx}
      dialogActions
      isOpen={isModalOpen}
      onConfirm={updateConfirmationalModalState}
      onCancel={closeUpdateAccountModal}
      declineCtnLabel="Cancel"
      acceptCtnLabel="Save"
      dialogTitleSx={dialogBoxTitleSx}
      title={MODAL_TITLE}
      customContentSx={modalContentCustomSx}
      customDialogClassName="update-user-status-modal"
      skipTitleCss>
      <AccountStatus />
    </DialogBox>
  );
};

const AccountStatus = () => {
  const {
    status,
    updateAccountStatusSelection,
    currentStatus,
    participant,
    selectedStatus,
  } = useAccountContext();

  const participantName = `${participant?.participantFirstName} ${participant?.participantLastName}`;

  return (
    <div>
      <InputField
        label={MODAL_INPUTS.PARTICIPANT_NAME}
        value={participantName}
        isDisabled
        customInputSx={InputSx}
        testId="participant-name-input"
      />
      <InputField
        label={MODAL_INPUTS.CURRENT_USER_STATUS}
        value={getParticipantUserStatus(currentStatus)}
        isDisabled
        customInputSx={InputSx}
        testId="current-user-status-input"
      />
      <DropDown
        value={selectedStatus}
        onUpdate={updateAccountStatusSelection}
        label={MODAL_INPUTS.UPDATED_USER_STATUS}
        customPlaceholderColor={COOL_GRAY}
        dropdownlist={status}
        showLabelAsValue={true}
        labelMarginTop={0}
        dataTestId="select-status-input"
        hasNoBottomMargin
        {...inputProps}
      />
    </div>
  );
};

UpdateAccountModal.propTypes = {
  isUpdateAccountModalOpen: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      row: PropTypes.shape({
        participantId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  setUpdateAccountModalOpen: PropTypes.func.isRequired,
  refreshHandler: PropTypes.func.isRequired,
};

export default UpdateAccountModal;

UpdateAccount.propTypes = {
  isUpdateAccountModalOpen: PropTypes.shape({
    params: PropTypes.shape({
      row: PropTypes.shape({
        participantId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
