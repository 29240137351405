import { scroll } from 'utils/helper';
import PropTypes from 'prop-types';
import { JumpLink, ItalicsStyleText } from './ImplementedActivitiesV2.style';

export const PAGE_CONTENT = {
  title: 'Implemented activity summary',
  subtitle:
    'Please provide field-level summary data for each activity implemented on this field. Based on the activities you select here, you will need to provide additional information within the detailed operations section below. ',
  activityDropdownBtnLabel: 'Activity',
  acresZeroError:
    'The acreage recorded within the implemented activity section must be greater than zero',
  coverCropNote:
    'You must add at least one cover crop below for field submission.',
  soilAmendmentNote:
    'You must add at least one soil carbon amendment below for field submission.',
  titleForBaseline: 'Historical activity summary',
  optional: '(Optional)',
  subtitleForBaseline: (
    <>
      Although optional, you may provide historical activity summary for this
      baseline year. Please note: the implemented activities selected here are{' '}
      <ItalicsStyleText>not</ItalicsStyleText> taken into consideration for
      payment.
    </>
  ),

  VALID_ACRES: 'Valid acres',
};

export const residueTillageNote = (activity) => {
  return `Implemented activity acreage for ${activity.toLowerCase()} must equal field acreage. Additionally, if updates are made to the residue and tillage management activity type, all tillage events data within the detailed operations section will be deleted.`;
};

residueTillageNote.propTypes = {
  activity: PropTypes.string,
};

export const JumpToOpsData = ({ category }) => {
  switch (category) {
    case 'Nutrient management':
      return (
        <JumpLink onClick={() => scroll('.Fertilizer')}>
          Jump to fertilizer operations data
        </JumpLink>
      );

    case 'Cover crop':
    case 'Soil carbon amendment':
      return (
        <JumpLink onClick={() => scroll('.crops-section')}>
          Jump to crop operations data
        </JumpLink>
      );
  }
};

JumpToOpsData.propTypes = {
  category: PropTypes.string,
};

export const ACTIVITY_FIELDS_ERROR_KEY = {
  SPECIES_CATEGORY_REQUIRED: 'speciesCategoryRequired',
  STRIP_WIDTH_REQUIRED: 'stripWidthRequired',
  BARRIER_WIDTH_REQUIRED: 'barrierWidthRequired',
  ROWS_COUNT_REQUIRED: 'rowsCountRequired',
  SPECIES_DENSITY_REQUIRED: 'speciesDensityRequired',
};

export const INPUT_LABELS = {
  STRPI_WIDTH: 'Strip width',
  BARRIER_WIDTH: 'Barrier width',
  ROW_COUNT: 'No. of rows',
  SPECIES_DENSITY: 'Species density',
};

export const implementedActivities = [
  {
    ACTUAL_TOTAL_ACTIVITY_AREA: [
      {
        dataElementId: 19,
        dataElementName: 'Actual total activity area',
        uomId: 16,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ac',
        uomNameFull: 'acre',
        uomNameDisplay: 'ac',
        uomType: 'area',
        uiModal: 'Implemented activity summary',
      },
    ],
  },
  {
    STRIP_WIDTH: [
      {
        dataElementId: 9,
        dataElementName: 'Strip width',
        uomId: 11,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ft',
        uomNameFull: 'foot',
        uomNameDisplay: 'ft',
        uomType: 'length',
        uiModal: 'Implemented activity summary - edge of field',
      },
    ],
  },
  {
    BARRIER_WIDTH: [
      {
        dataElementId: 11,
        dataElementName: 'Barrier width',
        uomId: 11,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ft',
        uomNameFull: 'foot',
        uomNameDisplay: 'ft',
        uomType: 'length',
        uiModal: 'Implemented activity summary - edge of field',
      },
    ],
  },
  {
    SPECIES_DENSITY: [
      {
        dataElementId: 10,
        dataElementName: 'Species density',
        uomId: 24,
        numeratorUomId: 14,
        denominatorUomId: 16,
        uomName: 'trees planted/ac',
        uomNameFull: 'trees planted per acre',
        uomNameDisplay: 'trees planted / ac',
        uomType: 'ratio',
        uiModal: 'Implemented activity summary - edge of field',
      },
    ],
  },
];
