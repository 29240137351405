import { MenuItem } from '@mui/material';
import { menuItemSx } from '../CustomOptionsButton.style';
import { useActivityContext } from 'containers/ActivityListContainer';

export const AddContractButton = () => {
  const { setAddContractOpen, closeMenu } = useActivityContext();
  const handleClick = () => {
    setAddContractOpen((pre) => ({
      ...pre,
      isOpen: true,
    }));
    closeMenu();
  };

  return (
    <MenuItem sx={menuItemSx} key="addContract" onClick={handleClick}>
      Add Contract
    </MenuItem>
  );
};

export const AddPipelineAcres = () => {
  const { setAddPipelineAcresOpen, closeMenu } = useActivityContext();
  const handleClick = () => {
    setAddPipelineAcresOpen((pre) => ({
      ...pre,
      isOpen: true,
    }));
    closeMenu();
  };

  return (
    <MenuItem sx={menuItemSx} key="addPipelineAcres" onClick={handleClick}>
      Add pipeline acres
    </MenuItem>
  );
};
export const EditPipelineAcres = () => {
  const { setEditPipelineAcresOpen, closeMenu } = useActivityContext();
  const handleClick = () => {
    setEditPipelineAcresOpen((pre) => ({
      ...pre,
      isOpen: true,
    }));
    closeMenu();
  };

  return (
    <MenuItem sx={menuItemSx} key="editPipelineAcres" onClick={handleClick}>
      Edit pipeline acres
    </MenuItem>
  );
};

export const EditContractButton = () => {
  const { setAddContractOpen, closeMenu } = useActivityContext();

  const openEditContractModal = () => {
    setAddContractOpen((pre) => ({
      ...pre,
      isOpen: true,
      editMode: true,
    }));
    closeMenu();
  };

  return (
    <MenuItem
      sx={menuItemSx}
      key="editContract"
      onClick={openEditContractModal}>
      Edit Contract
    </MenuItem>
  );
};

export const ViewContractButton = () => {
  const {
    activity: { contractPdfUrl },
  } = useActivityContext();
  const viewContract = () => {
    window.open(contractPdfUrl);
  };
  return (
    <>
      {!(
        contractPdfUrl === null ||
        contractPdfUrl === '' ||
        contractPdfUrl === undefined
      ) && (
        <MenuItem sx={menuItemSx} key="viewContract" onClick={viewContract}>
          View Contract
        </MenuItem>
      )}
    </>
  );
};

export const RequestAdditionalEnrollment = () => {
  const {
    setEnrollModalData,
    participantId,
    projectId,
    participantName,
    activity,
    closeMenu,
  } = useActivityContext();
  const handleRequestEnrollment = () => {
    setEnrollModalData((pre) => ({
      ...pre,
      flag: true,
      id: Number(participantId),
      projectData: [],
      projectId: projectId,
      participantName: participantName,
      contractId: activity.associatedContractId,
      instanceId: null,
      shouldGenerateNewInstance: true,
    }));
    closeMenu();
  };

  if (!activity.isRequestEnrollmentEnabled) {
    return null;
  }

  return (
    <MenuItem
      sx={menuItemSx}
      key="requestEnrollment"
      onClick={handleRequestEnrollment}>
      Request additional enrollment
    </MenuItem>
  );
};
