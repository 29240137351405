import { checkboxClasses, outlinedInputClasses } from '@mui/material';
import styled from 'styled-components';
import {
  DARK_CHARCOAL,
  COOL_GRAY,
  BLUE,
  RED,
  DARK_GREEN,
  QUILL_GRAY,
  GRAYISH_BLUE,
  GLOBAL_BLUE,
  NEW_DARK_GREEN,
  NEW_HOVER_DARK_GREEN,
  NEW_FOCUS_DARK_GREEN,
  FOCUSED_BORDER,
  NEW_HOVER_GREEN_BACKGROUND,
  TEAL_GREEN_FOCUS,
  TEAL_GREEN_HOVER,
  WHITE,
  RED_HOVER,
  RED_FOCUS,
  RED_FOCUSED_BORDER,
  BLUISH_CYAN,
  BLACK,
  EMPTY_STATE_TEXT_COLOR,
  TYPOGRAPHY_GRAY,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
export const MainWrapper = styled.div`
  background-color: ${({ color }) => color};
  display: flex;
  display: -webkit-flex;
  flex-direction: ${({ stackedView }) =>
    checkTernaryCondition(stackedView, 'column', 'row')};
  gap: ${({ gap }) => gap};
  flex-wrap: wrap;
  direction: ${({ direction }) => direction};
  justify-content: ${({ content }) => content};
`;
export const FormLabels = styled.p`
  display: flex;
  display: -webkit-flex;
  align-items: ${({ alignItems }) => alignItems ?? ''};
  flex-direction: ${({ flexDirection }) => flexDirection ?? 'column'};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '0.625rem'};
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  font-size: ${({ fontSize }) => fontSize ?? '0.875rem'};
  line-height: ${({ lineHeight }) => lineHeight ?? '1.225rem'};
  color: ${(props) =>
    checkTernaryCondition(props.color, props.color, DARK_CHARCOAL)};
  margin-top: ${({ marginTop }) => marginTop};
  text-wrap: wrap;
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap};
`;
export const RightSideFormLabel = styled.p`
  margin-bottom: 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${(props) =>
    props.iserror
      ? RED
      : checkTernaryCondition(props.color, props.color, COOL_GRAY)};
  margin-top: ${({ marginTop }) => marginTop ?? '0.4rem'};
  margin-left: ${({ marginLeft }) => marginLeft ?? '1rem'};
`;

export const LeftSideFormLabel = styled.p`
  margin-bottom: 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${(props) =>
    props.iserror
      ? RED
      : checkTernaryCondition(props.color, props.color, COOL_GRAY)};
  margin-top: -1.5rem;
  margin-left: -1rem;
`;
export const FormHeading = styled.div`
  margin-bottom: 1rem;
  margin-top: ${({ marginTop }) => marginTop ?? '0rem'};
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 140%;
  color: ${DARK_CHARCOAL};
  white-space: nowrap;
`;
export const FormFields = styled.div`
  margin-bottom: ${({ hasNoBottomMargin }) =>
    hasNoBottomMargin ? '' : '1rem'};
  height: fit-content;
  input {
    padding-left: 8px;
  }
  pointer-events: ${(props) =>
    checkTernaryCondition(props.readOnly || props.nonEditable, 'none', '')};
  opacity: ${(props) => (props.readOnly ? 0.5 : 1)};
  min-width: ${({ minWidth }) => minWidth || '20rem'};
  display: ${({ display }) => display};
  flex-direction: ${({ direction }) => direction};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  gap: ${({ gap }) => gap};
  align-items: ${({ alignItems }) => alignItems};
  margin-top: ${({ marginTop }) => marginTop};
`;
export const RadioButtonlabel = styled.span`
  margin-left: 0.5rem;
  margin-right: 5.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
`;
export const radioBtnSx = {
  width: '1.125rem',
  height: '1.125rem',
  '& .MuiSvgIcon-root': { width: '1.125rem', height: '1.125rem' },
};
export const ButtonWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  margin-top: 24px;
  :first-child {
    margin-right: 24px;
  }
`;
export const OptionalLabel = styled.span`
  font-size: ${({ fontSize }) => fontSize ?? '0.75rem'};
  line-height: ${(props) => props.lineHeight ?? '1.05rem'};
  letter-spacing: 0.013rem;
  color: ${({ color }) => checkTernaryCondition(color, color, COOL_GRAY)};
  opacity: ${({ opacity }) => checkTernaryCondition(opacity, opacity, 1)};
  margin-left: ${({ marginLeft }) => marginLeft ?? '1rem'};
  margin-right: 0.25rem;
`;
export const FormSection = styled.div`
  max-width: 37.5rem;
  width: 47%;
  height: fit-content;
  margin-top: 3.5rem;
  background-color: white;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  box-shadow: 0px 0.125rem 0.188rem rgba(0, 0, 0, 0.1);
`;
export const AddProjectWrapper = styled.div`
  cursor: pointer;
  margin-top: 1rem;
`;
export const InstructionWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${COOL_GRAY};
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  margin: ${({ margin }) => margin};
`;
export const AddprojectLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${BLUE};
  margin-left: 0.375rem;
`;
export const TextArea = styled.textarea`
  resize: none;
  width: 98%;
  height: 200px;
  border: 1px solid ${GRAYISH_BLUE};
  font-family: 'Open Sans';
  font-style: normal;
  padding: 8px;
  &::placeholder {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    opacity: 0.5;
    color: ${COOL_GRAY};
    visibility: hidden;
  }
  &:hover {
    outline: none;
    border: 2px solid ${DARK_CHARCOAL};
    padding: 7.2px;
  }
  &:focus {
    outline: none;
    border: 2px solid ${DARK_GREEN};
    padding: 7.2px;
  }
`;
export const RequiredSubtext = styled.span`
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.013rem;
  color: ${(props) => (props.iserror ? RED : COOL_GRAY)};
  opacity: ${(props) => (props.iserror ? 1 : 0.5)};
  margin-left: 0.5rem;
`;
export const ErrorWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 1rem;
  letter-spacing: ${({ letterSpacing }) => letterSpacing ?? '0.013rem'};
  color: ${RED};
  margin-top: ${({ marginTop }) => marginTop};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  max-width: ${({ maxWidth }) => maxWidth};
`;
export const RightSideLabelErrorWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 1rem;
  letter-spacing: 0.013rem;
  color: ${RED};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '0.625rem'};
`;
export const inputNumberSx = {
  width: '8.125rem',
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
};
export const DropDownPlaceholder = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  visibility: hidden;

  color: ${({ customPlaceholderColor }) => customPlaceholderColor};
`;
export const InputSubtext = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${COOL_GRAY};
  opacity: 0.5;
  margin-left: 1rem;
`;
export const LabelToolTipWrapper = styled.div`
  justify-content: ${({ hasCenterTooltip }) =>
    hasCenterTooltip ? 'space-between' : ''};
  width: ${({ hasCenterTooltip }) => (hasCenterTooltip ? '90%' : '100%')};
  font-size: 0.875rem;
  color: ${(props) =>
    checkTernaryCondition(props.color, props.color, DARK_CHARCOAL)};
`;
export const ToolTipAndAlertWrapper = styled.span`
  display: inline-block;
`;
export const ToolTipSx = {
  marginBottom: '-0.3rem',
  marginRight: '0.25rem',
};
export const AlertIconSx = {
  marginBottom: '-0.075rem',
};
export const LabelWrapper = styled.div`
  display: inline-block;
  color: ${({ color }) => color};
`;
export const LabelTextWrapper = styled.div`
  width: ${({ hasError, labelWidth }) =>
    hasError && labelWidth ? labelWidth : 'auto'};
  display: ${({ hasElementAsLabel }) =>
    checkTernaryCondition(hasElementAsLabel, 'inline-block', 'inline')};
  margin-right: ${({ marginRight }) => marginRight ?? 0};
`;
export const borderStyle = { borderTop: '1px solid #D0D0CE' };
export const ErroWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 1rem;
  letter-spacing: 0.013rem;
  color: ${RED};
  margin-top: ${({ marginTop }) => marginTop};
`;

export const ManualErrorWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  gap: 0.25rem;
  flex-direction: column;
`;
export const ValueWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${DARK_CHARCOAL};
  margin-left: ${(props) => (props.isDeleted ? '0.5rem' : '0rem')};
`;
export const ListWrapper = styled.ul`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${DARK_CHARCOAL};
  margin: 0;
  list-style-type: none;
  padding: 0;
  margin-left: ${(props) => (props.isDeleted ? '0.5rem' : '0rem')};
`;
export const MaxCharErrorWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
`;

export const DeleteWrapper = styled.div`
  cursor: pointer;
  display: inline;
`;
export const TextWrapper = styled.div`
  display: ${({ display }) => display};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  max-width: ${({ maxWidth }) => maxWidth};
  flex-wrap: ${({ wrap }) => wrap};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
`;
export const InputWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  gap: 1rem;
`;

export const Asterisk = styled.span`
  &:after {
    content: ' *';
  }
`;

export const PredefinedUnitWrapper = styled.span`
  color: ${COOL_GRAY};
  margin-left: 0.5rem;
  font-size: 0.875rem;
`;

export const InputSx = {
  '& .MuiInput-underline:after': {
    borderBottomColor: DARK_GREEN,
  },
  '&& .Mui-disabled': {
    opacity: 1,
  },
};
export const FocusedInputSx = {
  '&.MuiInput-underline.Mui-focused:after': {
    borderBottomColor: DARK_GREEN,
  },
};
export const OutlinedInputSx = {
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: DARK_GREEN,
  },
};
export const DropdownSx = {
  [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: DARK_GREEN,
    },
};
export const DropdownInputSx = {
  '&.MuiInput-underline:after': {
    borderBottomColor: DARK_GREEN,
  },
};
export const CheckboxSx = {
  color: GRAYISH_BLUE,
  '&:hover': {
    color: DARK_CHARCOAL,
  },
  [`&.${checkboxClasses.checked}`]: {
    color: NEW_DARK_GREEN,
  },
};
export const primaryButtonStyle = (padding) => {
  return {
    fontWeight: '400',
    textTransform: 'none',
    backgroundColor: NEW_DARK_GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: NEW_HOVER_DARK_GREEN,
      color: WHITE,
    },
    '&:focus': {
      backgroundColor: NEW_FOCUS_DARK_GREEN,
      border: `2px solid ${FOCUSED_BORDER}`,
      padding: padding ?? '4.5px 14.5px',
      color: WHITE,
    },
    '&.Mui-disabled.MuiButton-root': {
      color: TYPOGRAPHY_GRAY,
      backgroundColor: WHITE,
      border: `1px solid ${TYPOGRAPHY_GRAY}`,
    },
  };
};
export const tertiaryButtonStyle = {
  fontWeight: '400',
  textTransform: 'none',
  color: NEW_DARK_GREEN,
  '&:hover': {
    color: NEW_HOVER_DARK_GREEN,
  },
  '&:focus': {
    color: TEAL_GREEN_FOCUS,
  },
};
export const deletePrimaryButtonStyle = (padding) => {
  return {
    fontWeight: '400',

    textTransform: 'none',
    backgroundColor: RED,
    color: WHITE,
    '&:hover': {
      backgroundColor: RED_HOVER,
      color: WHITE,
    },
    '&:focus': {
      backgroundColor: RED_FOCUS,
      border: `2px solid ${RED_FOCUSED_BORDER}`,
      padding: padding ?? '4.5px 14.5px',
      color: WHITE,
    },
  };
};
export const deleteTertiaryButtonStyle = {
  textTransform: 'none',
  fontWeight: '400',
  color: RED,
  '&:hover': {
    color: RED_HOVER,
  },
  '&:focus': {
    color: RED_FOCUS,
  },
};
export const secondaryButtonStyle = {
  fontWeight: '400',
  textTransform: 'none',
  color: NEW_DARK_GREEN,
  border: `1px solid ${NEW_DARK_GREEN}`,
  '&:hover': {
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: NEW_FOCUS_DARK_GREEN,
  },
  '&:focus': {
    color: NEW_FOCUS_DARK_GREEN,
    backgroundColor: TEAL_GREEN_HOVER,
    border: `2px solid ${NEW_DARK_GREEN}`,
    padding: '4.5px 15px',
  },
  '&.Mui-disabled': {
    color: BLACK,
    backgroundColor: QUILL_GRAY,
    border: 'none',
  },
};

export const DropZoneWrapper = styled.div`
  width: 100%;
`;

export const UploadWrapper = styled.div`
  width: ${({ width }) => width || '100%'};
  border: 0.063rem dashed ${GRAYISH_BLUE};
  border-radius: 0.125rem;
  margin: auto;
  cursor: pointer;
  height: 70px;
  box-sizing: border-box;
`;

export const DragAndDropText = styled.div`
  color: ${COOL_GRAY};
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  text-align: center;
`;

export const FileUploadSubtext = styled.div`
  color: ${GLOBAL_BLUE};
  font-weight: 600;
  display: inline;
`;

export const AcceptedFileItem = styled.div`
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin: 0.25rem 0rem;
`;

export const FileName = styled.div`
  padding: 0.25rem 0.5rem;
  border: ${(props) =>
    checkTernaryCondition(
      props.isError,
      `1px solid ${RED}`,
      `1px solid ${QUILL_GRAY}`,
    )};
  border-radius: 2px;
  flex: 1;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  color: ${COOL_GRAY};
  font-size: 0.875rem;
  /* height: 1.75rem; */
  display: flex;
  display: -webkit-flex;
  align-items: center;
`;

export const deleteIconSx = {
  color: COOL_GRAY,
  cursor: 'pointer',
  height: '1.5rem',
  width: '1.5rem',
  ':hover': {
    color: DARK_GREEN,
  },
};

export const deleteFmsIconSx = {
  color: COOL_GRAY,
  cursor: 'pointer',
  height: '1.5rem',
  width: '1.5rem',
  ':hover': {
    color: BLUISH_CYAN,
  },
};
export const MultiFieldWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  direction: ${({ direction }) => direction};
  gap: ${({ gap }) => gap};
  align-items: ${({ alignment }) => alignment ?? 'end'};
`;
export const InputInfoToolTipSx = { paddingTop: 0 };
export const DropDownToolTipStyle = {
  height: '1.2rem',
  width: '1.2rem',
  color: COOL_GRAY,
};

export const approvalModalButtonStyle = (darkGreenBtnColor) => {
  return {
    textTransform: 'none',
    fontWeight: '400',
    backgroundColor: checkTernaryCondition(
      darkGreenBtnColor,
      DARK_GREEN,
      NEW_DARK_GREEN,
    ),
    '&:hover': {
      backgroundColor: checkTernaryCondition(
        darkGreenBtnColor,
        DARK_GREEN,
        NEW_HOVER_DARK_GREEN,
      ),
      color: WHITE,
    },
    height: '2.25rem',
    width: '12.75rem',
    fontSize: '0.875rem',
    lineHeight: '1.192rem',
    letterSpacing: '0rem',
  };
};

export const approvalModalDialogStyle = (
  buttonGap,
  primaryButtonsMarginTop,
) => {
  return {
    padding: 0,
    gap: buttonGap,
    marginTop: primaryButtonsMarginTop,
  };
};
export const disabledButtonStyle = {
  color: EMPTY_STATE_TEXT_COLOR,
  background: QUILL_GRAY,
  fontWeight: '400',
  textTransform: 'none',
  '&.Mui-disabled': {
    color: EMPTY_STATE_TEXT_COLOR,
    background: QUILL_GRAY,
  },
};

export const toolTipStyle = { marginLeft: '0.5rem', paddingLeft: 0 };
