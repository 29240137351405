import './AddVendor.scss';
import { Input, Button, Select, OutlinedInput, MenuItem } from '@mui/material';
import { PAGE_CONTENT } from './AddVendor.content';
import { useEffect, useState } from 'react';
import { useForm } from '../../hooks/useForm';
import { useLocation, useNavigate } from 'react-router';
import BackButton from 'components/Backbutton/BackButton.react';
import { useVendorDetails } from 'hooks/useVendorDetails';
import { STAKEHOLDER_ORG_CHECK, VENDOR_BY_ID } from 'urls';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { checkTernaryCondition, classNameHandler, scroll } from 'utils/helper';
import { usDateFormatter } from '../../utils/dateFormatter';
import { pathName } from 'Routes/routes.const';
import { getMenuItemStyles } from 'theme/GlobalStyles';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import { ERROR_MSGS } from 'utils/config';

const formModal = {
  vendorType: '',
};

const VENDOR_TYPES = [
  { label: 'Supplier', value: 'Supplier' },
  { label: 'Customer', value: 'Customer' },
  { label: 'Investor', value: 'Investor' },
  { label: 'Financer', value: 'Financer' },
];

const AddVendor = () => {
  const [vendorOrganization, setVendorOrganization] = useState(''); // Vendor organization typeahead input
  const [vendorWebsite, setVendorWebsite] = useState('');
  const [notes, setNotes] = useState('');
  const [editStakeHolderName, setEditStakeHolderName] = useState('');
  const [vendorId, setVendorId] = useState(null);
  //custom form hook for input fields
  const { formValue, formFieldChangeHandler, customFieldChangeHandler } =
    useForm(formModal);
  const { postVendorDetail, loading } = useVendorDetails();

  const { state } = useLocation(); // for previous pathname
  const navigate = useNavigate();

  //Error states
  const [vendorOrgError, setVendorOrgError] = useState(false);
  const [vendorTypeError, setVendorTypeError] = useState(false);
  const [webSiteError, setWebSiteError] = useState(false);
  const [duplicateOrgError, setDuplicateOrgError] = useState(false);

  const { id } = useParams();
  //Edit mode flag
  const [editMode, setEditMode] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [noEditData, setNoEditData] = useState('');

  //Deleted state
  const [stakeholderData, setStakeholderData] = useState();
  const [isDeletedRecord, setIsDeleted] = useState();

  const [onSubmit, setOnSubmit] = useState(true);

  //vendor website change handler
  const vendorWebsiteHandler = (e) => {
    setVendorWebsite(e.target.value);
  };

  const isValidURL = (string) => {
    const res = string.match(
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
    );
    return res !== null;
  };

  const onWebSiteBlur = (e) => {
    if (!isValidURL(e.target.value) && e.target.value !== '') {
      setWebSiteError(true);
    } else {
      setWebSiteError(false);
    }
  };

  const notesHandler = (e) => {
    setNotes(e.target.value);
  };

  //Vendor organiztion input field change handler
  const vendorOrgHandler = (e) => {
    setVendorOrganization(e.target.value);
    setDuplicateOrgError(false);
    if (e.target.value.trim()) {
      setVendorOrgError(false);
    }
  };

  //To check for duplicate stakeholders
  const checkVendorOrganization = (vendorOrg, vendorType) => {
    let requestParams = '';
    requestParams = editMode
      ? `vendorOrganization=${vendorOrg}&vendorType=${vendorType}&vendorId=${id}`
      : `vendorOrganization=${vendorOrg}&vendorType=${vendorType}`;
    axios
      .get(`${STAKEHOLDER_ORG_CHECK}?${requestParams}`)
      .then((res) => {
        setDuplicateOrgError(!res.data.isVendorOrganizationValid);
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      });
  };

  useEffect(() => {
    if (vendorOrganization.trim() && formValue.vendorType !== '') {
      checkVendorOrganization(vendorOrganization.trim(), formValue.vendorType);
    }
  }, [vendorOrganization, formValue.vendorType]);

  //Edit project fetch and populate data
  const fetchEditData = async () => {
    if (id) {
      setEditMode(true);
      setEditLoading(true);
      await axios
        .get(VENDOR_BY_ID + `/${id}`)
        .then((response) => {
          if (response.data) {
            setIsDeleted(response.data.isActive == -1 ? true : false);
            setStakeholderData(response.data);
            setVendorWebsite(response.data.vendorWebsite);
            setNotes(response.data.notes);
            setVendorId(response.data.vendorId);
            setVendorOrganization(response.data.vendorOrganization);
            setEditStakeHolderName(response.data.vendorOrganization);
            customFieldChangeHandler('vendorType', response.data.vendorType);
          } else {
            setNoEditData('Invalid Stakeholder data');
          }
        })
        .catch(() => {
          setNoEditData('Invalid Stakeholder data');
        })
        .finally(() => {
          setEditLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchEditData();
    return () => {
      setEditMode(false);
    };
  }, []);

  //Vendor type radio handler
  const vendorTypeHandler = (e) => {
    formFieldChangeHandler(e);
    setVendorTypeError(false);
    setDuplicateOrgError(false);
  };

  //Submit handler
  const submitHandler = () => {
    setOnSubmit(!onSubmit);
    if (formValue.vendorType === '') {
      setVendorTypeError(true);
    } else {
      setVendorTypeError(false);
    }
    !vendorOrganization.trim()
      ? setVendorOrgError(true)
      : setVendorOrgError(false);
    if (
      formValue.vendorType !== '' &&
      vendorOrganization.trim() &&
      !webSiteError &&
      !duplicateOrgError
    ) {
      let temp_request_body = {
        clientId: 100,
        vendorId: vendorId !== null ? vendorId : 0,
        vendorOrganization: vendorOrganization,
        vendorWebsite: vendorWebsite,
        notes: notes,
        vendorType: formValue.vendorType,
      };
      postVendorDetail(temp_request_body, editMode);
    }
  };

  //to scroll to error field on submit
  useEffect(() => {
    scroll('.Mui-error');
  }, [onSubmit]);

  return (
    <div>
      {editLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}>
          <CircularProgress color="success" />
        </Backdrop>
      )}
      {
        <BackButton
          previouspath={
            state?.previousPath
              ? state?.previousPath
              : pathName.assets.stakeholders
          }
          previouspagename={checkTernaryCondition(
            editMode,
            'stakeholders',
            checkTernaryCondition(
              state?.previousPath,
              state?.previousPath.substring(1),
              'portfolio',
            ),
          )}
          marginLeft="2rem"
        />
      }
      {noEditData !== '' && editMode ? (
        noEditData
      ) : (
        <div className="vendor_form_wrapper">
          <div className="vendor_from_section">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="vendor_form_heading">
                {editMode
                  ? `Edit ${editStakeHolderName}'s Profile`
                  : PAGE_CONTENT.vendor_form_heading}
              </div>
              {isDeletedRecord && (
                <div className="deletedStatus">{`${PAGE_CONTENT.deletedLabel} 
                
                  ${
                    stakeholderData.updatedDate !== null &&
                    usDateFormatter(stakeholderData.updatedDate)
                  }`}</div>
              )}
            </div>

            <div
              className={classNameHandler(
                editMode,
                'vendor_form_fields readonly',
                'vendor_form_fields',
              )}>
              <p
                className={classNameHandler(
                  vendorTypeError,
                  'vendor_field_label error',
                  'vendor_field_label',
                )}>
                {PAGE_CONTENT.vendor_type_label}
                <span
                  className={classNameHandler(
                    vendorTypeError,
                    'required_field error',
                    'required_field',
                  )}>
                  {PAGE_CONTENT.required_field_subtext}
                </span>
              </p>
              <Select
                value={formValue.vendorType}
                onChange={(e) => {
                  vendorTypeHandler(e);
                }}
                className="vendor_Type_dropdown"
                name="vendorType"
                displayEmpty
                error={vendorTypeError}
                inputProps={{ 'aria-label': 'vendorType' }}
                input={<OutlinedInput />}
                renderValue={
                  formValue.vendorType !== ''
                    ? undefined
                    : () => (
                        <div className="registry_select_placeholder">
                          {PAGE_CONTENT.vendor_type_placeholder}
                        </div>
                      )
                }>
                {VENDOR_TYPES.map((vendor) => (
                  <MenuItem
                    key={vendor.value}
                    value={vendor.value}
                    sx={getMenuItemStyles}>
                    {vendor.label}
                  </MenuItem>
                ))}
              </Select>
              {vendorTypeError && (
                <div className="error_message">
                  {PAGE_CONTENT.vendor_type_error}
                </div>
              )}
            </div>

            <div className="vendor_form_fields">
              <p
                className={
                  vendorOrgError || duplicateOrgError
                    ? 'vendor_field_label error'
                    : 'vendor_field_label'
                }>
                {PAGE_CONTENT.vendor_organization_label}
                <span
                  className={classNameHandler(
                    vendorOrgError,
                    'required_field error',
                    'required_field',
                  )}>
                  {PAGE_CONTENT.required_field_subtext}
                </span>
              </p>
              <Input
                placeholder={PAGE_CONTENT.vendor_organization_placeholder}
                error={vendorOrgError || duplicateOrgError}
                value={vendorOrganization}
                name="vendorOrganization"
                onChange={vendorOrgHandler}
                inputProps={{ 'aria-label': 'vendor Org' }}
                fullWidth
                disabled={isDeletedRecord}
              />
              {vendorOrgError && (
                <span className="error_message">
                  {PAGE_CONTENT.vendor_organization_error}
                </span>
              )}
              {duplicateOrgError && (
                <div className="error_message">
                  {PAGE_CONTENT.vendor_duplicate_error_1} {formValue.vendorType}{' '}
                  . {PAGE_CONTENT.vendor_duplicate_error_2}
                </div>
              )}
            </div>

            <div className="vendor_form_fields">
              <p
                className={classNameHandler(
                  webSiteError,
                  'vendor_field_label error',
                  'vendor_field_label',
                )}>
                {PAGE_CONTENT.vendor_website_label}
              </p>
              <Input
                placeholder={PAGE_CONTENT.vendor_website_placeholder}
                value={vendorWebsite}
                name="vendorWebsite"
                error={webSiteError}
                onBlur={onWebSiteBlur}
                onChange={vendorWebsiteHandler}
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth
                disabled={isDeletedRecord}
              />
              {webSiteError && (
                <div className="error_message">
                  {PAGE_CONTENT.vendor_website_error}
                </div>
              )}
            </div>

            <div className="vendor_form_fields">
              <p className="vendor_field_label">
                {PAGE_CONTENT.vendor_notes_label}
              </p>
              <textarea
                placeholder={PAGE_CONTENT.vendor_notes_placeholder}
                value={notes}
                name="notes"
                onChange={notesHandler}
                disabled={isDeletedRecord}
                className="text_area"></textarea>
            </div>

            <div className="vendor_form_buttons">
              <Button
                variant="contained"
                size="medium"
                sx={{ textTransform: 'none' }}
                color="success"
                onClick={submitHandler}
                disableElevation
                disabled={loading || isDeletedRecord}>
                {PAGE_CONTENT.vendor_submit_button}
              </Button>
              <Button
                size="medium"
                color="success"
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  navigate(-1);
                }}>
                {PAGE_CONTENT.vendor_cancel_button}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddVendor;
