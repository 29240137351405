import React, { useContext, useEffect, useState } from 'react';
import { SideNavWrapper, NavContainer } from './Sidenav.style';
import NavModule from 'components/NavModule/NavModule.react';
import { navLinks } from './Sidenav.constants';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { checkAccess } from 'utils/helper';
import {
  ADMIN,
  ERROR_MSGS,
  ModulesEnableMapper,
  getIsModuleEnabled,
} from 'utils/config';
import axios from 'axios';
import { FETCH_PROJECT_LIST } from 'urls';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';

const Sidenav = () => {
  const { userRoles, modulesListData } = useContext(userAuthRolesContext);
  const [projectList, setProjectList] = useState([]);

  const fetchOriginationProjectList = () => {
    axios
      .post(FETCH_PROJECT_LIST, {
        pageNumber: 0,
        pageSize: 1000,
        commercialPurpose: [],
        projectName: '',
        sortFilter: { column: 'updatedDate', order: 'DESC' },
      })
      .then((response) => {
        setProjectList((old) => ({
          ...old,
          isLoading: false,
          data: response.data.content.sort((a, b) =>
            a.projectName.localeCompare(b.projectName),
          ),
          total: response.data.totalElements,
        }));
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      });
  };
  useEffect(() => {
    fetchOriginationProjectList();
  }, []);

  return (
    <SideNavWrapper data-testid="sidenav">
      <NavContainer>
        <NavModule
          navLinks={navLinks.originationModuleLinks.map((link) => ({
            ...link,
            disabled:
              userRoles.length !== 0 &&
              !checkAccess(userRoles, ADMIN) &&
              (link.title === 'Audit Log' || link.title === 'Settings'),
            isModuleEnabled: getIsModuleEnabled(
              ModulesEnableMapper[link.moduleKey],
              modulesListData,
            ),
            projectList: projectList,
          }))}
          hasNoBorder={true}
        />
      </NavContainer>
    </SideNavWrapper>
  );
};

export default Sidenav;
