import React, { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { checkTernaryCondition } from 'utils/helper';
import FossilFuelTypeModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/FossilFuelTypeModal/FossilFuelTypeModal.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { PAGE_CONTENT } from '../BaseLineReporting/BaseLineReportingMainPage.content';
import { Button } from '@mui/material';
import {
  ButtonsWrapper,
  buttonStyleClose,
  fieldLabelStyle,
  fieldUnitStyle,
  secondaryLabelStyle,
  CopyDisclaimer,
  smallBtn,
  NavigationBtnWrapper,
} from './TabBodyComponent.style';
import {
  BASELINE_MODAL_CONTENT,
  tractorVehicleList,
  HeadersComp,
} from './TabBodyComponent.content';
import BaselineAccordionDisplayBox from './AccordionDisplayBox/AccordionDisplayBox.react';
import { FETCH_BASELINE_FARM_DATA } from 'urls';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import CopyModal from './CopyModal/CopyModal.react';
import DialogBox from 'components/DialogBox/DialogBox.react';
import {
  primaryButtonStyle,
  secondaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import {
  copyFossilFuelRepeatedItems,
  editStateSetter,
  initialDeleteState,
  onClickEmptyState,
} from './TabBodyComponent.functions';
import { useReportingApis } from 'hooks/useReportingApis';
import UserNavigationButtonsV2 from '../components/UserNavigationButtonsV2/UserNavigationButtonsV2.react';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import { pathName } from 'Routes/routes.const';

const modalOpenState = { flag: false, id: 0, data: null, currentRowId: 0 };

const TabBodyComponent = ({
  year,
  type,
  sampleId,
  setLoading,
  fieldType,
  farmId,
  enrollmentYearData,
  setFieldDetails,
  setSubmitData,
  saveDataHandler,
  setIsInitialLoad,
  fetchLastSectionData,
  setFetchLastSectionData,
  setUserActivityDetected,
  setEnrollmentYears = () => null,
  typeOfReporting,
  isInActivity = false,
  // approvedFarmIds is common prop name for baselineApprovedFarmIds and actityApprovedFarmIds
  approvedFarmIds = [],
  activityReportingMappingModalErrorMessage = '',
  handleCancel = () => {},
  phase,
  shouldNavigateToLandingPage,
  isBaselineOptionalVisible = false,
  fetchBaselineYears = () => {
    /* do nothing here */
  },
  updateReportingStatus = () => {
    /* do nothing here */
  },
  isParticipantContributor = false,
  isEditable = true,
  isParticipantOwner = false,
  triggerCurrentFarmDataFetch = false,
  setTriggerCurrentFarmDataFetch = () => {},
}) => {
  const { fossilFuelTypeDropdown } = useContext(baselineDropdownContext);
  const { reportingFetchAPI } = useReportingApis();
  const [parentState, setParentState] = useState([]);

  const [isTractorModalOpen, setIsTractorModalOpen] = useState(modalOpenState);
  const [deleteData, setDeleteData] = useState(initialDeleteState);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [isReplacePromptOpen, setIsReplacePromptOpen] = useState(false);
  const [replacePromptItems, setReplacePromptItems] = useState([]);
  const [replacePromptIndex, setReplacePromptIndex] = useState(-1);
  const navigate = useNavigate();
  const disableAllFarmActions = approvedFarmIds?.includes(farmId);
  const addTractorVehicleBtnDisabled = fossilFuelTypeDropdown.every(
    (dropdownItem) =>
      parentState.some((item) => item.fossilFuelType === dropdownItem.value),
  );
  const {
    unitOfMeasureList: { tractorVehicleFuel },
  } = useContext(uomContext);
  /**
   * @description Setting user activity flag to true to track web form changes and prompt user to save if they try closing browser window/tab
   */
  const makePageDirty = () => setUserActivityDetected(true);

  const [searchParams] = useSearchParams();
  const participantId = searchParams.get('participantid');
  const projectCycleId = searchParams.get('projectcycleid');

  const toggleCopyModalOpen = () => {
    setCopyModalOpen((prev) => !prev);
  };
  const submitHandler = (data, id) => {
    const updatedData = checkTernaryCondition(
      parentState.some((item) => item.id === id),
      parentState.map((item) =>
        checkTernaryCondition(item.id === id, data, item),
      ),
      [...parentState, { ...data, id: id, isCopied: false }],
    );
    setParentState(updatedData);
    setIsInitialLoad(false);
    makePageDirty();
  };

  const handleClickEdit = (id, modalType, parentId) => {
    setIsInitialLoad(false);

    const data = parentState.find((item) => item.id === id);
    if (modalType === PAGE_CONTENT.tractor_and_vehicle) {
      editStateSetter(setIsInitialLoad, year, id, setIsTractorModalOpen, data);
    }
  };

  const handleClickDelete = (parentId = null) => {
    makePageDirty();
    setIsInitialLoad(false);

    if (deleteData?.isWholeModalDeleted) {
      setParentState([]);
    } else {
      const filterData = parentState.filter((obj) => obj.id !== deleteData?.id);
      setParentState(filterData);
    }

    setDeleteData({
      id: null,
      isWholeModalDeleted: false,
      modalType: '',
      practiceTypeValue: '',
      deleteModalHeading: '',
      deleteModalContent: '',
      parentId: null,
      cropType: '',
    });
  };

  const handleDeleteData = (
    isWholeModalDeleted,
    modalType,
    practiceTypeValue,
    cropType,
  ) => {
    if (isWholeModalDeleted && modalType !== PAGE_CONTENT.cash_crop_label) {
      if (cropType !== '') {
        return {
          deleteModalHeading: `${PAGE_CONTENT.delete_heading_first_part} ${year} - ${cropType} - ${modalType}`,
          deleteModalContent: `${PAGE_CONTENT.delete_content_firt_part} ${year} - ${cropType} - ${modalType}${PAGE_CONTENT.delete_content_second_part}`,
        };
      } else {
        return {
          deleteModalHeading: `${PAGE_CONTENT.delete_heading_first_part} ${year} - ${modalType}`,
          deleteModalContent: `${PAGE_CONTENT.delete_content_firt_part} ${year} - ${modalType}${PAGE_CONTENT.delete_content_second_part}`,
        };
      }
    } else if (cropType !== '') {
      return {
        deleteModalHeading: `${PAGE_CONTENT.delete_heading_first_part} ${year} - ${cropType} - ${modalType} - ${practiceTypeValue}`,
        deleteModalContent: `${PAGE_CONTENT.delete_content_firt_part} ${year} - ${cropType} - ${modalType} - ${practiceTypeValue}${PAGE_CONTENT.delete_content_second_part}`,
      };
    } else {
      return {
        deleteModalHeading: `${PAGE_CONTENT.delete_heading_first_part} ${year} - ${modalType} - ${practiceTypeValue}`,
        deleteModalContent: `${PAGE_CONTENT.delete_content_firt_part} ${year} - ${modalType} - ${practiceTypeValue}${PAGE_CONTENT.delete_content_second_part}`,
      };
    }
  };

  useEffect(() => {
    if (deleteData?.id !== null) {
      const { deleteModalHeading, deleteModalContent } = handleDeleteData(
        deleteData?.isWholeModalDeleted,
        deleteData?.modalType,
        deleteData?.practiceTypeValue,
        deleteData?.cropType,
      );
      setDeleteData({
        ...deleteData,
        deleteModalHeading: deleteModalHeading,
        deleteModalContent: deleteModalContent,
      });
    }
  }, [
    deleteData?.id,
    deleteData?.isWholeModalDeleted,
    deleteData.practiceTypeValue,
    deleteData?.cropType,
  ]);
  useEffect(() => {
    setParentState([]);
    if ((fieldType && sampleId) || fetchLastSectionData) {
      fetchFarmAndFieldsData();
      setIsInitialLoad(true);
    }
  }, [fieldType, sampleId, fetchLastSectionData, enrollmentYearData.type]);

  const fetchFarmAndFieldsData = () => {
    if (
      fieldType === PAGE_CONTENT.farm &&
      enrollmentYearData?.type === 'farm'
    ) {
      setLoading(true);
      reportingFetchAPI(
        FETCH_BASELINE_FARM_DATA,

        {
          params: { farmSampleId: sampleId },
        },
      )
        .then((response) => {
          setParentState(
            response.data.fossilFuels?.map((data) => ({
              id: data.farmSampleFfId,
              fossilFuelType: data.ffId,
              consumption: data.ffConsumption,
              unitOfMeasure: data.ffConsumptionUom,
              isCopied: data.isCopied,
            })),
          );
          setUserActivityDetected(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setFetchLastSectionData(false);
  };

  const handleSave = () => {
    if (
      typeOfReporting === BASELINE_MODAL_CONTENT.activity_type &&
      phase === BASELINE_MODAL_CONTENT.phase_baseline
    )
      return;
    saveDataHandler(
      false,
      {
        type: fieldType,
        id: farmId,
      },
      false,
    );
  };

  const handleContinue = () => {
    if (
      typeOfReporting === BASELINE_MODAL_CONTENT.activity_type &&
      phase === BASELINE_MODAL_CONTENT.phase_baseline
    )
      return;
    saveDataHandler(false, undefined, true);
  };

  // Fetch triggered from parent component, this will fetch the latest current page data
  useEffect(() => {
    if (triggerCurrentFarmDataFetch) {
      fetchFarmAndFieldsData();
      setTriggerCurrentFarmDataFetch(false);
    }
  }, [triggerCurrentFarmDataFetch]);

  useEffect(() => {
    setSubmitData({
      sampleId: sampleId,
      parentState: parentState,
      editMode: false,
      practiceModalsData: null,
      deletePracticeModalsData: null,
      practicesData: [],
    });
  }, [sampleId, parentState]);

  const copyFossilFuels = ({ data: fossilFuelsList, combinations }) => {
    makePageDirty();
    const newList = fossilFuelsList.map((data) => ({
      id: uniqueId(),
      fossilFuelType: data.ffId,
      consumption: data.ffConsumption,
      unitOfMeasure: data.ffConsumptionUom,
      isCopied: true,
    }));
    setParentState((prev) => [
      ...prev,
      ...copyFossilFuelRepeatedItems(newList, parentState, combinations)[0],
    ]);
    setSubmitData((prev) => {
      return {
        ...prev,
        parentState: [
          ...prev.parentState,
          ...copyFossilFuelRepeatedItems(newList, parentState, combinations)[0],
        ],
      };
    });
    setReplacePromptItems(
      copyFossilFuelRepeatedItems(newList, parentState, combinations)[1],
    );
    setReplacePromptIndex(0);
    setFieldDetails((prev) => {
      return {
        ...prev,
        farmDetails: prev.farmDetails?.map((farm) => {
          return checkTernaryCondition(
            farm.farmId === farmId,
            {
              ...farm,
              mainStatus: 'in progress',
            },
            farm,
          );
        }),
      };
    });
    setEnrollmentYears((prev) =>
      prev.map((yearData) => {
        return checkTernaryCondition(
          yearData.sampleId === sampleId,
          {
            ...yearData,
            status: 'In Progress',
          },
          yearData,
        );
      }),
    );
  };

  useEffect(() => {
    if (
      replacePromptIndex >= 0 &&
      replacePromptIndex < replacePromptItems?.length
    ) {
      setIsReplacePromptOpen(true);
    }
  }, [replacePromptIndex, replacePromptItems]);

  const acceptReplace = (replaceItem, replaceItemIdKey) => {
    if (type === PAGE_CONTENT.empty_farm_label) {
      setParentState((prev) => [
        ...prev.filter(
          (item) => item[replaceItemIdKey] !== replaceItem[replaceItemIdKey],
        ),
        replaceItem,
      ]);
    }
    setIsReplacePromptOpen(false);
    setReplacePromptIndex((prev) => prev + 1);
  };

  const declineReplace = () => {
    setIsReplacePromptOpen(false);
    setReplacePromptIndex((prev) => prev + 1);
  };

  // Checking if we have an item(cash crop / Tractor_vehicle) which is copied from some other place(We are tracking the copied status using "isCopied" flag)
  const copiedDataPresent = useMemo(
    () => parentState?.some((item) => item.isCopied === true),
    [parentState],
  );

  const handleSaveClick = async () => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    handleSave();
  };

  const handleContinueClick = async () => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    handleContinue();
  };

  return (
    <div data-testid="tab-body">
      {(parentState?.length === 0 && (
        <div>
          <ButtonsWrapper>
            {type === PAGE_CONTENT.empty_farm_label && (
              <Button
                sx={[
                  buttonStyleClose(false),
                  primaryButtonStyle('4.5px 15px'),
                  smallBtn,
                ]}
                disabled={disableAllFarmActions || !isEditable}
                onClick={() => {
                  onClickEmptyState(year, setIsTractorModalOpen);
                }}>
                {PAGE_CONTENT.addTractorVehicleBtn}
              </Button>
            )}
            <Button
              sx={[buttonStyleClose(), secondaryButtonStyle, smallBtn]}
              disabled={disableAllFarmActions || !isEditable}
              onClick={toggleCopyModalOpen}>
              {PAGE_CONTENT.copyButtonLabelTractors}
            </Button>
          </ButtonsWrapper>
          {(isInActivity || isBaselineOptionalVisible) &&
            HeadersComp(
              year,
              type,
              type === PAGE_CONTENT.empty_farm_label,
              activityReportingMappingModalErrorMessage,
            )}
        </div>
      )) || (
        <div>
          <ButtonsWrapper>
            {type === PAGE_CONTENT.empty_farm_label && (
              <Button
                sx={[
                  buttonStyleClose(false),
                  primaryButtonStyle('4.5px 15px'),
                  smallBtn,
                ]}
                onClick={() => {
                  onClickEmptyState(year, setIsTractorModalOpen);
                }}
                disabled={
                  disableAllFarmActions ||
                  addTractorVehicleBtnDisabled ||
                  !isEditable
                }>
                {PAGE_CONTENT.addTractorVehicleBtn}
              </Button>
            )}
            <Button
              disabled={disableAllFarmActions || !isEditable}
              sx={[buttonStyleClose(), secondaryButtonStyle, smallBtn]}
              onClick={toggleCopyModalOpen}>
              {PAGE_CONTENT.copyButtonLabelTractors}
            </Button>
          </ButtonsWrapper>
          {!isInActivity &&
            HeadersComp(
              year,
              type,
              type === PAGE_CONTENT.empty_farm_label,
              activityReportingMappingModalErrorMessage,
            )}
          {isInActivity &&
            HeadersComp(
              year,
              type,
              type === PAGE_CONTENT.empty_farm_label,
              activityReportingMappingModalErrorMessage,
            )}
          {copiedDataPresent && (
            <CopyDisclaimer>
              {checkTernaryCondition(
                type === PAGE_CONTENT.empty_crop_label,
                PAGE_CONTENT.cashCropCopyDisclaimer,
                PAGE_CONTENT.farmCopyDisclaimer,
              )}
            </CopyDisclaimer>
          )}

          <BaselineAccordionDisplayBox
            copiedDataPresent={copiedDataPresent}
            width={'100%'}
            typeOfReporting={typeOfReporting}
            modalDataArray={tractorVehicleList(
              parentState,
              fossilFuelTypeDropdown,
              tractorVehicleFuel,
            )}
            mainLabel={`${PAGE_CONTENT.tractor_and_vehicle} (${parentState.length})`}
            itemId={parentState[0]?.id}
            handleClickDelete={handleClickDelete}
            handleClickEdit={handleClickEdit}
            deleteModalHeading={deleteData?.deleteModalHeading}
            deleteModalContent={deleteData?.deleteModalContent}
            hasRowLevelEditDelete={!disableAllFarmActions}
            hasAddBtn={!disableAllFarmActions}
            addBtnLabel={PAGE_CONTENT.tractor_and_vehicle_add_btn}
            secondaryLabelStyle={secondaryLabelStyle}
            fieldLabelStyle={fieldLabelStyle}
            fieldUnitStyle={fieldUnitStyle}
            deleteData={deleteData}
            setDeleteData={setDeleteData}
            addNewBtnHandler={() =>
              onClickEmptyState(year, setIsTractorModalOpen)
            }
            isInActivity={isInActivity}
            isButtonDisabled={addTractorVehicleBtnDisabled || !isEditable}
            disableUserActions={!isEditable}
          />
        </div>
      )}

      {!disableAllFarmActions && (
        <NavigationBtnWrapper>
          <UserNavigationButtonsV2
            handleCancel={() => {
              handleCancel();
              /* istanbul ignore else */
              if (isParticipantContributor || isParticipantOwner) {
                setIsInitialLoad(true);
                fetchBaselineYears(PAGE_CONTENT.farm, farmId);
                updateReportingStatus(PAGE_CONTENT.farm, farmId);
                fetchFarmAndFieldsData();
                setIsInitialLoad(true);
              }
            }}
            disabledCancel={!isEditable}
            disabledSave={!isEditable}
            disabledContinue={!isEditable}
            handleSave={handleSaveClick}
            handleContinue={handleContinueClick}
          />
        </NavigationBtnWrapper>
      )}
      <FossilFuelTypeModal
        modalOpen={isTractorModalOpen}
        setModalOpen={setIsTractorModalOpen}
        tabWiseSubmitHandler={submitHandler}
        existingFuelTypeIds={
          parentState?.map(
            (tractorOrVehicle) => tractorOrVehicle.fossilFuelType,
          ) || []
        }
      />
      <CopyModal
        open={copyModalOpen}
        modalCloseHandler={toggleCopyModalOpen}
        title={PAGE_CONTENT.tractorOrVehicleCopyModalTitle}
        apiParams={{
          sampleId: sampleId,
          participantId: participantId,
          farmId: farmId,
          projectCycleId: projectCycleId,
        }}
        copyHandler={copyFossilFuels}
      />

      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isReplacePromptOpen}
        onConfirm={() => {
          acceptReplace(
            replacePromptItems[replacePromptIndex]?.data,
            replacePromptItems[replacePromptIndex]?.replaceItemIdKey,
          );
        }}
        subtitle={`${PAGE_CONTENT.replaceModalContentPrefix} ${replacePromptItems[replacePromptIndex]?.titlePart}${PAGE_CONTENT.replaceModalContentSuffix}`}
        onCancel={declineReplace}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Replace"
        isInsideBaseline
        title={`${PAGE_CONTENT.copy} ${replacePromptItems[replacePromptIndex]?.titlePart}`}></DialogBox>
    </div>
  );
};

export default TabBodyComponent;

TabBodyComponent.propTypes = {
  year: PropTypes.number,
  type: PropTypes.string,
  sampleId: PropTypes.string,
  setLoading: PropTypes.func,
  fieldType: PropTypes.string,
  farmId: PropTypes.string,
  enrollmentYearData: PropTypes.array,
  setFieldDetails: PropTypes.func,
  setSubmitData: PropTypes.func,
  saveDataHandler: PropTypes.func,
  setIsInitialLoad: PropTypes.func,
  fetchLastSectionData: PropTypes.func,
  setFetchLastSectionData: PropTypes.func,
  setUserActivityDetected: PropTypes.func,
  setEnrollmentYears: PropTypes.func,
  isInActivity: PropTypes.bool,
  approvedFarmIds: PropTypes.array,
  activityReportingMappingModalErrorMessage: PropTypes.string,
  phase: PropTypes.string,
  typeOfReporting: PropTypes.string,
  handleCancel: PropTypes.func,
  shouldNavigateToLandingPage: PropTypes.func,
  isBaselineOptionalVisible: PropTypes.bool,
  fetchBaselineYears: PropTypes.func,
  updateReportingStatus: PropTypes.func,
  isParticipantContributor: PropTypes.bool,
  isEditable: PropTypes.bool,
  isParticipantOwner: PropTypes.bool,
  triggerCurrentFarmDataFetch: PropTypes.bool,
  setTriggerCurrentFarmDataFetch: PropTypes.func,
};
