import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import {
  MainWrapper,
  HeaderWrapper,
  FilterWrapper,
  DataGridWrapper,
  datagridSx,
  TextWrapper,
  FilterBox,
  BasicWrapper,
  RowCellWrapper,
  FilterHeader,
  sortStyle,
  CellValueWrapper,
} from './AuditTrailComp.style';
import { DataGrid } from '@mui/x-data-grid';
import {
  PAGE_CONTENT,
  projectCategoryList,
  appCategoryList,
} from './AuditTrailComp.content';
import { ReactComponent as ExportIcon } from '../../assets/icons/export.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calenderIcon.svg';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker.react';
import axios from 'axios';
import { AUDIT_LOG } from 'urls';
import { uniqueId } from 'utils/uniqueIdGenerator';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ActionModal from 'components/ActionModal/ActionModal.react';
import { generateTitle } from 'components/ActionModal/ActionModal.content';
import {
  dateFormatter,
  usToUkDateFormatter,
  findPastMonthsDate,
  dateTimeFormatter,
} from 'utils/dateFormatter';
import { covertDateToLocalTimeZone } from '../../utils/helper';
import TransactionModal from './TransactionAudit/TransactionModal/TransactionModal.react';
import { pathName } from '../../Routes/routes.const';
import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import { ERROR_MSGS } from 'utils/config';

const pastOneMonth = findPastMonthsDate(1);

const customCell = (value1, value2, color1, color2) => {
  return (
    <RowCellWrapper direction={PAGE_CONTENT.column}>
      <CellValueWrapper color={color1} fontSize="0.9rem" fontWeight={400}>
        {value1}
      </CellValueWrapper>
      <TextWrapper fontSize="0.8rem" fontWeight={400} color={color2}>
        {value2}
      </TextWrapper>
    </RowCellWrapper>
  );
};

const AuditTrailComp = ({ disableVirtualization, categoryType, recordId }) => {
  const [category, setCategory] = useState(1);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [fromDate, setFromDate] = useState(
    dateFormatter(pastOneMonth.toJSON()),
  );
  const [toDate, setToDate] = useState(dateFormatter(new Date().toJSON()));
  const [auditData, setAuditData] = useState([]);
  const [transactionAuditEvents, setTransactionAuditEvents] = useState(null);
  const [loading, setLoading] = useState(false);
  const [row, setRow] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_CONTENT.default_page_size);
  const [totalElements, setTotalElements] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [modalEvents, setModalEvents] = useState([]);
  const [actionModalTitle, setActionModalTitle] = useState('');

  const [modalTitle, setModalTitle] = useState('');
  const [sortKey, setSortKey] = useState(0);
  const [sortValue, setSortValue] = useState([
    'action_datetime desc',
    'user_name desc',
    'object desc',
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAuditData();
  }, []);
  useEffect(() => {
    fetchAuditData();
  }, [sortValue, fromDate, toDate, category, pageNumber, pageSize]);

  const categoryList =
    categoryType === PAGE_CONTENT.project
      ? projectCategoryList
      : appCategoryList;

  const setModalValues = (event, title) => {
    setIsModalOpen(true);
    setModalEvents(event);
    setActionModalTitle(title);
  };

  const findCategory = () => {
    const length = categoryList[category - 1].value.length;
    const index = category - 1;
    switch (categoryType) {
      case PAGE_CONTENT.application:
        if (categoryList[category - 1].value === categoryList[0].value) {
          return [];
        } else if (categoryList[category - 1].value === categoryList[3].value) {
          return [PAGE_CONTENT.data_input];
        } else {
          return categoryList[category - 1].value ===
            `${PAGE_CONTENT.transaction}s`
            ? PAGE_CONTENT.transact_category_array
            : [categoryList[category - 1].value.slice(0, length - 1)];
        }
      case PAGE_CONTENT.project:
        return !index
          ? PAGE_CONTENT.project_payload_category
          : [PAGE_CONTENT.project_payload_category[index - 1]];
    }
  };

  const fetchAuditData = () => {
    let postData = {
      startDate: usToUkDateFormatter(fromDate),
      endDate: usToUkDateFormatter(toDate),
      filter: {
        users: [],
        objects: [],
        category: findCategory(),
      },
      orderBy: [sortValue[sortKey]],
      recordId: recordId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    setLoading(true);
    axios
      .post(AUDIT_LOG, postData)
      .then((response) => {
        setAuditData(
          response.data.content.map((item) => {
            return {
              id: uniqueId(),
              action: item?.action,
              auditEvents: item?.auditEvents,
              actionDate: item?.actionDateTime.slice(0, 10),
              actionTime: dateTimeFormatter(
                covertDateToLocalTimeZone(item?.actionDateTime),
              ),
              auditId: item?.auditId,
              category: item?.category,
              email: item?.email,
              object: item?.object,
              recordId: item?.recordId,
              userName: item?.userName,
            };
          }),
        );
        setTotalElements(response.data.totalElements);
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setSortOrder = (key, prev, newVal) => {
    let sortArray = sortValue;
    const updatedKey = sortArray[key].replace(prev, newVal);
    sortArray[key] = updatedKey;
    setSortValue([...sortArray]);
    setSortKey(key);
  };

  // Call popup transaction api
  const callTransactionAPI = async (auditId) => {
    axios.get(`${AUDIT_LOG}/?auditId=${auditId}`).then((response) => {
      setTransactionAuditEvents(response.data);
      setIsTransactionModalOpen(true);
    });
  };

  const handleMoreInfoClick = (
    eventArray,
    object,
    user,
    category,
    date,
    time,
    auditId,
  ) => {
    if (category.includes(PAGE_CONTENT.transaction)) {
      callTransactionAPI(auditId);
      const transationTitle = generateTitle(category, object, date, time, user);
      if (transationTitle != undefined) setModalTitle(transationTitle);
    } else {
      const title = generateTitle(category, object, date, time, user);
      if (title != undefined) setModalTitle(title);
      setModalValues(eventArray, title);
    }
  };

  const customHeader = (column, hasSort, hasFilter, key) => {
    return (
      <FilterHeader>
        <b>{column}</b>

        {hasSort && (
          <>
            {(sortValue[key].includes(PAGE_CONTENT.desc) && (
              <ArrowDownwardIcon
                data-testid="down-arrow"
                sx={sortStyle}
                onClick={() =>
                  setSortOrder(key, PAGE_CONTENT.desc, PAGE_CONTENT.asc)
                }
              />
            )) || (
              <ArrowUpwardIcon
                data-testid="up-arrow"
                sx={sortStyle}
                onClick={() =>
                  setSortOrder(key, PAGE_CONTENT.asc, PAGE_CONTENT.desc)
                }
              />
            )}
          </>
        )}
        {/* div is a placeholder for filter component */}
        {hasFilter && <div></div>}
      </FilterHeader>
    );
  };

  const actionCell = ({
    action,
    category,
    auditEvents,
    object,
    userName,
    actionDate,
    actionTime,
    auditId,
  }) => {
    if (action === PAGE_CONTENT.update) {
      return (
        <button
          onClick={
            category === PAGE_CONTENT.data_input ||
            (auditEvents === null &&
              category.includes(PAGE_CONTENT.transaction) === false)
              ? null
              : () =>
                  handleMoreInfoClick(
                    auditEvents,
                    object,
                    userName,
                    category,
                    actionDate,
                    actionTime,
                    auditId,
                  )
          }
          onKeyDown={() => {}}>
          {customCell(
            PAGE_CONTENT.updated,
            category === PAGE_CONTENT.data_input ||
              (auditEvents === null &&
                category.includes(PAGE_CONTENT.transaction) === false)
              ? ''
              : PAGE_CONTENT.more_info,
            PAGE_CONTENT.black,
            PAGE_CONTENT.link_blue,
          )}
        </button>
      );
    } else if (action === PAGE_CONTENT.insert) {
      return customCell(
        category === PAGE_CONTENT.data_input
          ? PAGE_CONTENT.updated
          : PAGE_CONTENT.created,
        '',
        PAGE_CONTENT.black,
        PAGE_CONTENT.link_blue,
      );
    } else {
      return customCell(
        PAGE_CONTENT.deleted,
        '',
        PAGE_CONTENT.black,
        PAGE_CONTENT.link_blue,
      );
    }
  };

  const columns = [
    {
      field: PAGE_CONTENT.category_field,
      headerName: PAGE_CONTENT.category,
      disableColumnMenu: true,
      flex: 1.4,
      sortable: false,
      renderHeader: () => (
        <div>
          <b>{PAGE_CONTENT.category}</b>
        </div>
      ),
      renderCell: (params) => customCell(params?.row?.category),
    },
    {
      field: PAGE_CONTENT.object_field,
      headerName: PAGE_CONTENT.object,
      disableColumnMenu: true,
      flex: 2,
      sortable: false,
      renderHeader: () =>
        customHeader(PAGE_CONTENT.object, true, true, PAGE_CONTENT.key_two),
      renderCell: (params) =>
        customCell(params.row?.object, '', PAGE_CONTENT.backgroundColor),
    },
    {
      field: PAGE_CONTENT.action_field,
      headerName: PAGE_CONTENT.action,
      disableColumnMenu: true,
      disableSelectionOnClick: true,
      align: '',
      flex: 0.7,
      sortable: false,
      renderHeader: () => (
        <div>
          <b>{PAGE_CONTENT.action}</b>
        </div>
      ),
      renderCell: (params) => actionCell(params.row),
    },
    {
      field: PAGE_CONTENT.date_time,
      headerName: PAGE_CONTENT.time,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.8,
      renderHeader: () =>
        customHeader(PAGE_CONTENT.time, true, false, PAGE_CONTENT.key_zero),
      renderCell: (params) =>
        customCell(
          covertDateToLocalTimeZone(params?.row?.actionDate).slice(0, 10),
          params?.row?.actionTime,
          PAGE_CONTENT.backgroundColor,
          PAGE_CONTENT.cool_gray,
        ),
    },

    {
      field: PAGE_CONTENT.user_name,
      headerName: PAGE_CONTENT.user,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
      renderHeader: () =>
        customHeader(PAGE_CONTENT.user, true, true, PAGE_CONTENT.key_one),
      renderCell: (params) =>
        customCell(
          params?.row?.userName,
          params?.row?.email,
          PAGE_CONTENT.backgroundColor,
          PAGE_CONTENT.cool_gray,
        ),
    },
  ];
  const rowClickHandler = (params) => {
    if (params.row.action === PAGE_CONTENT.delete) {
      switch (params.row.category) {
        case PAGE_CONTENT.project:
          navigate(`${pathName.assets.projects.edit}/${params.row.recordId}`);
          break;
        case PAGE_CONTENT.stakeholder:
          navigate(
            `${pathName.assets.stakeholders.edit}/${params.row.recordId}`,
          );
          break;
        case PAGE_CONTENT.purchseTransaction:
          navigate(
            `${pathName.transaction.purchase.view}/${params.row.recordId}`,
          );
          break;
        case PAGE_CONTENT.saleTransaction:
          navigate(`${pathName.transaction.sale.view}/${params.row.recordId}`);
          break;
      }
    }
  };
  return (
    <MainWrapper>
      <HeaderWrapper>
        <FilterWrapper>
          <TextWrapper fontSize={'0.88rem'} fontWeight={600}>
            {PAGE_CONTENT.audit_log_for}
          </TextWrapper>
          {categoryList.map((item) => (
            <FilterBox
              key={item.value}
              onClick={() => {
                setCategory(item.key);
              }}
              color={
                category !== item.key ? PAGE_CONTENT.black : PAGE_CONTENT.white
              }
              backgroundColor={
                category !== item.key ? PAGE_CONTENT.white : PAGE_CONTENT.green
              }>
              {item.value}
            </FilterBox>
          ))}
        </FilterWrapper>
        <BasicWrapper gap={'1rem'}>
          <BasicWrapper
            gap={'1.5rem'}
            border={'1px solid #97999B'}
            padding={'0.5rem'}
            radius={'2px'}
            cursor={PAGE_CONTENT.center}
            data-testid={PAGE_CONTENT.date_filter}
            onClick={() => {
              setIsCalendarOpen(true);
            }}>
            <TextWrapper fontSize={'0.75rem'} fontWeight={400}>
              {fromDate} - {toDate}
            </TextWrapper>
            <CalendarIcon />
          </BasicWrapper>
          <BasicWrapper
            gap={'1rem'}
            border={'1px solid #26890D'}
            padding={'0.5rem'}
            radius={'0.25rem'}
            cursor={PAGE_CONTENT.pointer}
            alignment={PAGE_CONTENT.center}>
            <TextWrapper
              fontSize={'0.75rem'}
              fontWeight={400}
              color={PAGE_CONTENT.green}>
              {PAGE_CONTENT.export}
            </TextWrapper>
            <ExportIcon />
          </BasicWrapper>
        </BasicWrapper>
      </HeaderWrapper>
      <DataGridWrapper>
        <DataGrid
          disableVirtualization={disableVirtualization || false}
          disableSelectionOnClick
          loading={loading}
          onRowClick={(row) => {
            setRow(row.row);
          }}
          sx={datagridSx}
          rowCount={totalElements}
          rows={auditData}
          onCellClick={rowClickHandler}
          columns={columns}
          paginationMode={PAGE_CONTENT.pagination_mode}
          page={pageNumber}
          pageSize={pageSize}
          onPageChange={(newPage) => setPageNumber(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={PAGE_CONTENT.default_pagination_options}
          pagination
          componentsProps={{
            pagination: {
              labelRowsPerPage: PAGE_CONTENT.label_rows_per_page,
              SelectProps: TablePaginationMenuListStyle,
            },
          }}
        />
      </DataGridWrapper>
      <DateRangePicker
        data-testid={PAGE_CONTENT.range_picker}
        isOpen={isCalendarOpen}
        setIsOpen={setIsCalendarOpen}
        starDateSetter={setFromDate}
        endDateSetter={setToDate}
      />
      {isModalOpen && (
        <ActionModal
          eventDetails={modalEvents}
          isModalOpen={isModalOpen}
          category={row.category}
          title={actionModalTitle}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {isTransactionModalOpen && (
        <TransactionModal
          title={modalTitle}
          auditEvents={transactionAuditEvents}
          transactionType={row.category}
          isOpen={isTransactionModalOpen}
          isOpenSetter={setIsTransactionModalOpen}
        />
      )}
    </MainWrapper>
  );
};

AuditTrailComp.propTypes = {
  disableVirtualization: PropTypes.bool,
  categoryType: PropTypes.string,
  recordId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AuditTrailComp;
