import PropTypes from 'prop-types';
import LeftMenuFieldData from '../LeftMenuFieldData/LeftMenuFieldData.react';
import {
  MainWrapper,
  MenuSubsectionHeading,
  MainFieldWrapper,
  NoFarmDefaultText,
} from './LeftMenuSubsection.style';
import {
  LEFT_MENU_SECTION_HEADERS,
  PAGE_CONTENT,
} from '../../ParticipantDataCollection.content';
import { checkTernaryCondition } from 'utils/helper';
import { useLocation } from 'react-router';
import AddSectionButton from 'components/AddSectionButton/AddSection.react';

const LeftMenuSubsection = ({
  heading,
  isInBaselinePage,
  fieldData,
  showFields,
  setCurrentFieldId,
  setRightSectionId = () => {},
  updateFarmFieldName = null,
  currentFarmId = null,
  setCurrentFarmId = () => {},
  setOpenMapView = () => {},
  setSelectedFieldId = () => {},
  setShowFieldInfoForm = () => {},
  setFieldAddTypeModalOpen = () => {},
  farmInfoCurrentTab = null,
  setFarmInfoCurrentTab = () => {},
  setExpandedFarmId = () => {},
  farmsIdsWithExistingFields = null,
  farmInfo = null,
  setFarmInfo = () => {},
  selectedFieldId = null,
  setFieldPolygons = () => {},
  rightSectionId = null,
  expandedFarmId = null,
  setIsTabSwitched = () => {},
  setCurrentFarmLocation = () => {},
  showFieldInfoForm,
  setFarmDetails,
  isFarmSelected,
  saveDataHandler = () => {},
  setIsFormUnmounted = () => {},
  setIsFieldDeleted = () => {},
  resetFieldViewTab = () => {},
  enrolledFarmIds = [],
  enrolledFieldIds = [],
  hideSection = false,
  handleAccordianSummaryDeleteClick = () => {},
  isParticipantContributor = false,
  handleViewMapClick = () => {},
  handleAccordianSummaryClick = () => {},
  handleAccordianItemClick = () => {},
  isInsideActivityReporting = false,
  addSectionLabel = '',
  addSectionHandler = () => {},
  isAddFarmDisabled = false,
}) => {
  const { pathname } = useLocation();

  const inFarmAndFieldsSection =
    heading === LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection;
  const insideEnrollmentForm = pathname === PAGE_CONTENT.enrollmentFormPath;
  const noFarmsInEnrollment =
    insideEnrollmentForm && fieldData && fieldData.length === 0;

  return (
    !hideSection && (
      <MainWrapper
        borderBottom={
          heading === LEFT_MENU_SECTION_HEADERS.profileSection ||
          heading === LEFT_MENU_SECTION_HEADERS.farmAndContributorSection
        }>
        <MenuSubsectionHeading>
          {heading}
          {addSectionLabel && (
            <AddSectionButton
              label={addSectionLabel}
              onClickHanlder={(e) => {
                e.stopPropagation();
                handleAccordianItemClick({
                  targetPlace: PAGE_CONTENT.addFarms,
                  targetParentId: currentFarmId,
                  targetName: '',
                  navigateNextTo: PAGE_CONTENT.addFarms,
                });
              }}
              hiddenFlag={false}
              marginTop="0"
              marginBottom="0"
              hasBorder={true}
              padding="0.2rem 0.5rem"
              disabled={isParticipantContributor || isAddFarmDisabled}
            />
          )}
        </MenuSubsectionHeading>
        <MainFieldWrapper maxHeight={isInBaselinePage}>
          {fieldData?.map((data) => {
            return (
              <LeftMenuFieldData
                insideEnrollmentForm={insideEnrollmentForm}
                isInBaselinePage={isInBaselinePage}
                heading={heading}
                key={data.id}
                fieldValue={data}
                hasCollapsableOptions={inFarmAndFieldsSection}
                showFields={checkTernaryCondition(
                  showFields,
                  showFields,
                  inFarmAndFieldsSection && data.id === expandedFarmId,
                )}
                toggleFieldsDataView={
                  inFarmAndFieldsSection
                    ? () => {
                        setExpandedFarmId(
                          data.id === expandedFarmId ? -1 : data.id,
                        );
                        setCurrentFarmId(data.id);
                        setShowFieldInfoForm(false);
                      }
                    : () => null
                }
                setCurrentFieldId={setCurrentFieldId}
                setRightSectionId={setRightSectionId}
                updateFarmFieldName={updateFarmFieldName}
                currentFarmId={currentFarmId}
                setCurrentFarmId={setCurrentFarmId}
                setOpenMapView={setOpenMapView}
                setSelectedFieldId={setSelectedFieldId}
                setShowFieldInfoForm={setShowFieldInfoForm}
                setFieldAddTypeModalOpen={setFieldAddTypeModalOpen}
                farmInfoCurrentTab={farmInfoCurrentTab}
                setFarmInfoCurrentTab={setFarmInfoCurrentTab}
                setExpandedFarmId={setExpandedFarmId}
                farmsIdsWithExistingFields={farmsIdsWithExistingFields}
                farmInfo={farmInfo}
                setFarmInfo={setFarmInfo}
                selectedFieldId={selectedFieldId}
                setFieldPolygons={setFieldPolygons}
                rightSectionId={rightSectionId}
                setIsTabSwitched={setIsTabSwitched}
                setCurrentFarmLocation={setCurrentFarmLocation}
                showFieldInfoForm={showFieldInfoForm}
                setFarmDetails={setFarmDetails}
                isFarmSelected={isFarmSelected}
                saveDataHandler={saveDataHandler}
                setIsFormUnmounted={setIsFormUnmounted}
                expandedFarmId={expandedFarmId}
                setIsFieldDeleted={setIsFieldDeleted}
                resetFieldViewTab={resetFieldViewTab}
                enrolledFarmIds={enrolledFarmIds}
                enrolledFieldIds={enrolledFieldIds}
                handleAccordianSummaryDeleteClick={
                  handleAccordianSummaryDeleteClick
                }
                isParticipantContributor={isParticipantContributor}
                handleViewMapClick={handleViewMapClick}
                handleAccordianSummaryClick={handleAccordianSummaryClick}
                handleAccordianItemClick={handleAccordianItemClick}
                isInsideActivityReporting={isInsideActivityReporting}
              />
            );
          })}
          {noFarmsInEnrollment && (
            <NoFarmDefaultText>
              {PAGE_CONTENT.noFarmsDefaultText}
            </NoFarmDefaultText>
          )}
        </MainFieldWrapper>
      </MainWrapper>
    )
  );
};

LeftMenuSubsection.propTypes = {
  heading: PropTypes.string,
  isInBaselinePage: PropTypes.bool,
  fieldData: PropTypes.array,
  showFields: PropTypes.bool,
  setCurrentFieldId: PropTypes.func,
  setRightSectionId: PropTypes.func,
  updateFarmFieldName: PropTypes.func,
  currentFarmId: PropTypes.string,
  setCurrentFarmId: PropTypes.func,
  setOpenMapView: PropTypes.func,
  setSelectedFieldId: PropTypes.func,
  setShowFieldInfoForm: PropTypes.func,
  setFieldAddTypeModalOpen: PropTypes.func,
  farmInfoCurrentTab: PropTypes.number,
  setFarmInfoCurrentTab: PropTypes.func,
  setExpandedFarmId: PropTypes.func,
  farmsIdsWithExistingFields: PropTypes.array,
  farmInfo: PropTypes.array,
  setFarmInfo: PropTypes.func,
  selectedFieldId: PropTypes.string,
  setFieldPolygons: PropTypes.func,
  rightSectionId: PropTypes.string,
  expandedFarmId: PropTypes.string,
  setIsTabSwitched: PropTypes.func,
  setCurrentFarmLocation: PropTypes.func,
  showFieldInfoForm: PropTypes.bool,
  setFarmDetails: PropTypes.func,
  isFarmSelected: PropTypes.bool,
  saveDataHandler: PropTypes.func,
  setIsFormUnmounted: PropTypes.func,
  setIsFieldDeleted: PropTypes.func,
  resetFieldViewTab: PropTypes.func,
  enrolledFarmIds: PropTypes.array,
  enrolledFieldIds: PropTypes.array,
  hideSection: PropTypes.boolean,
  handleAccordianSummaryDeleteClick: PropTypes.func,
  handleViewMapClick: PropTypes.func,
  addSectionLabel: PropTypes.string,
  addSectionHandler: PropTypes.func,
  isParticipantContributor: PropTypes.bool,
  handleAccordianSummaryClick: PropTypes.func,
  handleAccordianItemClick: PropTypes.func,
  isInsideActivityReporting: PropTypes.bool,
  isAddFarmDisabled: PropTypes.bool,
};

export default LeftMenuSubsection;
