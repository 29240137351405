import styled from 'styled-components';
import {
  BLACK,
  DARK_CHARCOAL,
  TOAST_WARNING_BORDER,
  WHITE,
} from 'theme/GlobalColors';

export const ProjectScheduleWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 30.563rem;
  background-color: ${WHITE};
  flex-direction: column;
`;

export const Heading = styled.div`
  font-family: Open Sans;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.225rem;
  color: ${BLACK};
  text-align: left;
  margin-bottom: 1.5rem;
`;

export const SubHeading = styled.div`
  font-family: Open Sans;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.225rem;
  text-align: left;
  color: ${DARK_CHARCOAL};
  span {
    font-weight: 600;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  width: 31.9rem;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  border-radius: 2px;
  border: 1px solid ${TOAST_WARNING_BORDER};
  opacity: 0px;
  margin-top: 1rem;
`;

export const IconWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;

export const InfoContent = styled.div`
  font-family: Open Sans;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.05rem;
  text-align: left;
  color: ${DARK_CHARCOAL};
`;
