import { Box, Tab, Tabs } from '@mui/material';
import {
  PAGE_CONTENT,
  POI_UNSAVED_CHANGES_MODAL_INITIAL_STATE,
  aggregateObjects,
  createFieldActivityMapping,
  findIfAllFieldsAndActivitiesMapped,
  formatPoiData,
  formatSubmitData,
  geoReferenceImageAcceptedFilesInfo,
  getActivityCategory,
  getFieldAcresText,
  possibleFileTypes,
  projectActivitiesLabels,
  recieptsInvoiceAndMgmtPlanAcceptedFilesInfo,
  tabProps,
  getSectionHeading,
  replaceToolTipEmail,
  getProofUploadedText,
} from './ProofOfImplementationMainComp.content';
import {
  ContentBox,
  ContentWrapper,
  HeaderWrapper,
  InfoBox,
  MainWrapper,
  PoiTextWrapper,
  TabberBoxCustomStyle,
  TextWrapper,
} from './ProofOfImplementationMainComp.style';
import {
  useStyles,
  flexColumn,
  yAxisAutoScroll,
  toolTipStyle,
} from 'theme/GlobalStyles';
import { TabStylesSx } from '../../ActivityReporting.style';
import classNames from 'classnames';
import TabPanel from 'components/Tabber/TabPanel.react';
import { useContext, useEffect, useMemo, useState } from 'react';
import FileUploadCompPOI from '../FileUploadCompPOI/FileUploadCompPOI.react';
import {
  checkTernaryCondition,
  convertCommaseperatedStringToArray,
  findObjectWithKey,
  getUniqueObjectsByKey,
  sortWithAKeyInSpecificOrder,
} from 'utils/helper';
import {
  DELETE_POI_FILE,
  FETCH_POI_ACTIVITIES,
  FETCH_POI_TAB_DATA,
  POI_INIT_FILE_UPLOAD,
  SUBMIT_POI_TAB_DATA,
} from 'urls';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Loader from 'components/Loader/Loader.react';
import PropTypes from 'prop-types';
import { pathName } from 'Routes/routes.const';
import { ReactComponent as PoiAlertIcon } from 'assets/icons/poiAlertIcon.svg';
import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { DARK_CHARCOAL, WHITE } from 'theme/GlobalColors';
import InputField from 'components/FormComponents/InputField/InputField.react';
import UserNavigationButtonsV2 from 'pages/ParticipantDataCollection/components/UserNavigationButtonsV2/UserNavigationButtonsV2.react';
import { ERROR_MSGS, TOOLTIP_PLACEMENTS } from 'utils/config';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import UnsavedChangesModal from 'pages/ParticipantDataCollection/components/UnsavedChangesModal/UnsavedChangesModal.react';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import { ESTIMATED_IMPLEMENTATION_COST } from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import FieldDataModal from './FieldDataModal.react';

const ProofOfImplementationMainComp = ({
  farmId,
  setActivityTabData,
  fetchFieldDetails,
  poiStatusList,
  currentTab = 0,
  setCurrentTab,
  projectActivities = [],
  setProjectActivities = () => {},
  currentTabData = [],
  setCurrentTabData = () => {},
  currentTabStatus = 'Not Started',
  setCurrentTabStatus = () => {},
  updateReportingStatus = () => {},
  gotoNextInprogressPage = () => {},
  farmProofOfImplementationStatus,
  setUserActivityDetected = () => {},
  setAnnualImplementationCost,
  annualImplementationCost,
  setReportingCompleteModal = () => {},
  userActivityDetected = false,
  disableAllFarmActions = false,
  activityApprovedFields = [],
  shouldNavigateToLandingPage,
  navigateHandler = () => {
    /* do nothing here */
  },
  isParticipantContributor = false,
  isEditable = true,
  isParticipantOwner = false,
  triggerDataFetch = false,
  setTriggerDataFetch = () => {},
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const [loaderCount, setLoaderCount] = useState(0);
  const [showWarning, setShowWarning] = useState(true);
  const [fieldIdWithActivityMapping, setFieldIdWithActivityMapping] =
    useState(null);
  const [isAllFieldsAndActivitiesMapped, setIsAllFieldsAndActivitiesMapped] =
    useState(false);
  const { TabberBox } = useStyles();
  const existingfileIds = useMemo(() => {
    return currentTabData?.map((data) => data.fileId);
  }, [currentTabData]);

  const [fieldDetails, setFieldDetails] = useState([]);
  const [unsavedChangesModalProps, setUnsavedChangesModalProps] = useState(
    POI_UNSAVED_CHANGES_MODAL_INITIAL_STATE,
  );
  const {
    unitOfMeasureList: { proofOfImplementation },
  } = useContext(uomContext);
  const refreshStatus = () => updateReportingStatus(PAGE_CONTENT.farm, farmId);
  const { clientConfigs } = useContext(userAuthRolesContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const aggregateAndMap = (fieldDetails, currentTab) => {
    const aggregatedData = aggregateObjects(fieldDetails);
    const projectActivitiesData = aggregatedData.map((item) => {
      const fieldsOfActivity = getUniqueObjectsByKey(
        item.fieldsOfActivity,
        'value',
      );
      return {
        activityId: item.projectActivityId,
        title: getSectionHeading(item.projectActivityCategory),
        implementedOn: fieldsOfActivity.length,
        implementedAcres: Number(item.implementedAcres), // removed the toFixed(2) as it was restricting the value upto 2 decimal places
        fieldsOfActivity: fieldsOfActivity,
        practiceTypeList: item.practiceTypeList,
      };
    });

    const tabSortOrder = projectActivitiesLabels.map(
      (activity) => activity.title,
    );
    setProjectActivities(
      sortWithAKeyInSpecificOrder(projectActivitiesData, 'title', tabSortOrder),
    );

    const currentTabTitle = projectActivitiesData[currentTab]?.title;
    const fieldWithActivityIdsMapping = createFieldActivityMapping(
      fieldDetails.filter(
        (item) =>
          getSectionHeading(item.projectActivityCategory) === currentTabTitle,
      ),
    );
    setFieldIdWithActivityMapping(fieldWithActivityIdsMapping);
  };

  const checkPOITabsStatus = (fieldData) => {
    const currentFarmPOITabStatusList = fieldData.farmDetails?.find(
      (farm) => farm.farmId === farmId,
    )?.poiTabStatus;
    const areAllPOITabsCompleted = currentFarmPOITabStatusList?.every(
      (poiTab) => poiTab.status === PAGE_CONTENT.completed,
    );

    const areAllFarmsCompleted = fieldData.farmDetails?.every(
      (farm) => farm.farmActivityReportingStatus === PAGE_CONTENT.completed,
    );

    return areAllPOITabsCompleted && areAllFarmsCompleted;
  };

  const navigateToNextTab = (fieldData) => {
    const tabLength = projectActivities.length;
    const shouldOpenReviewModal = checkPOITabsStatus(fieldData);
    if (shouldOpenReviewModal) {
      setReportingCompleteModal({ flag: true });
      refreshStatus();
      return;
    }
    if (currentTab < tabLength - 1) {
      switchToTab(currentTab + 1);
      refreshStatus();
    } else if (currentTab === tabLength - 1) {
      gotoNextInprogressPage(fieldData);
    }

    if (tabLength === 0) {
      gotoNextInprogressPage(fieldData);
    }
  };

  const validateTabData = (tabData) => {
    setIsAllFieldsAndActivitiesMapped(
      findIfAllFieldsAndActivitiesMapped(fieldIdWithActivityMapping, tabData),
    );
  };

  const fetchPoiActivities = () => {
    setLoaderCount((prev) => prev + 1);

    axios
      .get(FETCH_POI_ACTIVITIES, {
        params: {
          participantId: participantId,
          projectId: projectId,
          farmId: farmId,
        },
      })
      .then((resp) => {
        const formattedData = formatPoiData(resp.data);
        setFieldDetails(formattedData);
        aggregateAndMap(formattedData, currentTab);
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  const fetchActivityTabData = async (tab) => {
    const currentTabTitle = projectActivities[tab]?.title;
    const activityIds = projectActivities
      .find((item) => item.title === currentTabTitle)
      ?.practiceTypeList.map((practiceType) => practiceType.value)
      .join(',')
      .toString();
    const activityCategory = getActivityCategory(currentTabTitle);

    setLoaderCount((prev) => prev + 1);
    return axios
      .get(FETCH_POI_TAB_DATA, {
        params: {
          farmId: farmId,
          projectId: projectId,
          activityIds: checkTernaryCondition(
            activityCategory === projectActivitiesLabels[0].title ||
              activityCategory === projectActivitiesLabels[2].title,
            activityIds,
            '',
          ),
          activityCategory: activityCategory,
        },
      })
      .then((resp) => {
        const data = resp.data;

        const filesData = data
          ?.filter((file) => file.fileType !== null) // Exclude files with fileType as null
          .map((file) => ({
            fileId: file.poiFileId,
            file: file.fileThumbnail,
            fields: convertCommaseperatedStringToArray(file.fieldId),
            practice: checkTernaryCondition(
              file.practiceType === '0',
              [],
              convertCommaseperatedStringToArray(file.practiceType),
            ),
            fileType: file.fileType,
            fileName: file.fileName,
            fileStatus: file.fileStatus,
            estimatedActivityImplementationCost:
              file.estimatedActivityImplementationCost,
          }));
        setCurrentTabData(filesData);
        const estimatedCost = checkTernaryCondition(
          filesData.length > 0,
          filesData[0],
          data[data?.length - 1],
        );
        const totalCost =
          estimatedCost?.estimatedActivityImplementationCost?.toString() ?? '';
        setAnnualImplementationCost(
          checkTernaryCondition(totalCost, ` ${totalCost}`, ''),
        );
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      })
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  const addFilesToTabData = async (
    files,
    fileType,
    activityIds,
    hasPracticeDropdown = true,
  ) => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    const formattedActivityIds = activityIds.join(',').toString();

    const formData = new FormData();
    for (const file of files) {
      let fileToAppend = file;
      if (file.name.endsWith('.jpeg')) {
        const newFileName = file.name.replace(/\.jpeg$/, '.jpg');
        fileToAppend = new File([file], newFileName, { type: file.type });
      }
      formData.append('images', fileToAppend);
    }

    setLoaderCount((prev) => prev + 1);
    axios
      .post(POI_INIT_FILE_UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          projectId: projectId,
          farmId: farmId,
          fileType: fileType,
          activityIds: checkTernaryCondition(
            hasPracticeDropdown,
            '',
            formattedActivityIds,
          ),
          activityCategory: getActivityCategory(
            projectActivities[currentTab].title,
          ),
          activityStatus: currentTabStatus,
          participantId: participantId,
        },
      })
      .then((resp) => {
        if (fileType === possibleFileTypes[0].name) {
          setUserActivityDetected(true);
        }
        const fileData = resp.data;
        const filesData = fileData
          .filter(
            (file) =>
              !existingfileIds.includes(file.poiFileId) &&
              file.fileType !== null,
          )
          .map((file) => ({
            fileId: file.poiFileId,
            file: file.fileThumbnail,
            fields: (() => {
              const filteredFields = convertCommaseperatedStringToArray(
                file.fieldId,
              )?.filter((fieldId) => !activityApprovedFields.includes(fieldId));
              return checkTernaryCondition(
                filteredFields.length === 0,
                [0],
                filteredFields,
              );
            })(),
            practice: checkTernaryCondition(
              hasPracticeDropdown,
              [],
              activityIds,
            ),
            fileType: file.fileType,
            fileName: file.fileName,
            fileStatus: file.fileStatus,
          }));
        setCurrentTabData((prev) => [...prev, ...filesData]);
        setActivityTabData((prev) => ({
          ...prev,
          farmProofOfImplementationStatus: PAGE_CONTENT.in_progress,
        }));
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.UNEXPECTED);
      })
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  function isAllFilesMapped(tabData) {
    return tabData.every((item) => {
      const hasInvalidField =
        item.fields.includes(-1) || item.fields.includes(0);
      const hasEmptyPractice = item.practice.length === 0;
      return !hasInvalidField && !hasEmptyPractice;
    });
  }

  function getStatusAfterFileDelete(fileId) {
    const areAllFilesMapped = isAllFilesMapped(
      currentTabData.filter((item) => item.fileId !== fileId),
    );

    const isAllFieldsAndActivitiesMapped = findIfAllFieldsAndActivitiesMapped(
      fieldIdWithActivityMapping,
      currentTabData.filter((item) => item.fileId !== fileId),
    );

    const poiTabStatus = checkTernaryCondition(
      areAllFilesMapped && isAllFieldsAndActivitiesMapped,
      PAGE_CONTENT.completed,
      PAGE_CONTENT.in_progress,
    );

    return checkTernaryCondition(
      currentTabData.length === 1,
      PAGE_CONTENT.not_started,
      poiTabStatus,
    );
  }

  const handleFileDelete = async (fileId) => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    let status = getStatusAfterFileDelete(fileId);
    setLoaderCount((prev) => prev + 1);
    axios
      .delete(
        `${DELETE_POI_FILE}/${fileId}?internalProjectId=${projectId}&farmId=${farmId}&activityCategory=${getActivityCategory(
          projectActivities[currentTab]?.title,
        )}&categoryStatus=${status}`,
      )
      .then(() => {
        setCurrentTabData((prevFiles) =>
          prevFiles.filter((item) => item.fileId !== fileId),
        );
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.DELETE);
      })
      .finally(() => {
        refreshStatus();
        setLoaderCount((prev) => prev - 1);
      });
  };

  const switchToTab = (tab) => {
    fetchActivityTabData(tab).then(() => {
      setCurrentTab(tab);
      aggregateAndMap(fieldDetails, tab);
    });
    setAnnualImplementationCost('');
  };

  const handleTabChange = (_, selectedTab) => {
    if (userActivityDetected) {
      setUnsavedChangesModalProps({
        open: true,
        nextTab: selectedTab,
      });
    } else switchToTab(selectedTab);
  };

  const submitTabData = async () => {
    const currentTabTitle = projectActivities[currentTab]?.title;
    const activityIds = projectActivities
      .find((item) => item.title === currentTabTitle)
      ?.practiceTypeList.map((practiceType) => practiceType.value);

    const submitData = formatSubmitData(
      currentTabData,
      projectId,
      farmId,
      activityIds,
      getActivityCategory(projectActivities[currentTab]?.title),
      currentTabStatus,
      participantId,
      annualImplementationCost,
    );

    setLoaderCount((prev) => prev + 1);
    return axios
      .post(
        `${SUBMIT_POI_TAB_DATA}?internalProjectId=${projectId}&participantId=${participantId}&farmId=${farmId}`,
        submitData,
      )
      .then(() => {
        setUserActivityDetected(false);
        displayToast(PAGE_CONTENT.success, PAGE_CONTENT.success_msg);
      })
      .catch(() => {
        displayToast(PAGE_CONTENT.error, PAGE_CONTENT.error_msg);
      })
      .finally(() => {
        setLoaderCount((prev) => prev - 1);
      });
  };

  const continueHandler = () => {
    submitTabData().then(() => {
      fetchFieldDetails(false).then((fieldData) => {
        navigateToNextTab(fieldData);
      });
    });
  };

  const saveHandler = () => {
    submitTabData().finally(() => {
      fetchActivityTabData(currentTab);
      refreshStatus();
    });
  };

  const updateFieldsAndPractice = (
    fileData,
    updatedFields,
    isGeorefenceType,
  ) => {
    // New practice list based on updatedFields
    let updatedPractice = [];
    // Iterate over updatedFields and collect all valid activity ids from fieldIdWithActivityMapping
    updatedFields.forEach((fieldId) => {
      if (fieldIdWithActivityMapping[fieldId]) {
        const activitiesForField = fieldIdWithActivityMapping[fieldId];
        // Add valid activities from fieldIdWithActivityMapping for the selected fields
        updatedPractice = [
          ...new Set([...updatedPractice, ...activitiesForField]),
        ];
      }
    });

    // Filter the current practice list to only include practices that exist in updatedPractice
    const filteredPractice = fileData.practice.filter((practiceId) =>
      updatedPractice.includes(practiceId),
    );

    return {
      ...fileData,
      fields: updatedFields,
      fileType: checkTernaryCondition(
        isGeorefenceType,
        possibleFileTypes[4].name,
        fileData.fileType,
      ),
      practice: filteredPractice, // Set the filtered practice values
    };
  };

  const fieldsStatus = useMemo(() => {
    const flattenedFields = currentTabData
      .flatMap((item) => {
        if (
          item.fileType.includes(possibleFileTypes[0].name) &&
          item.fields.length > 1
        ) {
          return []; // Don't consider any of those fields
        }
        return item.fields;
      })
      .filter((item) => item !== -1);

    const uniqueFields = Array.from(new Set(flattenedFields));

    const currentTabTitle = projectActivities[currentTab]?.title;
    const fieldList = projectActivities?.find(
      (item) => item.title === currentTabTitle,
    )?.fieldsOfActivity;

    return fieldList?.map((field) => {
      const fieldStatus = checkTernaryCondition(
        uniqueFields.includes(field.value),
        PAGE_CONTENT.UPLOADED,
        PAGE_CONTENT.NOT_UPLOADED,
      );

      return {
        fieldId: field.value,
        name: field.label,
        status: fieldStatus,
      };
    });
  }, [currentTabData, currentTab, projectActivities]);

  const handleDropdownValueUpdate = (
    newList,
    fileId,
    dropdownType,
    isMultiSelect,
    isGeorefenceType = false,
  ) => {
    setUserActivityDetected(true);
    const length = newList.length;

    setCurrentTabData((prev) =>
      prev.map((fileData) => {
        if (fileData.fileId === fileId) {
          if (dropdownType === PAGE_CONTENT.fieldDropdown) {
            // Update the fields based on the current selection
            const updatedFields = checkTernaryCondition(
              isMultiSelect,
              newList,
              [newList[length - 1]],
            );

            return updateFieldsAndPractice(
              fileData,
              updatedFields,
              isGeorefenceType,
            );
          } else if (dropdownType === PAGE_CONTENT.practiceTypeDropdown) {
            return {
              ...fileData,
              practice: newList,
            };
          }
        }
        return fileData;
      }),
    );
  };

  const handleDropdownValueRemove = (
    fileId,
    dropdownValue,
    dropdownType,
    isGeorefenceType,
  ) => {
    setUserActivityDetected(true);
    const getFilteredFields = (fields, dropdownValue) =>
      fields.filter((fieldId) => fieldId !== dropdownValue);

    setCurrentTabData((prev) =>
      prev.map((fileData) => {
        if (fileData.fileId === fileId) {
          if (dropdownType === PAGE_CONTENT.fieldDropdown) {
            const updatedFields = checkTernaryCondition(
              getFilteredFields(fileData.fields, dropdownValue).length === 0,
              checkTernaryCondition(isGeorefenceType, [-1], [0]),
              getFilteredFields(fileData.fields, dropdownValue),
            );

            return updateFieldsAndPractice(
              fileData,
              updatedFields,
              isGeorefenceType,
            );
          } else if (dropdownType === PAGE_CONTENT.practiceTypeDropdown) {
            return {
              ...fileData,
              practice: getFilteredFields(fileData.practice, dropdownValue),
            };
          }
        }
        return fileData;
      }),
    );
  };

  const handleSaveAndLeave = () => {
    submitTabData()
      .then(() => {
        switchToTab(unsavedChangesModalProps?.nextTab);
        setUnsavedChangesModalProps(POI_UNSAVED_CHANGES_MODAL_INITIAL_STATE);
        setUserActivityDetected(false);
      })
      .finally(refreshStatus);
  };

  const handleLeaveWithoutSave = () => {
    switchToTab(unsavedChangesModalProps?.nextTab);
    setUnsavedChangesModalProps(POI_UNSAVED_CHANGES_MODAL_INITIAL_STATE);
    setUserActivityDetected(false);
    refreshStatus();
  };

  const closeUnsavedChangesModal = () => {
    setUnsavedChangesModalProps(POI_UNSAVED_CHANGES_MODAL_INITIAL_STATE);
  };

  const setPOIDropzones = () => {
    const currentTabTitle = projectActivities[currentTab].title;
    const fieldList = projectActivities?.find(
      (item) => item.title === currentTabTitle,
    )?.fieldsOfActivity;
    const practiceTypeList = projectActivities?.find(
      (item) => item.title === currentTabTitle,
    )?.practiceTypeList;
    const activityIds = practiceTypeList?.map(
      (practiceType) => practiceType.value,
    );

    switch (currentTabTitle) {
      case projectActivitiesLabels[0].title:
        return [
          <FileUploadCompPOI
            key={possibleFileTypes[0].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.cover_crop_dropzone1_label}
            acceptedFilesInfo={geoReferenceImageAcceptedFilesInfo}
            dropzoneInfoText={PAGE_CONTENT.dropzone_info_text1}
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                false,
                true,
              )
            }
            currentDropzoneData={currentTabData?.filter((data) =>
              data?.fileType?.includes(possibleFileTypes[0].name),
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[0].name,
                activityIds,
                false,
              )
            }
            fieldDetails={fieldList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            isGeorefenceType
            disableAllFarmActions={disableAllFarmActions}
            activityApprovedFields={activityApprovedFields}
            fieldIdWithActivityMapping={fieldIdWithActivityMapping}
            disableActions={!isEditable}
          />,
          <FileUploadCompPOI
            key={possibleFileTypes[1].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.cover_crop_dropzone2_label}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            dropzoneInfoText={
              PAGE_CONTENT.reciepts_invoice_mgmt_plan_dropzone_info_text
            }
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[1].name,
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[1].name,
                activityIds,
                false,
              )
            }
            fieldDetails={fieldList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            disableAllFarmActions={disableAllFarmActions}
            activityApprovedFields={activityApprovedFields}
            fieldIdWithActivityMapping={fieldIdWithActivityMapping}
            disableActions={!isEditable}
          />,
          <FileUploadCompPOI
            key={possibleFileTypes[2].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.cover_crop_dropzone3_label}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            dropzoneInfoText={
              PAGE_CONTENT.reciepts_invoice_mgmt_plan_dropzone_info_text
            }
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[2].name,
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[2].name,
                activityIds,
                false,
              )
            }
            fieldDetails={fieldList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            disableAllFarmActions={disableAllFarmActions}
            activityApprovedFields={activityApprovedFields}
            fieldIdWithActivityMapping={fieldIdWithActivityMapping}
            disableActions={!isEditable}
          />,
        ];
      case projectActivitiesLabels[1].title:
        return [
          <FileUploadCompPOI
            key={possibleFileTypes[0].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.residue_dropzone1_label}
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                false,
                true,
              )
            }
            acceptedFilesInfo={geoReferenceImageAcceptedFilesInfo}
            currentDropzoneData={currentTabData?.filter((data) =>
              data?.fileType?.includes(possibleFileTypes[0].name),
            )}
            dropzoneInfoText={PAGE_CONTENT.dropzone_info_text1}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[0].name,
                activityIds,
              )
            }
            fieldDetails={fieldList}
            maxFiles={fieldList.length}
            canMultipleFilesBeUploaded={true}
            handleDropdownValueRemove={handleDropdownValueRemove}
            practiceTypeList={practiceTypeList}
            hasPracticeTypeDropdown
            isGeorefenceType
            disableAllFarmActions={disableAllFarmActions}
            activityApprovedFields={activityApprovedFields}
            fieldIdWithActivityMapping={fieldIdWithActivityMapping}
            disableActions={!isEditable}
          />,
        ];
      case projectActivitiesLabels[2].title:
        return [
          <FileUploadCompPOI
            key={possibleFileTypes[3].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.nutrient_dropzone1_label}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            dropzoneInfoText={PAGE_CONTENT.dropzone_info_text2}
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[3].name,
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[3].name,
                activityIds,
                false,
              )
            }
            fieldDetails={fieldList}
            canMultipleFilesBeUploaded={true}
            maxFiles={fieldList.length}
            handleDropdownValueRemove={handleDropdownValueRemove}
            disableAllFarmActions={disableAllFarmActions}
            activityApprovedFields={activityApprovedFields}
            fieldIdWithActivityMapping={fieldIdWithActivityMapping}
            disableActions={!isEditable}
          />,
          <FileUploadCompPOI
            key={possibleFileTypes[2].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.nutrient_dropzone2_label}
            dropzoneInfoText={PAGE_CONTENT.dropzone_info_text2}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[2].name,
                activityIds,
                false,
              )
            }
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[2].name,
            )}
            fieldDetails={fieldList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            canMultipleFilesBeUploaded={true}
            maxFiles={fieldList.length}
            disableAllFarmActions={disableAllFarmActions}
            activityApprovedFields={activityApprovedFields}
            fieldIdWithActivityMapping={fieldIdWithActivityMapping}
            disableActions={!isEditable}
          />,
        ];
      case projectActivitiesLabels[3].title:
        return [
          <FileUploadCompPOI
            key={possibleFileTypes[0].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.edge_of_field_dropzone1_label}
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                false,
                true,
              )
            }
            dropzoneInfoText={PAGE_CONTENT.dropzone_info_text1}
            currentDropzoneData={currentTabData?.filter((data) =>
              data?.fileType?.includes(possibleFileTypes[0].name),
            )}
            acceptedFilesInfo={geoReferenceImageAcceptedFilesInfo}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[0].name,
                activityIds,
              )
            }
            canMultipleFilesBeUploaded={true}
            fieldDetails={fieldList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            maxFiles={fieldList.length}
            practiceTypeList={practiceTypeList}
            hasPracticeTypeDropdown
            isGeorefenceType
            disableAllFarmActions={disableAllFarmActions}
            activityApprovedFields={activityApprovedFields}
            fieldIdWithActivityMapping={fieldIdWithActivityMapping}
            disableActions={!isEditable}
          />,
          <FileUploadCompPOI
            key={possibleFileTypes[2].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.edge_of_field_dropzone2_label}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            dropzoneInfoText={PAGE_CONTENT.dropzone_info_text2}
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[2].name,
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[2].name,
                activityIds,
              )
            }
            canMultipleFilesBeUploaded={true}
            fieldDetails={fieldList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            maxFiles={fieldList.length}
            hasPracticeTypeDropdown
            practiceTypeList={practiceTypeList}
            disableAllFarmActions={disableAllFarmActions}
            activityApprovedFields={activityApprovedFields}
            fieldIdWithActivityMapping={fieldIdWithActivityMapping}
            disableActions={!isEditable}
          />,
        ];
      case projectActivitiesLabels[4].title:
        return [
          <FileUploadCompPOI
            key={possibleFileTypes[1].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.soil_carbon_dropzone1_label}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            dropzoneInfoText={
              PAGE_CONTENT.reciepts_invoice_mgmt_plan_dropzone_info_text
            }
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[1].name,
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[1].name,
                activityIds,
              )
            }
            fieldDetails={fieldList}
            practiceTypeList={practiceTypeList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            hasPracticeTypeDropdown
            disableAllFarmActions={disableAllFarmActions}
            activityApprovedFields={activityApprovedFields}
            fieldIdWithActivityMapping={fieldIdWithActivityMapping}
            disableActions={!isEditable}
          />,
          <FileUploadCompPOI
            key={possibleFileTypes[2].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.soil_carbon_dropzone2_label}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            dropzoneInfoText={
              PAGE_CONTENT.reciepts_invoice_mgmt_plan_dropzone_info_text
            }
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[2].name,
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[2].name,
                activityIds,
              )
            }
            fieldDetails={fieldList}
            practiceTypeList={practiceTypeList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            hasPracticeTypeDropdown
            disableAllFarmActions={disableAllFarmActions}
            activityApprovedFields={activityApprovedFields}
            fieldIdWithActivityMapping={fieldIdWithActivityMapping}
            disableActions={!isEditable}
          />,
        ];
      default:
        return [];
    }
  };

  useEffect(() => {
    if (projectActivities.length !== 0) {
      fetchActivityTabData(currentTab);
    }
    setShowWarning(projectActivities.length === 0);
  }, [projectActivities, currentTab]);

  useEffect(() => {
    validateTabData(currentTabData);
  }, [currentTabData]);

  useEffect(() => {
    fetchPoiActivities();
  }, [farmId]);

  // Data fetch triggered from parent component, updates POI data
  useEffect(() => {
    if (triggerDataFetch) {
      fetchPoiActivities();
      setTriggerDataFetch(false);
    }
  }, [triggerDataFetch]);

  useEffect(() => {
    const areAllFilesMapped = currentTabData.every((item) => {
      const hasInvalidField =
        item.fields.includes(-1) ||
        item.fields.includes(0) ||
        (item?.fileType?.includes(possibleFileTypes[0].name) &&
          item.fields.length > 1);
      const hasEmptyPractice = item.practice.length === 0;
      return !hasInvalidField && !hasEmptyPractice;
    });
    const isTabValidated = isAllFieldsAndActivitiesMapped && areAllFilesMapped;
    const poiTabStatus = checkTernaryCondition(
      isTabValidated,
      PAGE_CONTENT.completed,
      PAGE_CONTENT.in_progress,
    );
    setCurrentTabStatus(
      checkTernaryCondition(
        currentTabData.length === 0,
        PAGE_CONTENT.not_started,
        poiTabStatus,
      ),
    );
  }, [currentTabData, isAllFieldsAndActivitiesMapped, poiStatusList]);

  useEffect(() => {
    /* istanbul ignore else */
    if (farmProofOfImplementationStatus === PAGE_CONTENT.not_started) {
      setActivityTabData((prev) => ({
        ...prev,
        farmProofOfImplementationStatus: checkTernaryCondition(
          annualImplementationCost,
          PAGE_CONTENT.in_progress,
          PAGE_CONTENT.not_started,
        ),
      }));
    }
  }, [annualImplementationCost]);

  const handleSaveClick = async () => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    saveHandler();
  };

  const handleContinueClick = async () => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    continueHandler();
  };

  const handleSaveAndLeaveClick = async () => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    handleSaveAndLeave();
  };

  const handleLeaveWithoutSaveClick = async () => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    handleLeaveWithoutSave();
  };

  return (
    <>
      <Loader loading={loaderCount > 0} zIndex={999999} />
      {showWarning && (
        <InfoBox>
          <ContentBox width="95%">
            <InfoTooltip />
            <TextWrapper color={DARK_CHARCOAL}>
              {PAGE_CONTENT.info_test_implemented_activities}
            </TextWrapper>
          </ContentBox>
          <ContentBox
            width="5%"
            content="flex-end"
            cursor="pointer"
            data-testid="closeWarningIcon"
            onClick={() => {
              setShowWarning(false);
            }}>
            <CloseIcon />
          </ContentBox>
        </InfoBox>
      )}
      <MainWrapper>
        <HeaderWrapper>
          <TextWrapper width={'10rem'} marginLeft={'1rem'} fontWeight={600}>
            {PAGE_CONTENT.project_activities}
          </TextWrapper>
          <Box
            sx={{
              ...flexColumn,
              ...yAxisAutoScroll,
              width: '100%',
            }}>
            <TabberBox style={TabberBoxCustomStyle}>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                aria-label="page-tabs">
                {projectActivities?.map((tab, index) => (
                  <Tab
                    disabled={tab?.isDisabled}
                    sx={TabStylesSx}
                    className={classNames({
                      'active-tab': currentTab === index,
                    })}
                    key={tab?.title}
                    label={tab?.title}
                    {...tabProps(index)}
                  />
                ))}
              </Tabs>
            </TabberBox>
          </Box>
        </HeaderWrapper>
        <ContentWrapper>
          {projectActivities?.map((tab, index) => (
            <TabPanel key={tab.title} value={currentTab} index={index}>
              <PoiTextWrapper>
                <TextWrapper
                  marginBottom={'0.25rem'}
                  data-testid="poi-implementation-text">
                  {getFieldAcresText(!isAllFieldsAndActivitiesMapped, tab)}
                </TextWrapper>
                <TextWrapper
                  marginBottom={'0.25rem'}
                  onClick={handleOpenModal}
                  cursor="pointer"
                  data-testid="poi-field-text">
                  {getProofUploadedText(
                    fieldsStatus?.filter(
                      (item) => item.status === PAGE_CONTENT.UPLOADED,
                    )?.length,
                    fieldsStatus?.length,
                  )}
                </TextWrapper>
              </PoiTextWrapper>
              {!isAllFieldsAndActivitiesMapped && (
                <TextWrapper
                  lineHeight={'0.963rem'}
                  fontSize={'0.688rem'}
                  position={'sticky'}
                  top={'2.4rem'}
                  backgroundColor={WHITE}
                  zIndex={1}>
                  <PoiAlertIcon />
                  {PAGE_CONTENT.field_and_practice_mapping_error_msg}
                </TextWrapper>
              )}
              <TextWrapper
                gap="0.5rem"
                marginBottom="0.5rem"
                position="relative">
                <span style={{ alignSelf: 'center' }}>
                  {PAGE_CONTENT.annual_cost_per_acre}
                </span>
                <InputField
                  value={`${annualImplementationCost}`}
                  width="3.75rem"
                  labelMarginTop="0.375rem"
                  predefinedUnit={
                    findObjectWithKey(
                      proofOfImplementation,
                      ESTIMATED_IMPLEMENTATION_COST,
                    )?.ESTIMATED_IMPLEMENTATION_COST[0]?.uomNameDisplay
                  }
                  hasPredefinedUnit
                  category="long-numbers-field"
                  optionalLabelOnRight
                  isDisabled={disableAllFarmActions || !isEditable}
                  onUpdate={(e) => {
                    setUserActivityDetected(true);
                    const value = e.target.value.trim().replace('$', '');
                    if (value.trim() === '') {
                      setAnnualImplementationCost('');
                      return;
                    }
                    setAnnualImplementationCost(
                      ` ${value.trim().replace(/[^0-9.]/g, '')}`,
                    );
                  }}
                  noLabel
                />
              </TextWrapper>
              <TextWrapper gap={0} marginBottom={'1rem'}>
                {checkTernaryCondition(
                  projectActivities[currentTab]?.title ===
                    projectActivitiesLabels[1]?.title,
                  PAGE_CONTENT.list_proof_of_implementation_label_type2,
                  PAGE_CONTENT.list_proof_of_implementation_label_type1,
                )}

                {!disableAllFarmActions && (
                  <InfoTooltip
                    placement={TOOLTIP_PLACEMENTS.BOTTOM_END}
                    content={replaceToolTipEmail(clientConfigs)}
                    customSx={toolTipStyle}
                  />
                )}
              </TextWrapper>
              {setPOIDropzones()}
            </TabPanel>
          ))}
        </ContentWrapper>
      </MainWrapper>
      {!disableAllFarmActions && (
        <UserNavigationButtonsV2
          handleCancel={() => {
            navigateHandler();
            if (isParticipantContributor || isParticipantOwner) {
              fetchActivityTabData(0);
              refreshStatus();
              setUserActivityDetected(false);
            }
          }}
          disabledCancel={!isEditable}
          disabledContinue={!isEditable}
          disabledSave={!isEditable}
          handleContinue={handleContinueClick}
          handleSave={handleSaveClick}
        />
      )}

      <FieldDataModal
        open={isModalOpen}
        title={projectActivities[currentTab]?.title}
        uploadedFields={
          fieldsStatus?.filter((item) => item.status === PAGE_CONTENT.UPLOADED)
            ?.length
        }
        totalFields={fieldsStatus?.length}
        fieldList={fieldsStatus}
        onClose={handleCloseModal}
      />

      <UnsavedChangesModal
        open={unsavedChangesModalProps?.open}
        handleLeaveWithoutSave={handleLeaveWithoutSaveClick}
        handleSaveAndLeave={handleSaveAndLeaveClick}
        handleClose={closeUnsavedChangesModal}
      />
    </>
  );
};

export default ProofOfImplementationMainComp;

ProofOfImplementationMainComp.propTypes = {
  farmId: PropTypes.number,
  poiStatusList: PropTypes.array,
  setActivityTabData: PropTypes.func,
  fetchFieldDetails: PropTypes.func,
  currentTab: PropTypes.number,
  setCurrentTab: PropTypes.func,
  projectActivities: PropTypes.array,
  setProjectActivities: PropTypes.func,
  currentTabData: PropTypes.array,
  setCurrentTabData: PropTypes.func,
  currentTabStatus: PropTypes.string,
  setCurrentTabStatus: PropTypes.func,
  updateReportingStatus: PropTypes.func,
  gotoNextInprogressPage: PropTypes.func,
  farmProofOfImplementationStatus: PropTypes.string,
  setUserActivityDetected: PropTypes.func,
  setAnnualImplementationCost: PropTypes.func,
  annualImplementationCost: PropTypes.string,
  setReportingCompleteModal: PropTypes.func,
  userActivityDetected: PropTypes.bool,
  fetchBaselineYears: PropTypes.func,
  disableAllFarmActions: PropTypes.bool,
  activityApprovedFields: PropTypes.array,
  shouldNavigateToLandingPage: PropTypes.func,
  navigateHandler: PropTypes.func,
  isParticipantContributor: PropTypes.bool,
  isEditable: PropTypes.bool,
  isParticipantOwner: PropTypes.bool,
  triggerDataFetch: PropTypes.bool,
  setTriggerDataFetch: PropTypes.func,
};
